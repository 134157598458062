import { gql } from '@apollo/client';

export default gql`
  fragment AcademyCourse on AcademyCourse {
    _id
    name
    owner {
      _id
      name
      email
    }
    periodDateInit
    periodDateEnd
    periodStatus
    periodDuration
    totalStudents
    totalDebt
  }
`;
