import { useMemo } from 'react';
import { PricingMode } from '@ts-core/utils/Constants';

export default function useAcademyPricingModes() {
  const pricingModes = useMemo(
    () => [
      {
        title: 'Estudiante - Período',
        id: PricingMode.StudentPeriod,
      },
      {
        title: 'Estudiante - Mes',
        id: PricingMode.StudentMonth,
      },
      {
        title: 'Estudiante - Curso - Mes',
        id: PricingMode.StudentCourseMonth,
      },
      {
        title: 'Fijo - Mes',
        id: PricingMode.FixedMonth,
      },
    ],
    []
  );

  const getPricingModeById = (id) => {
    return pricingModes.find((p) => p.id === id);
  };

  return {
    pricingModes,
    getPricingModeById,
  };
}
