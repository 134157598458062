import { gql } from '@apollo/client';

export default gql`
  fragment AcademyListItem on Academy {
    _id
    name
    active
    createdAt
    clientId
  }
`;
