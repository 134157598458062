import { gql } from '@apollo/client';

export default gql`
  fragment BilledCourse on BilledCourse {
    _id
    periodStatus
    professor {
      _id
      name
      email
    }
    name
    invoices @type(name: "CourseInvoice") {
      _id
      course
      coursePeriodDateEnd
      coursePeriodDateInit
      paid
    }
  }
`;
