import { gql } from '@apollo/client';

import { StudentPracticeSessionsActivity } from '../../types';

export interface GetStudentPracticeSessionsByMonthQueryVariables {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export interface GetStudentPracticeSessionsByMonthQueryResult {
  practiceSessions: StudentPracticeSessionsActivity[];
}

export const getStudentPracticeSessionsByMonthQuery = gql`
  query GetStudentPracticeSessionsByMonthQuery($academyId: String!, $courseId: String!, $studentId: String!, $year: String!, $month: String!) {
    practiceSessions(academyId: $academyId, courseId: $courseId, studentId: $studentId, year: $year, month: $month)
      @rest(
        type: "StudentPracticeSessionsActivityByMonth"
        path: "/students-activity/academies/{args.academyId}/years/{args.year}/months/{args.month}/courses/{args.courseId}/students/{args.studentId}/practice-sessions"
      ) {
      _id
      name
      createdAt
      score
    }
  }
`;
