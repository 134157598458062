import PropTypes from 'prop-types';

import getTimeString from '@ts-core/utils/getTimeString';

const TimeTableCell = (props) => {
  const { time } = props;
  return getTimeString(time);
};

TimeTableCell.propTypes = {
  time: PropTypes.number,
};

TimeTableCell.defaultProps = {
  time: undefined,
};

export default TimeTableCell;
