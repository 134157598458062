export const allMonths: { id: string; label: string }[] = [
  {
    id: '1',
    label: 'Enero',
  },
  {
    id: '2',
    label: 'Febrero',
  },
  {
    id: '3',
    label: 'Marzo',
  },
  {
    id: '4',
    label: 'Abril',
  },
  {
    id: '5',
    label: 'Mayo',
  },
  {
    id: '6',
    label: 'Junio',
  },
  {
    id: '7',
    label: 'Julio',
  },
  {
    id: '8',
    label: 'Agosto',
  },
  {
    id: '9',
    label: 'Septiembre',
  },
  {
    id: '10',
    label: 'Octubre',
  },
  {
    id: '11',
    label: 'Noviembre',
  },
  {
    id: '12',
    label: 'Diciembre',
  },
];
