import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmButton from '@ts-core/components/ConfirmButton';

const DeleteButtonTableCell = (props) => {
  const {
    className,
    item,
    title,
    message,
    confirmLabel,
    cancelLabel,
    onConfirm,
  } = props;

  const handleConfirmAction = useCallback(() => {
    onConfirm(item);
  }, [onConfirm, item]);

  return (
    <Box className={className} my={-1}>
      <ConfirmButton
        alertTitle={title}
        alertMessage={message}
        confirmActionTitle={confirmLabel}
        cancelActionTitle={cancelLabel}
        onConfirm={handleConfirmAction}
      >
        {({ onClick }) => (
          <IconButton onClick={onClick} size="large">
            <DeleteIcon />
          </IconButton>
        )}
      </ConfirmButton>
    </Box>
  );
};

DeleteButtonTableCell.propTypes = {
  className: PropTypes.string,

  item: PropTypes.object,
  title: PropTypes.node,
  message: PropTypes.node,
  cancelLabel: PropTypes.node,
  confirmLabel: PropTypes.node,

  onConfirm: PropTypes.func,
};

DeleteButtonTableCell.defaultProps = {
  className: undefined,
  item: {},
  title: '',
  message: '',
  confirmLabel: undefined,
  cancelLabel: undefined,

  onConfirm() {},
};

export default DeleteButtonTableCell;
