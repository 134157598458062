import { useCallback } from 'react';

import { useQuery, useMutation } from '@ts-core/lib/apollo';

import { getErrorMessage, useMutationCompletionHandler } from '@ts-core/lib/apollo';

import { getAcademiesQuery } from 'graphql/academies/queries';
import { toggleAcademyMutation } from 'graphql/academies/mutations';

export default function useAcademies() {
  const { onError } = useMutationCompletionHandler();

  const { data, loading, error, refetch } = useQuery(getAcademiesQuery);
  const academies = data?.academies;

  /**
   * Delete Academy
   */
  const [toggleAcademy] = useMutation(toggleAcademyMutation, {
    onError,
  });
  const onToggleAcademy = useCallback(
    (academy) => {
      const status = academy.active;
      const updatedAcademy = {
        ...academy,
        active: !status,
      };
      toggleAcademy({
        variables: { academyId: academy._id, method: status ? 'deactivate' : 'activate' },
        optimisticResponse: {
          academy: updatedAcademy,
        },
      });
    },
    [toggleAcademy]
  );

  return {
    onFetchAcademies: refetch,
    loading,
    academies,
    error: getErrorMessage({ error }),
    onToggleAcademy,
  };
}
