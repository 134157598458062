/* eslint-disable no-param-reassign */
import axios from 'axios';

// const apiClient = new APIClient();

const ImageUploaderAdapter = (loader, apiClient, { courseId }) => {
  const upload = async () => {
    try {
      const file = await loader.file;

      const response = await apiClient.get('aws/signed-url/forum', {
        contentType: file.type,
        course: courseId,
      });

      const { signedRequest, url } = response;

      const options = {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          if (!progressEvent) {
            return;
          }
          loader.uploadTotal = progressEvent.total;
          loader.uploaded = progressEvent.loaded;
        },
      };

      await axios.put(signedRequest, file, options);

      return {
        default: url,
      };
    } catch (error) {
      return Promise.reject(error?.message || error);
    }
  };

  const abort = () => {};

  return {
    upload,
    abort,
  };
};

export default function ImageUploaderAdapterPluginCreator(apiClient, { courseId }) {
  return function ImageUploaderAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return ImageUploaderAdapter(loader, apiClient, { courseId });
    };
  };
}
