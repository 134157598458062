import { ApolloError, ServerError } from '@apollo/client';

export interface GetErrorMessageInput {
  error?: ApolloError;
}

export default function getErrorMessage(
  input: GetErrorMessageInput
): string | undefined {
  const { error } = input;

  const graphqlErrors = error?.graphQLErrors;
  if (!!graphqlErrors?.length) {
    return graphqlErrors.map((e) => e.message).join('\n');
  }

  const networkError = error?.networkError;
  if (networkError) {
    const message = (networkError as ServerError).result?.message;
    if (message) {
      return message;
    }
    return networkError.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return undefined;
}
