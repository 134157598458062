import {
  useMutation as useApolloMutation,
  DocumentNode,
  TypedDocumentNode,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  ApolloError,
  MutationHookOptions as ApolloMutationHookOptions,
  DefaultContext,
  ApolloCache,
} from '@apollo/client';

import { useSnackbarNotifications } from '@ts-core/core/hooks/ui';

import getErrorMessage from './getErrorMessage';

type MutationResult<TResponse> = {
  data?: TResponse | null;
  loading: boolean;
  error?: ApolloError;
  errorMessage?: string;
};

type MutationHookOptions<TResponse, TVariables, TContext> = {
  notifyError?: boolean;
  notifySuccess?: boolean;
  successMessage?: string;
} & ApolloMutationHookOptions<TResponse, TVariables, TContext>;

type MutationHookOutput<TResponse, TVariables, TContext, TCache extends ApolloCache<any>> = [
  (options?: MutationFunctionOptions<TResponse, TVariables, TContext, TCache>) => Promise<FetchResult<TResponse>>,
  MutationResult<TResponse>
];

export default function useMutation<
  TResponse = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<TResponse, TVariables>,
  options?: MutationHookOptions<TResponse, TVariables, TContext>
): MutationHookOutput<TResponse, TVariables, TContext, TCache> {
  const {
    notifyError = false,
    notifySuccess = false,
    successMessage,
    onCompleted = () => null,
    onError = () => null,
    ...apolloOptions
  } = options || {};

  const { showErrorNotification, showSuccessNotification } = useSnackbarNotifications();

  // Always pass onError to avoid unhandled rejections
  // https://github.com/apollographql/apollo-client/issues/5708
  const [mutate, { loading, error, data }] = useApolloMutation<TResponse, TVariables, TContext, TCache>(mutation, {
    onError: notifyError ? showErrorNotification : onError,
    onCompleted: notifySuccess ? () => showSuccessNotification(successMessage) : onCompleted,
    ...apolloOptions,
  });

  return [
    mutate,
    {
      data,
      loading,
      error,
      errorMessage: getErrorMessage({ error }),
    },
  ];
}
