import { useCallback } from 'react';
import { useMutation } from '@ts-core/lib/apollo';
import { useCourseInvoice } from '@ts-core/hooks/invoices';

import { useMutationCompletionHandler } from '@ts-core/lib/apollo';

import { getCourseInvoiceQuery } from 'graphql/invoices/queries';
import { toggleInvoicePaidStatusMutation } from 'graphql/invoices/mutations';

export default function useAcademyCourseInvoice({ academyId, courseId, invoiceId }) {
  const { onError } = useMutationCompletionHandler();

  const coreProps = useCourseInvoice({
    query: getCourseInvoiceQuery,
    variables: { academyId, courseId, invoiceId },
    skip: !academyId || !courseId || !invoiceId,
  });

  /**
   * Toggle invoice paid status
   */
  const [toggleInvoicePaidStatus] = useMutation(toggleInvoicePaidStatusMutation, { onError });
  const onToggleInvoicePaidStatus = useCallback(
    (invoice) => {
      const updatedInvoice = {
        ...invoice,
        paid: !invoice.paid,
      };

      toggleInvoicePaidStatus({
        variables: {
          academyId,
          courseId,
          invoiceId,
          method: updatedInvoice.paid ? 'mark-as-paid' : 'mark-as-unpaid',
        },
        optimisticResponse: {
          invoice: updatedInvoice,
        },
      });
    },
    [toggleInvoicePaidStatus, academyId, courseId, invoiceId]
  );

  return {
    ...coreProps,

    onToggleInvoicePaidStatus,
  };
}
