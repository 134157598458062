import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { Payment } from '../../types/payments';
import {
  getCoursePaymentsQuery,
  GetCoursePaymentsQueryResult,
  GetCoursePaymentsQueryVariables,
} from '../../graphql/payments/queries/getCoursePaymentsQuery';

export type CoursePaymentsHookResult = DataFetcherHookResult<Payment[], 'payments'>;

export interface CoursePaymentsHookInput {
  academyId: string;
  courseId: string;
  year: string;
  month: string;
}

export function useCoursePayments(input: CoursePaymentsHookInput): CoursePaymentsHookResult {
  const { data, loading, errorMessage } = useQuery<GetCoursePaymentsQueryResult, GetCoursePaymentsQueryVariables>(getCoursePaymentsQuery, {
    variables: input,
  });
  return { loading, error: errorMessage, payments: data?.payments };
}
