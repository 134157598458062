import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';

const Menu = (props) => {
  const { Icon, menuItems } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuClick = useCallback(
    (menuItem) => () => {
      closeMenu();
      menuItem.handler();
    },
    [closeMenu]
  );

  return <>
    <IconButton onClick={openMenu} size="large">
      {Icon ? <Icon /> : <MoreVertIcon />}
    </IconButton>
    <MuiMenu anchorEl={anchorEl} open={open} keepMounted onClose={closeMenu}>
      {menuItems.map((menuItem, index) => (
        <MenuItem key={index} onClick={handleMenuClick(menuItem)}>
          {menuItem.title}
        </MenuItem>
      ))}
    </MuiMenu>
  </>;
};

Menu.propTypes = {
  Icon: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      handler: PropTypes.func.isRequired,
    })
  ),
};

Menu.defaultProps = {
  Icon: undefined,
  menuItems: [],
};

export default Menu;
