import React, { useCallback, useState } from 'react';

import { Payment, CreatePaymentInput } from 'types/payments';
import { PaymentDetailsRow } from './PaymentDetailsRow';
import { PaymentForm } from './PaymentForm';

interface PaymentRowProps {
  payment?: Payment;
  loading?: boolean;

  onUpdate: (payment: Payment, input: CreatePaymentInput, onUpdated: () => void) => void;
  isUpdating: boolean;

  onDelete: (payment: Payment) => void;
}

export function PaymentRow(props: PaymentRowProps) {
  const { payment, loading, onDelete, onUpdate, isUpdating } = props;
  const [paymentToEdit, setPaymentToEdit] = useState<Payment | undefined>();

  const handleEditPayment = useCallback((payment: Payment) => {
    setPaymentToEdit(payment);
  }, []);

  return paymentToEdit ? (
    <PaymentForm
      payment={payment}
      onUpdate={onUpdate}
      isSubmitting={isUpdating}
      onCancel={() => {
        setPaymentToEdit(undefined);
      }}
    />
  ) : (
    <PaymentDetailsRow payment={payment} loading={loading} onDelete={onDelete} onEdit={handleEditPayment} />
  );
}
