import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import PaymentCourseDetails from '@ts-core/components/Invoices/PaymentCourseDetails';
import BackButton from '@ts-core/components/BackButton';

import { useBilledCourse, useBilledCourseCurrentPeriodDetails } from 'hooks/courses';

import { useAcademy } from 'hooks/academies';

import { useCourseInvoice } from 'hooks/invoices';

const CourseBillingPage = () => {
  const history = useHistory();
  const { academyId, courseId, invoiceId } = useParams();
  const { course } = useBilledCourse({ academyId, courseId });

  const { academy } = useAcademy({ academyId });
  const { currentPeriod, onDownloadPeriodData } = useBilledCourseCurrentPeriodDetails({ academyId, courseId });
  const {
    invoice,
    loading: loadingInvoice,
    onToggleInvoicePaidStatus,
  } = useCourseInvoice({
    academyId,
    courseId,
    invoiceId,
  });

  const onSelectInvoice = useCallback(
    (newInvoice) => {
      history.push(`/academies/${academyId}/courses/${courseId}/${newInvoice._id}`);
    },
    [history, courseId, academyId]
  );

  const onSelectCurrentPeriod = useCallback(() => {
    history.push(`/academies/${academyId}/courses/${courseId}`);
  }, [history, academyId, courseId]);

  const onGoBack = useCallback(() => {
    history.push(`/academies/${academyId}/details`);
  }, [history, academyId]);

  return (
    <>
      <BackButton onGoBack={onGoBack} title={course?.name} subtitle={academy?.name} />
      <PaymentCourseDetails
        course={course}
        currentPeriod={currentPeriod}
        onSelectInvoice={onSelectInvoice}
        selectedInvoiceId={invoiceId}
        selectedInvoice={invoice}
        loadingInvoice={loadingInvoice}
        onSelectCurrentPeriod={onSelectCurrentPeriod}
        onDownloadPeriodReport={onDownloadPeriodData}
        onToggleInvoiceStatus={onToggleInvoicePaidStatus}
      />
    </>
  );
};

export default CourseBillingPage;
