import { gql } from '@apollo/client';

import { academyFragment } from '../fragments';

export default gql`
  query GetAcademyById($academyId: String!) {
    academy(academyId: $academyId)
      @rest(type: "Academy", path: "academies/{args.academyId}") {
      ...Academy
    }
  }
  ${academyFragment}
`;
