import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Form, { FormTextField } from '@ts-core/core/components/FormV2';
import { useSnackbarNotifications } from '@ts-core/core/hooks/ui';

import { AskResetPasswordInput } from '../../types/Auth';
import { usePasswordActions } from '../../hooks';

const AskResetPasswordFormSchema = Yup.object({
  email: Yup.string().email('El email no es válido').required('El email es requerido'),
});

export interface AskResetPasswordFormProps {
  onClose: () => void;
}

const AskResetPasswordForm = (props: AskResetPasswordFormProps) => {
  const { onClose } = props;
  const intl = useIntl();
  const { onRequestResetPassword, requestResetPasswordLoading, requestResetPasswordError } = usePasswordActions();
  const { showSuccessNotification } = useSnackbarNotifications();

  const { handleSubmit, formState, control } = useForm<AskResetPasswordInput>({
    resolver: yupResolver(AskResetPasswordFormSchema),
  });

  const handleResetPassword = useCallback(
    (formData: AskResetPasswordInput) => {
      onRequestResetPassword(formData, () => {
        showSuccessNotification(intl.formatMessage({ id: 'resetPassword.successMessage' }));
        onClose();
      });
    },
    [onRequestResetPassword, onClose, showSuccessNotification, intl]
  );

  return (
    <Form
      sx={{ width: '100%' }}
      onSubmit={handleSubmit(handleResetPassword)}
      isSubmitting={requestResetPasswordLoading}
      submitError={requestResetPasswordError}
      formState={formState}
      submitActionTitle={intl.formatMessage({
        id: 'resetPassword.submitButton',
      })}
    >
      <FormTextField control={control} name="email" type="email" label="Email" defaultValue="" rules={{ required: true }} fullWidth />
    </Form>
  );
};

export default AskResetPasswordForm;
