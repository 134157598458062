// import { getStoredLanguage } from './localStorage';

// const getFirstBrowserLanguage = () => {
//   const storedLanguage = getStoredLanguage();

//   if (storedLanguage) {
//     return storedLanguage;
//   }

//   const nav = window.navigator;
//   const browserLanguagePropertyKeys = [
//     'language',
//     'browserLanguage',
//     'systemLanguage',
//     'userLanguage',
//   ];
//   let i;
//   let language;
//   // support for HTML 5.1 "navigator.languages"
//   if (Array.isArray(nav.languages)) {
//     for (i = 0; i < nav.languages.length; i++) {
//       language = nav.languages[i];
//       if (language && language.length) {
//         return language;
//       }
//     }
//   }
//   // support for other well known properties in browsers
//   for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
//     language = nav[browserLanguagePropertyKeys[i]];
//     if (language && language.length) {
//       return language;
//     }
//   }

//   return null;
// };

export const supportedLanguages = ['es', 'en'];

export const defaultLanguage = 'es';

export const getUserBrowserLanguage = () => {
  // const lang = getFirstBrowserLanguage();
  // const langCode = lang ? lang.substring(0, 2) : null;

  // if (langCode && supportedLanguages.includes(langCode)) {
  //   return langCode;
  // }
  return defaultLanguage;
};
