import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Box, FormControlLabel, Radio, IconButton } from '@mui/material';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import Table, { DeleteButtonTableCell, DateTableCell } from '@ts-core/components/Table';

import ConfirmButton from '@ts-core/components/ConfirmButton';

const AcademiesTable = (props) => {
  const intl = useIntl();
  const { academies, loading, error, onDeleteAcademy, onActivateAcademy, onSelectAcademy, onFetchAcademies, onFilterAcademies } = props;
  const [academyStatusFilter, setAcademyStatusFilter] = useState(true);

  const filteredAcademies = useMemo(() => academies.filter((a) => a.active === academyStatusFilter), [academies, academyStatusFilter]);

  const getActionsCell = useCallback(
    (_value, _, academy) => {
      return (
        <Box display="flex" justifyContent="flex-end">
          {academyStatusFilter ? (
            <DeleteButtonTableCell
              message={intl.formatMessage(
                {
                  id: 'dialog.confirmRemoveItem.title',
                },
                { item: academy.name }
              )}
              item={academy}
              onConfirm={onDeleteAcademy}
            />
          ) : (
            <Box>
              <Box my={-1}>
                <ConfirmButton alertMessage={`¿Seguro que quieres activar ${academy.name}?`} onConfirm={() => onActivateAcademy(academy)}>
                  {({ onClick }) => (
                    <IconButton onClick={onClick} size="large">
                      <RestoreFromTrashIcon />
                    </IconButton>
                  )}
                </ConfirmButton>
              </Box>
            </Box>
          )}
        </Box>
      );
    },
    [intl, onDeleteAcademy, academyStatusFilter, onActivateAcademy]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Número de cliente',
        accessor: 'clientId',
        sortable: true,
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        sortable: true,
        valueTransformer: (value) => <DateTableCell date={value} />,
      },

      {
        Header: '',
        accessor: '_id',
        valueTransformer: getActionsCell,
      },
    ],
    [getActionsCell]
  );

  return (
    <Table
      data={filteredAcademies}
      columns={columns}
      loading={loading}
      errorMessage={error}
      onRefreshData={onFetchAcademies}
      onSelectRow={onSelectAcademy}
      onSearch={onFilterAcademies}
      title={
        <Box>
          <Box mb={1}>Academias</Box>
          <Box display="flex" flexDirection="row">
            <FormControlLabel
              control={<Radio color="primary" checked={academyStatusFilter} />}
              label="Activas"
              labelPlacement="end"
              onClick={() => setAcademyStatusFilter(true)}
            />
            <FormControlLabel
              control={<Radio color="primary" checked={!academyStatusFilter} />}
              label="Inactivas"
              labelPlacement="end"
              onClick={() => setAcademyStatusFilter(false)}
            />
          </Box>
        </Box>
      }
    />
  );
};

AcademiesTable.propTypes = {
  academies: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.string,

  onFetchAcademies: PropTypes.func.isRequired,
  onSelectAcademy: PropTypes.func,
  onDeleteAcademy: PropTypes.func,
  onActivateAcademy: PropTypes.func,
  onFilterAcademies: PropTypes.func,
};

AcademiesTable.defaultProps = {
  academies: [],
  loading: false,
  error: undefined,

  onSelectAcademy: undefined,
  onDeleteAcademy: undefined,
  onActivateAcademy: undefined,
  onFilterAcademies: undefined,
};

export default AcademiesTable;
