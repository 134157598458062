import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const filterByField = ({ object, fieldPath, query }) => {
  const value = String(get(object, fieldPath)) || '';
  return (
    value.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
    query.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  );
};

export const filterByFieldsList = ({ object, fields = [], query }) => {
  for (const field of fields) {
    if (filterByField({ object, fieldPath: field, query })) {
      return true;
    }
  }
  return false;
};

export const filterListWithQuery = ({ list, query, fields }) => {
  if (isEmpty(list) || isEmpty(query) || isEmpty(fields)) return list;
  return list.filter((i) => filterByFieldsList({ object: i, fields, query }));
};
