import { gql } from '@apollo/client';

import { roleFragment } from '../fragments';

export default gql`
  query GetRoles {
    roles @rest(type: "Role", path: "roles") {
      ...Role
    }
  }
  ${roleFragment}
`;
