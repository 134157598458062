import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';

import { useAuth } from 'hooks/auth';

const LogoutPage = () => {
  const { onSignOut, authLoaded, authenticated } = useAuth();

  const history = useHistory();

  useEffect(() => {
    if (authLoaded && authenticated) {
      onSignOut();
    }
  }, [authLoaded, authenticated, onSignOut]);

  useEffect(() => {
    if (authLoaded && !authenticated) {
      history.push('/login');
    }
  }, [authLoaded, authenticated, history]);

  return <LinearProgress />;
};

export default LogoutPage;
