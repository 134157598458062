import { useCallback } from 'react';
import { useMutation } from '@ts-core/lib/apollo';
import { useSnackbarNotifications } from '@ts-core/core/hooks/ui';

import { CreatePaymentInput } from '../../types/payments';

import {
  updatePaymentMutation,
  UpdatePaymentMutationVariables,
  UpdatePaymentMutationResult,
} from '../../graphql/payments/mutations/updatePaymentMutation';

interface PaymentsCRUDPayload {
  onUpdatePayment: (paymentId: string, input: CreatePaymentInput, onUpdated?: () => void) => void;
  updatePaymentLoading: boolean;
  updatePaymentError?: string;
}

export function useUpdatePayment(): PaymentsCRUDPayload {
  const { showErrorNotification, showSuccessNotification } = useSnackbarNotifications();

  /**
   * Update payment
   */
  const [updatePayment, { loading: updatePaymentLoading, errorMessage: updatePaymentError }] = useMutation<
    UpdatePaymentMutationResult,
    UpdatePaymentMutationVariables
  >(updatePaymentMutation, {
    onCompleted: () => showSuccessNotification(),
    onError: showErrorNotification,
  });
  const onUpdatePayment = useCallback(
    (paymentId: string, input: CreatePaymentInput, onUpdated?: () => void) => {
      updatePayment({
        variables: {
          paymentId,
          input,
        },
      }).then(onUpdated);
    },
    [updatePayment]
  );

  return {
    onUpdatePayment,
    updatePaymentLoading,
    updatePaymentError,
  };
}
