import { gql } from '@apollo/client';

import { Payment, CreatePaymentInput } from '../../../types/payments';
import { paymentFragment } from '../fragments';

export interface CreateCoursePaymentMutationVariables {
  academyId: String;
  courseId: String;
  year: String;
  month: String;
  input: CreatePaymentInput;
}

export interface CreateCoursePaymentMutationResult {
  payment: Payment;
}

export const createCoursePaymentMutation = gql`
  mutation CreateCoursePaymentMutation(
    $academyId: String
    $courseId: String!
    $year: String
    $month: String!
    $input: CreateCoursePaymentMutationInput!
  ) {
    payment(academyId: $academyId, courseId: $courseId, year: $year, month: $month, input: $input)
      @rest(type: "Payment", path: "payments/{args.academyId}/{args.courseId}/{args.year}/{args.month}", method: "POST") {
      ...Payment
    }
  }
  ${paymentFragment}
`;
