import _ from 'lodash';

export const formatValue = (
  value,
  maxValue = 1,
  usePercentage = true,
  decimals = 0
) => {
  if (usePercentage) {
    if (
      maxValue === 0 ||
      maxValue === undefined ||
      maxValue === null ||
      value === undefined ||
      value === null
    )
      return '-';
    if (value <= 0) return '0%';
    const result = Math.min(100, (value / maxValue) * 100);
    return `${result.toFixed(decimals)}%`;
  }
  return `${value.toFixed(decimals)}`;
};

export const filterData = (list, query, fields) => {
  if (query && query !== '' && fields.length > 0) {
    const lowerCasedQuery = query.toLowerCase();
    return list.filter((e) => {
      for (const filterKey of fields) {
        if (
          (_.get(e, filterKey) || '').toLowerCase().includes(lowerCasedQuery)
        ) {
          return true;
        }
      }
      return false;
    });
  }
  return list;
};

export const timeString = (timeInSeconds) => {
  if (!timeInSeconds) return '-';

  const hours = Math.trunc(timeInSeconds / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.trunc((timeInSeconds - hours * 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.trunc(timeInSeconds - minutes * 60)
    .toString()
    .padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};
