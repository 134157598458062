import { useMemo } from 'react';

export default function useAcademyCurrencies() {
  const currencies = useMemo(
    () => [
      {
        id: 'UYU',
        title: 'UYU',
      },
      {
        id: 'ARG',
        title: 'ARG',
      },
      {
        id: 'USD',
        title: 'USD',
      },
    ],
    []
  );

  return {
    currencies,
  };
}
