import { gql } from '@apollo/client';

import { Course } from '../../../types/courses';

export interface GetCourseQueryVariables {
  courseId: string;
}

export interface GetCourseQueryResult {
  course: Course;
}

export const getCourseQuery = gql`
  query GetCourseQuery($courseId: String!) {
    course(courseId: $courseId) @rest(type: "Course", path: "/courses/{args.courseId}") {
      _id
      name
    }
  }
`;
