import { gql } from '@apollo/client';

import { Student } from '../../../types/students';

export interface GetStudentQueryVariables {
  studentId: string;
}

export interface GetStudentQueryResult {
  student: Student;
}

export const getStudentQuery = gql`
  query GetStudentQuery($studentId: String!) {
    student(studentId: $studentId) @rest(type: "Student", path: "/students/{args.studentId}") {
      _id
      name
      email
    }
  }
`;
