import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';

const EditButtonTableCell = (props) => {
  const { className, item, onClick } = props;

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      onClick(item);
    },
    [onClick, item]
  );

  return (
    <Box className={className} my={-1}>
      <IconButton onClick={handleClick} size="large">
        <EditIcon />
      </IconButton>
    </Box>
  );
};

EditButtonTableCell.propTypes = {
  className: PropTypes.string,

  item: PropTypes.object,
  onClick: PropTypes.func,
};

EditButtonTableCell.defaultProps = {
  className: undefined,
  item: {},
  onClick() {},
};

export default EditButtonTableCell;
