import { useQuery } from '@ts-core/lib/apollo';

import { getErrorMessage } from '@ts-core/lib/apollo';
import { getAcademyProfessorsQuery } from 'graphql/professors/queries';

export default function useAcademyProfessors({ academyId } = {}) {
  const { data, loading, error } = useQuery(getAcademyProfessorsQuery, {
    variables: { academyId },
    skip: !academyId,
  });
  const professors = data?.professors;

  return {
    professors,
    loading,
    error: getErrorMessage({ error }),
  };
}
