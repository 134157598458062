import { gql } from '@apollo/client';

import { AcademyActivityByMonth } from '../../types';

export interface GetAcademyActivityByMonthQueryVariables {
  academyId: string;
  year: string;
  month: string;
}

export interface GetAcademyActivityByMonthQueryResult {
  academyActivity: AcademyActivityByMonth;
}

export const getAcademyActivityByMonthQuery = gql`
  query GetAcademyActivityByMonth($academyId: String!, $year: String!, $month: String!) {
    academyActivity(academyId: $academyId, year: $year, month: $month)
      @rest(type: "AcademyActivityByMonth", path: "/students-activity/academies/{args.academyId}/years/{args.year}/months/{args.month}") {
      courses
      academy
      coursesCount
      studentsCount
      year
      month
      mediaSessionsCount
      assignmentAnswersCount
      practiceSessionsCount
      activityAnswersCount
      documentsCount
      totalCost
    }
  }
`;
