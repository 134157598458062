import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { formatDate } from '@ts-core/utils/dateFormatters';

const DateTableCell = (props) => {
  const { date, format } = props;
  return <Typography>{formatDate(date, format)}</Typography>;
};

DateTableCell.propTypes = {
  date: PropTypes.string,

  format: PropTypes.string,
};

DateTableCell.defaultProps = {
  date: undefined,

  format: 'DD/MM/YYYY HH:mm',
};

export default DateTableCell;
