import { DocumentNode, ApolloCache, FetchResult } from '@apollo/client';

interface AddItemToCacheInput<TMutationResult, TQueryResult, TQueryVariables> {
  query: DocumentNode;
  queryVariables?: TQueryVariables;
  queryName: keyof TQueryResult;
  mutationName: keyof TMutationResult;
  options?: {
    insertFirst?: boolean;
  };
}

export const addItemToCache =
  <TMutationResult, TQueryResult, TQueryVariables = any, TCache extends ApolloCache<any> = ApolloCache<any>>(
    input: AddItemToCacheInput<TMutationResult, TQueryResult, TQueryVariables>
  ) =>
  (cache: TCache, result: Omit<FetchResult<TMutationResult>, 'context'>) => {
    try {
      const { query, queryVariables, queryName, mutationName, options = {} } = input;
      const { insertFirst = false } = options;

      const newItem = result?.data?.[mutationName];

      if (!newItem) return;

      const queryData = cache.readQuery<TQueryResult, TQueryVariables>({ query, variables: queryVariables });
      const list = (queryData?.[queryName] as any) || [];

      let newList = [];
      if (insertFirst) {
        newList = [newItem, ...list];
      } else {
        newList = [...list, newItem];
      }

      const data: any = { [queryName]: newList };
      cache.writeQuery<TQueryResult, TQueryVariables>({ query, variables: queryVariables, data });
    } catch (error) {
      console.error(error);
    }
  };

interface RemoItemFromCacheInput<TMutationResult, TQueryResult, TQueryVariables> {
  query: DocumentNode;
  queryVariables?: TQueryVariables;
  queryName: keyof TQueryResult;
  mutationName: keyof TMutationResult;
  idAccessor: string;
}

export const removeItemFromCache =
  <TMutationResult, TQueryResult, TQueryVariables = any, TCache extends ApolloCache<any> = ApolloCache<any>>(
    input: RemoItemFromCacheInput<TMutationResult, TQueryResult, TQueryVariables>
  ) =>
  (cache: TCache, result: Omit<FetchResult<TMutationResult>, 'context'>) => {
    try {
      const { query, queryVariables, queryName, mutationName, idAccessor } = input;
      const removedItem = result?.data?.[mutationName];
      if (!removedItem) return;

      const removedItemId = (removedItem as any)?.[idAccessor];
      const queryData = cache.readQuery<TQueryResult, TQueryVariables>({ query, variables: queryVariables });
      const list = queryData?.[queryName] as any;
      if (!list) return;

      const newList = list.filter((i: any) => i[idAccessor] !== removedItemId);
      const data: any = { [queryName]: newList };
      cache.writeQuery<TQueryResult, TQueryVariables>({ query, variables: queryVariables, data });
    } catch (error) {
      console.error(error);
    }
  };
