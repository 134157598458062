import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { StudentAssignmentsActivity } from '../types';
import {
  getStudentAssignmentsByMonthQuery,
  GetStudentAssignmentsByMonthQueryResult,
  GetStudentAssignmentsByMonthQueryVariables,
} from '../graphql/queries/getStudentAssignmentsByMonthQuery';

export type StudentAssignmentsByMonthHookResult = DataFetcherHookResult<StudentAssignmentsActivity[], 'assignments'>;

export interface StudentAssignmentsByMonthHookInput {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export function useStudentAssignmentsByMonth(input: StudentAssignmentsByMonthHookInput): StudentAssignmentsByMonthHookResult {
  const { academyId, courseId, studentId, year, month } = input;
  const { data, loading, errorMessage } = useQuery<GetStudentAssignmentsByMonthQueryResult, GetStudentAssignmentsByMonthQueryVariables>(
    getStudentAssignmentsByMonthQuery,
    { variables: { academyId, courseId, studentId, year, month } }
  );

  return { loading, error: errorMessage, assignments: data?.assignments };
}
