/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';

import MUITypography, { TypographyProps as MUITypographyProps } from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

export interface TypographyProps extends MUITypographyProps {
  component?: React.ElementType;
  loading?: boolean;
  skeletonProps?: {
    lines?: number;
    width?: number | string;
    lastLineWidth?: number | string;
    bgcolor?: string;
  };
  skeletonWidth?: number | string;
  skeletonHeight?: number;
  skeletonLines?: number;
  skeletonLastLineWidth?: number | string;
}

const Typography = forwardRef<HTMLAnchorElement, TypographyProps>((props, ref) => {
  const {
    loading = false,
    sx,
    skeletonProps,
    skeletonWidth: pSkeletonWidth,
    skeletonHeight: pSkeletonHeight,
    skeletonLastLineWidth: pSkeletonLastLineWidth,
    skeletonLines: pSkeletonLines,
    children,
    ...other
  } = props;

  let skeletonWidth = props.skeletonWidth || props.skeletonProps?.width || '100%';
  let skeletonLastLineWidth = props.skeletonLastLineWidth || props.skeletonProps?.lastLineWidth || '50%';
  let skeletonLines = props.skeletonLines || props.skeletonProps?.lines || 1;

  function renderSkeletonLines() {
    const array = Array.from(new Array(skeletonLines));
    return (
      <>
        {array.map((_, index) => {
          let width = skeletonWidth;
          if (skeletonLines > 1 && index === skeletonLines - 1) {
            width = skeletonLastLineWidth;
          }

          return <Skeleton key={index} width={width} />;
        })}
      </>
    );
  }

  return (
    <MUITypography {...other} sx={sx} ref={ref}>
      {loading ? renderSkeletonLines() : children}
    </MUITypography>
  );
});

export default Typography;
