import { gql } from '@apollo/client';

import { StudentMediaSessionActivity } from '../../types';

export interface GetStudentMediaSessionsByMonthQueryVariables {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export interface GetStudentMediaSessionsByMonthQueryResult {
  mediaSessions: StudentMediaSessionActivity[];
}

export const getStudentMediaSessionsByMonthQuery = gql`
  query GetStudentMediaSessionsByMonthQuery($academyId: String!, $courseId: String!, $studentId: String!, $year: String!, $month: String!) {
    mediaSessions(academyId: $academyId, courseId: $courseId, studentId: $studentId, year: $year, month: $month)
      @rest(
        type: "StudentMediaSessionActivityByMonth"
        path: "/students-activity/academies/{args.academyId}/years/{args.year}/months/{args.month}/courses/{args.courseId}/students/{args.studentId}/media-sessions"
      ) {
      _id
      platform
      maxProgressPercentage
      lastEventDate
      createdAt
      video
    }
  }
`;
