import React from 'react';
import { FieldValues, useController } from 'react-hook-form';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { FormField } from '../types';

export type FormTextFieldProps<TField extends FieldValues> = FormField<TField> & Omit<TextFieldProps, 'required'>;

const FormTextField = <TField extends FieldValues>(props: FormTextFieldProps<TField>) => {
  const { name, control, variant = 'standard', size = 'small', rules, defaultFormValue = '', shouldUnregister = false, ...textFieldProps } = props;

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: defaultFormValue,
    shouldUnregister,
    rules: {
      required: rules?.required,
    },
  });
  const { error } = fieldState;
  const { value } = field;

  const hasError = !!error;

  return (
    <TextField
      {...textFieldProps}
      variant={variant}
      size={size}
      error={!!hasError}
      required={!!rules?.required}
      helperText={error?.message || undefined}
      {...field}
      value={value || defaultFormValue}
    />
  );
};

export default FormTextField;
