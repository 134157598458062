import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider as NSSnackbarProvider } from 'notistack';

const SnackbarProvider = ({ children }) => {
  return (
    <NSSnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      maxSnack={3}
    >
      {children}
    </NSSnackbarProvider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SnackbarProvider;
