import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';

import LoginForm from '@ts-core/modules/auth/components/LoginForm';

import { useAuth } from 'hooks/auth';
import { useAuthState } from 'lib/auth';

const LoginPage = () => {
  const history = useHistory();
  const { onSignIn, signInError, signingIn } = useAuth();

  const { authenticated } = useAuthState();

  useEffect(() => {
    if (authenticated) {
      history.push('/academies');
    }
  }, [authenticated, history]);

  return (
    <Box display="flex" width="100%" justifyContent="center">
      <Box width={{ xs: '100%', md: 400 }} maxWidth={{ xs: 400, md: undefined }} p={2} my={3} mt={24}>
        <LoginForm title={<FormattedMessage id="loginAdmin.title" />} onSubmit={onSignIn} error={signInError} isSubmitting={signingIn} />
      </Box>
    </Box>
  );
};

export default LoginPage;
