import { useCallback } from 'react';
import { useMutation } from '@ts-core/lib/apollo';
import { removeItemFromCache } from '@ts-core/core/utils/apolloUtils';
import { useSnackbarNotifications } from '@ts-core/core/hooks/ui';

import { Payment } from '../../types/payments';

import {
  getAcademyPaymentsQuery,
  GetAcademyPaymentsQueryResult,
  GetAcademyPaymentsQueryVariables,
} from '../../graphql/payments/queries/getAcademyPaymentsQuery';
import {
  getCoursePaymentsQuery,
  GetCoursePaymentsQueryResult,
  GetCoursePaymentsQueryVariables,
} from '../../graphql/payments/queries/getCoursePaymentsQuery';
import {
  deletePaymentMutation,
  DeletePaymentMutationVariables,
  DeletePaymentMutationResult,
} from '../../graphql/payments/mutations/deletePaymentMutation';

interface DeletePaymentPayload {
  onDeletePayment: (payment: Payment) => void;
}

export function useDeletePayment(): DeletePaymentPayload {
  const { showErrorNotification } = useSnackbarNotifications();

  /**
   * Delete payment
   */
  const [deletePayment] = useMutation<DeletePaymentMutationResult, DeletePaymentMutationVariables>(deletePaymentMutation, {
    onError: showErrorNotification,
    update: (cache, result) => {
      const payment = result.data?.payment;
      if (!payment) return;
      const { academy, course } = payment;
      const year = `${payment.year}`;
      const month = `${payment.month}`;

      const removeFromAcademies = removeItemFromCache<DeletePaymentMutationResult, GetAcademyPaymentsQueryResult, GetAcademyPaymentsQueryVariables>({
        query: getAcademyPaymentsQuery,
        queryVariables: {
          academyId: academy,
          year,
          month,
        },
        queryName: 'payments',
        mutationName: 'payment',
        idAccessor: 'id',
      });
      removeFromAcademies(cache, result);

      if (course) {
        const removeFromCourse = removeItemFromCache<DeletePaymentMutationResult, GetCoursePaymentsQueryResult, GetCoursePaymentsQueryVariables>({
          query: getCoursePaymentsQuery,
          queryVariables: {
            academyId: academy,
            courseId: course.id,
            year,
            month,
          },
          queryName: 'payments',
          mutationName: 'payment',
          idAccessor: 'id',
        });
        removeFromCourse(cache, result);
      }
    },
  });
  const onDeletePayment = useCallback(
    (payment: Payment) => {
      deletePayment({
        variables: {
          paymentId: payment.id,
        },
        optimisticResponse: { payment },
      });
    },
    [deletePayment]
  );

  return {
    onDeletePayment,
  };
}
