import { gql } from '@apollo/client';

import { Payment } from '../../../types/payments';
import { paymentFragment } from '../fragments';

export interface GetAcademyPaymentsQueryVariables {
  academyId: string;
  year: string;
  month: string;
}

export interface GetAcademyPaymentsQueryResult {
  payments: Payment[];
}

export const getAcademyPaymentsQuery = gql`
  query GetAcademyPaymentsQuery($academyId: String!, $year: String!, $month: String!) {
    payments(academyId: $academyId, year: $year, month: $month) @rest(type: "Payment", path: "payments/{args.academyId}/{args.year}/{args.month}") {
      ...Payment
    }
  }
  ${paymentFragment}
`;
