import { gql } from '@apollo/client';

import { academyFragment } from '../fragments';

export default gql`
  mutation CreateAcademy($input: CreateAcademyBody!) {
    academy(input: $input)
      @rest(type: "Academy", path: "academies", method: "POST") {
      ...Academy
    }
  }
  ${academyFragment}
`;
