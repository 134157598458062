import { useCallback } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';

import { usePrevious } from '@ts-core/hooks/utils';
import { getErrorMessage } from '@ts-core/lib/apollo';

import { userAccountQuery } from 'graphql/auth/queries';
import { signInMutation, signOutMutation } from 'graphql/auth/mutation';

export default function useAuth() {
  const apolloClient = useApolloClient();

  const { data: newUserData, loading: loadingUser, called, error } = useQuery(userAccountQuery);

  const previousData = usePrevious(newUserData);
  const data = newUserData || previousData;

  const user = data?.userAccount;

  const authLoaded = Boolean(called && (data || error));
  const authenticated = Boolean(user?._id);

  /**
   * Sign in
   */
  const [signIn, { loading: signingIn, error: signInError }] = useMutation(signInMutation, {
    onCompleted: ({ user: userAccount }) => {
      apolloClient.writeQuery({
        query: userAccountQuery,
        data: {
          userAccount,
        },
      });
    },
  });
  const onSignIn = useCallback(
    (input) => {
      signIn({ variables: { input } });
    },
    [signIn]
  );

  /**
   * Sign out
   */
  const [signOut] = useMutation(signOutMutation, {
    onCompleted: () => {
      apolloClient.cache.reset();
    },
    onError: () => {
      apolloClient.cache.reset();
    },
  });
  const onSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return {
    authenticated,
    authLoaded,
    loadingUser,
    user,

    signingIn,
    signInError: getErrorMessage({ error: signInError }),
    onSignIn,
    onSignOut,
  };
}
