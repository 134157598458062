/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Box, { BoxProps } from '@mui/material/Box';

const BasePage = (props: BoxProps) => {
  const { children, ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box {...rest} p={{ xs: 2, lg: 3 }} maxWidth={1600} margin="auto">
      {children}
    </Box>
  );
};

BasePage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BasePage;
