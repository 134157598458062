import React from 'react';

import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import RefreshIcon from '@mui/icons-material/Refresh';

interface ErrorMessageProps {
  errorMessage?: string;
  columnsLength: number;
  loading?: boolean;

  onRefreshData: () => void;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { errorMessage, onRefreshData, columnsLength, loading = false } = props;

  if (loading || !errorMessage) {
    return null;
  }

  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={columnsLength} style={{ textAlign: 'center' }}>
          <Box display="flex" flexDirection="column" alignItems="center" p={1} color="error.main">
            <Typography>{errorMessage}</Typography>
            <IconButton onClick={onRefreshData} color="inherit" size="large">
              <RefreshIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default ErrorMessage;
