/* eslint-disable no-undef */
/* eslint-disable react/state-in-constructor */
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { FormattedMessage } from 'react-intl';
import XLSX from 'xlsx';
import { SortableContainer } from 'react-sortable-hoc';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import PaperWithLoader from '@ts-core/components/PaperWithLoader/PaperWithLoader';

import ErrorMessage from './ErrorMessage';
import TableHeader from './TableHeader';
import TableFooter from './TableFooter';
import CheckTableRow from './TableRow';
import TableToolbar from './TableToolbar';

const SortableBody = SortableContainer((props) => {
  const { children, ...otherProps } = props;
  return <TableBody {...otherProps}>{children}</TableBody>;
});

const TSTable = forwardRef((props, tableRef) => {
  const {
    columns,
    data,
    loading,
    elevation,
    emptyMessage,
    onSortEnd,
    errorMessage,
    onRefreshData,
    onSelectRow,
    onSearch,
    extraFilters,
    allowDownloadAsXLSX,
    disableTypography,
    title,
  } = props;
  const [internalLoading, setInternalLoading] = useState(false);

  function handleSortStart({ node }) {
    const tds = document.getElementsByClassName('SortableHelper')[0].childNodes;
    node.childNodes.forEach((childNode, idx) => {
      tds[idx].style.width = `${childNode.offsetWidth}px`;
    });
  }

  // Actions

  function handleSortEnd(data) {
    const { oldIndex, newIndex } = data;
    onSortEnd(oldIndex, newIndex);
  }

  function handleDownloadAsXlSX(instance) {
    setInternalLoading(true);
    const { xlsxFileName = 'Resultados', getDataToExport } = props;
    const { state } = instance;
    const { sortedData, allVisibleColumns } = state;

    const exportableColumns = allVisibleColumns.filter((c) => c.exportable === undefined || c.exportable);

    let xlsData = [];

    if (getDataToExport) {
      xlsData = getDataToExport(sortedData);
    } else {
      xlsData.push(exportableColumns.map((c) => c.value || c.id));
      sortedData.forEach((row) => {
        xlsData.push(exportableColumns.map((c) => (row[c.id] ? row[c.id] : '')));
      });
    }

    const worksheet = XLSX.utils.aoa_to_sheet(xlsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, `${xlsxFileName}.xlsx`);

    setInternalLoading(false);
  }

  return (
    <Box>
      <ReactTable data={data} columns={columns} defaultPageSize={50} filterable multiSort>
        {(state, makeTable, instance) => (
          <PaperWithLoader loading={loading || internalLoading} elevation={elevation}>
            <TableToolbar
              loading={loading}
              title={title}
              disableTypography={disableTypography}
              state={state}
              instance={instance}
              extraFilters={extraFilters}
              onDownloadAsXLSX={allowDownloadAsXLSX ? handleDownloadAsXlSX : undefined}
              onSearch={onSearch}
            />
            <Box sx={{ overflowX: 'auto', backgroundColor: 'white' }} ref={tableRef}>
              <Table>
                <TableHeader state={state} instance={instance} canSortRows={!!onSortEnd} />
                <SortableBody useDragHandle onSortEnd={onSortEnd && handleSortEnd} helperClass="SortableHelper" onSortStart={handleSortStart}>
                  {state.pageRows.map((row, index) => (
                    <CheckTableRow
                      key={index}
                      row={row}
                      state={state}
                      canSortRows={!!onSortEnd}
                      isNavigable={!!onSelectRow}
                      index={index}
                      onSelectRow={onSelectRow}
                    />
                  ))}
                </SortableBody>
                {data.length === 0 && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={state.allVisibleColumns.length}>{emptyMessage || <FormattedMessage id="table.emptyMessage" />}</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <ErrorMessage
                  columnsLength={state.allVisibleColumns.length}
                  errorMessage={errorMessage}
                  onRefreshData={onRefreshData}
                  loading={loading}
                />
                <TableFooter state={state} />
              </Table>
            </Box>
            <TablePagination
              component="div"
              count={state.sortedData.length}
              rowsPerPage={state.pageSize}
              page={state.page}
              labelRowsPerPage={<FormattedMessage id="table.pagination.resultsPerPage" />}
              labelDisplayedRows={(values) => <FormattedMessage id="table.pagination.displayedRows" values={values} />}
              backIconButtonProps={{
                'aria-label': <FormattedMessage id="table.pagination.back" />,
              }}
              nextIconButtonProps={{
                'aria-label': <FormattedMessage id="table.pagination.next" />,
              }}
              onPageChange={(_event, page) => {
                instance.onPageChange(page);
              }}
              onRowsPerPageChange={(event) => {
                instance.onPageSizeChange(event.target.value);
              }}
            />
          </PaperWithLoader>
        )}
      </ReactTable>
    </Box>
  );
});

TSTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  extraFilters: PropTypes.array,
  disableTypography: PropTypes.bool,
  title: PropTypes.node,

  allowDownloadAsXLSX: PropTypes.bool,
  xlsxFileName: PropTypes.string,

  emptyMessage: PropTypes.node,
  elevation: PropTypes.number,

  onSearch: PropTypes.func,
  onRefreshData: PropTypes.func,
  onSelectRow: PropTypes.func,
  getDataToExport: PropTypes.func,
  onSortEnd: PropTypes.func,
};

TSTable.defaultProps = {
  data: [],
  onSortEnd: undefined,
  errorMessage: undefined,
  extraFilters: [],
  customTitle: false,
  title: undefined,
  allowDownloadAsXLSX: false,
  xlsxFileName: undefined,
  emptyMessage: undefined,
  onSearch: undefined,
  onRefreshData: undefined,
  onSelectRow: undefined,
  getDataToExport: undefined,
  elevation: undefined,

  disableTypography: false,
};

export default TSTable;
