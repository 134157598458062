import { gql } from '@apollo/client';

import { ChangePasswordInput } from '../../types/Auth';

export interface ChangePasswordMutationVariables {
  input: ChangePasswordInput;
}

export const changePasswordMutation = gql`
  mutation ChangePasswordMutation($input: ChangePasswordMutationInput!) {
    changePassword(input: $input)
      @rest(
        type: "ChangePasswordPayload"
        path: "profile/change-password"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`;
