import React from 'react';

import { Box } from '@mui/material';

interface PaymentRowContainerProps {
  children: React.ReactNode;
}

export const PaymentRowContainer = ({ children }: PaymentRowContainerProps) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" p={2} pb={1} border="0.5px solid" borderColor="grey.200">
      {children}
    </Box>
  );
};
