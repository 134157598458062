import { useFilteredData } from '@ts-core/hooks/ui';

export default function useAcademiesTableData({ allAcademies }) {
  const { filteredData: academies, onFilterData: onFilterAcademies } =
    useFilteredData({ list: allAcademies, fields: ['name', 'clientId'] });

  return {
    academies,
    onFilterAcademies,
  };
}
