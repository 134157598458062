import { gql } from '@apollo/client';

import { StudentActivitiesActivity } from '../../types';

export interface GetStudentActivitiesByMonthQueryVariables {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export interface GetStudentActivitiesByMonthQueryResult {
  activities: StudentActivitiesActivity[];
}

export const getStudentActivitiesByMonthQuery = gql`
  query GetStudentActivitiesByMonthQuery($academyId: String!, $courseId: String!, $studentId: String!, $year: String!, $month: String!) {
    activities(academyId: $academyId, courseId: $courseId, studentId: $studentId, year: $year, month: $month)
      @rest(
        type: "StudentActivitiesActivityByMonth"
        path: "/students-activity/academies/{args.academyId}/years/{args.year}/months/{args.month}/courses/{args.courseId}/students/{args.studentId}/activities"
      ) {
      _id
      name
      answerDate
      file
      score
    }
  }
`;
