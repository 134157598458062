import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

const Address = (props) => {
  const { address } = props;

  const { addressLine1, postalCode, city, country } = address;

  return (
    <Box>
      <Box fontSize="body2.fontSize">{`${addressLine1}`}</Box>
      <Box fontSize="body2.fontSize">{`${postalCode}, ${city}`}</Box>
      <Box fontSize="body2.fontSize">{country}</Box>
    </Box>
  );
};

Address.propTypes = {
  address: PropTypes.shape({
    addressLine1: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
};

Address.defaultProps = {
  address: {},
};

export default Address;
