import {
  useQuery as useApolloQuery,
  DocumentNode,
  TypedDocumentNode,
  OperationVariables,
  ApolloError,
  QueryHookOptions as ApolloQueryHookOptions,
  ApolloQueryResult,
} from '@apollo/client';

import { useSnackbarNotifications } from '@ts-core/core/hooks/ui';

import getErrorMessage from './getErrorMessage';

type QueryResult<TResponse, TVariables> = {
  data?: TResponse | null;
  loading: boolean;
  error?: ApolloError;
  errorMessage?: string;
  refetch(variables?: Partial<TVariables>): Promise<ApolloQueryResult<TResponse>>;
};

type QueryHookOptions<TResponse, TVariables> = {
  notifyError?: boolean;
} & ApolloQueryHookOptions<TResponse, TVariables>;

export default function useQuery<TResponse = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TResponse, TVariables>,
  options?: QueryHookOptions<TResponse, TVariables>
): QueryResult<TResponse, TVariables> {
  const { showErrorNotification } = useSnackbarNotifications();

  const { notifyError = true, ...apolloOptions } = options || {};
  const { data, loading, error, refetch } = useApolloQuery(query, {
    onError: notifyError ? showErrorNotification : undefined,
    ...apolloOptions,
  });

  return {
    data,
    loading,
    error,
    errorMessage: getErrorMessage({ error }),
    refetch,
  };
}
