import { gql } from '@apollo/client';

import { Payment } from '../../../types/payments';
import { paymentFragment } from '../fragments';

export interface DeletePaymentMutationVariables {
  paymentId: String;
}

export interface DeletePaymentMutationResult {
  payment: Payment;
}

export const deletePaymentMutation = gql`
  mutation DeletePaymentMutation($paymentId: String!) {
    payment(paymentId: $paymentId, input: $input) @rest(type: "Payment", path: "payments/{args.paymentId}", method: "DELETE") {
      ...Payment
    }
  }
  ${paymentFragment}
`;
