import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import Table, { DateTableCell } from '@ts-core/components/Table';

const AcademyCoursesTable = (props) => {
  const { courses, loading, error, onFilterCourses, onSelectCourse } = props;

  const intl = useIntl();

  const totalStudents = useMemo(() => {
    return courses.reduce((total, course) => total + course.totalStudents, 0);
  }, [courses]);

  const totalDebt = useMemo(() => {
    return courses.reduce((total, course) => total + course.totalDebt, 0);
  }, [courses]);

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({ id: 'billing.coursesTable.name' }),
        value: intl.formatMessage({ id: 'billing.coursesTable.name' }),
        accessor: 'name',
        sortable: true,
      },
      {
        Header: intl.formatMessage({ id: 'billing.coursesTable.owner' }),
        value: intl.formatMessage({ id: 'billing.coursesTable.owner' }),
        accessor: 'owner.name',
        sortable: true,
      },
      {
        Header: intl.formatMessage({ id: 'billing.coursesTable.periodStatus' }),
        value: intl.formatMessage({ id: 'billing.coursesTable.periodStatus' }),
        accessor: 'periodStatus',
        sortable: true,
        valueTransformer: (value) => {
          return value === 'opened' ? <LockOpenIcon /> : <LockIcon />;
        },
      },
      {
        Header: intl.formatMessage({ id: 'billing.coursesTable.periodStatus' }),
        value: intl.formatMessage({ id: 'billing.coursesTable.periodStatus' }),
        id: 'periodStatus',
        accessor: 'periodStatus',
        filterable: true,
        valueTransformer: (value) => {
          return value === 'opened' ? 'Abierto' : 'Cerrado';
        },
        show: false,
      },
      {
        Header: intl.formatMessage({ id: 'billing.coursesTable.periodStart' }),
        value: intl.formatMessage({ id: 'billing.coursesTable.periodStart' }),
        accessor: 'periodDateInit',
        sortable: true,
        valueTransformer: (value) => (
          <DateTableCell date={value} format="DD/MM/YYYY" />
        ),
      },
      {
        Header: intl.formatMessage({ id: 'billing.coursesTable.periodEnd' }),
        value: intl.formatMessage({ id: 'billing.coursesTable.periodEnd' }),
        accessor: 'periodDateEnd',
        sortable: true,
        valueTransformer: (value) => (
          <DateTableCell date={value} format="DD/MM/YYYY" />
        ),
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.duration',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.duration',
        }),
        accessor: 'periodDuration',
        sortable: true,
      },
      {
        Header: intl.formatMessage({
          id: 'billing.coursesTable.billableStudents',
        }),
        value: intl.formatMessage({
          id: 'billing.coursesTable.billableStudents',
        }),
        accessor: 'totalStudents',
        sortable: true,
        Footer: `${totalStudents}`,
      },
      {
        Header: intl.formatMessage({
          id: 'billing.coursesTable.cost',
        }),
        value: intl.formatMessage({
          id: 'billing.coursesTable.cost',
        }),
        accessor: 'totalDebt',
        sortable: true,
        valueTransformer: (value) => `$${value}`,
        Footer: `$${totalDebt}`,
      },
    ],
    [intl, totalStudents, totalDebt]
  );

  return (
    <Table
      title="Cursos"
      data={courses}
      columns={columns}
      loading={loading}
      errorMessage={error}
      onSelectRow={onSelectCourse}
      onSearch={onFilterCourses}
    />
  );
};

AcademyCoursesTable.propTypes = {
  courses: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.string,

  onSelectCourse: PropTypes.func,
  onFilterCourses: PropTypes.func,
};

AcademyCoursesTable.defaultProps = {
  courses: [],
  error: undefined,
  loading: false,

  onSelectCourse: undefined,
  onFilterCourses: undefined,
};

export default AcademyCoursesTable;
