import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@ts-core/components/Button';

import ConfirmationDialog, { useConfirmationDialog } from '@ts-core/components/ConfirmationDialog';

const ConfirmButton = (props) => {
  const { renderButton, alertTitle, alertMessage, confirmActionTitle, cancelActionTitle, children, onConfirm, onCancel, ...buttonProps } = props;

  const { onOpen, props: confirmationDialogProps } = useConfirmationDialog({
    title: alertTitle,
    message: alertMessage,
    confirmActionTitle,
    cancelActionTitle,
    onConfirm,
    onCancel,
  });

  const handleOpenConfirmDialog = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onOpen(event);
    },
    [onOpen]
  );

  function renderContent() {
    if (renderButton) {
      return renderButton({ onClick: handleOpenConfirmDialog });
    }
    if (typeof children === 'function') {
      return children({ onClick: handleOpenConfirmDialog });
    }

    return (
      <Button {...buttonProps} onClick={onOpen}>
        {children}
      </Button>
    );
  }

  return (
    <>
      <ConfirmationDialog {...confirmationDialogProps} />
      {renderContent()}
    </>
  );
};

ConfirmButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  renderButton: PropTypes.func,
  variant: PropTypes.oneOf(['outlined', 'contained', 'text']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,

  alertTitle: PropTypes.node,
  alertMessage: PropTypes.node,
  confirmActionTitle: PropTypes.node,
  cancelActionTitle: PropTypes.node,

  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

ConfirmButton.defaultProps = {
  renderButton: undefined,
  children: undefined,
  variant: undefined,
  size: undefined,
  color: undefined,
  loading: false,
  disabled: false,

  alertTitle: '',
  alertMessage: '',
  confirmActionTitle: <FormattedMessage id="button.yes.title" />,
  cancelActionTitle: <FormattedMessage id="button.no.title" />,
  onConfirm: undefined,
  onCancel: undefined,
};

export default ConfirmButton;
