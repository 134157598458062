import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Button from '@ts-core/components/Button';
import { useTextField } from '@ts-core/hooks/ui';

const useStyles = makeStyles((theme) => ({
  sendPaymentEmailButton: {
    marginLeft: theme.spacing(2),
    minWidth: 100,
  },
}));

const SendPaymentUrlForm = (props) => {
  const classes = useStyles();

  const {
    // academy,
    onSendPaymentEmail,
    sendingPaymentEmail,
    sendPaymentEmailError,
  } = props;

  const { onReset, ...textFieldProps } = useTextField();
  const { value: paymentUrl } = textFieldProps;
  const handleSendPaymentEmail = useCallback(() => {
    onSendPaymentEmail({ paymentUrl });
  }, [paymentUrl, onSendPaymentEmail]);

  return (
    <Box>
      <Box display="flex" flexDirection="row" width={{ xs: '100%', md: '50%' }}>
        <TextField
          variant="outlined"
          label="URL Pago"
          fullWidth
          {...textFieldProps}
        />
        <Button
          loading={sendingPaymentEmail}
          disabled={!paymentUrl}
          className={classes.sendPaymentEmailButton}
          onClick={handleSendPaymentEmail}
        >
          Enviar Email
        </Button>
      </Box>
      {sendPaymentEmailError && (
        <Typography variant="caption" color="error">
          {sendPaymentEmailError}
        </Typography>
      )}
    </Box>
  );
};

SendPaymentUrlForm.propTypes = {
  academy: PropTypes.object,
  sendingPaymentEmail: PropTypes.bool,
  sendPaymentEmailError: PropTypes.string,

  onSendPaymentEmail: PropTypes.func,
};

SendPaymentUrlForm.defaultProps = {
  academy: {},
  sendingPaymentEmail: false,
  sendPaymentEmailError: undefined,

  onSendPaymentEmail() {},
};

export default SendPaymentUrlForm;
