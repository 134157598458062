/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import MuiButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button';

export interface ButtonProps extends MUIButtonProps {
  loading?: boolean;
  disableShadow?: boolean;

  linkComponentProps?: object | (() => object);
}

const Button = forwardRef<HTMLAnchorElement, ButtonProps>((props, ref) => {
  const {
    children,
    disabled,
    loading,
    disableShadow = true,
    sx,
    linkComponentProps,
    ...otherProps
  } = props;

  return (
    <MuiButton
      disabled={disabled || loading}
      endIcon={loading && <CircularProgress size={16} sx={{ opacity: 0.5 }} />}
      {...otherProps}
      ref={ref as any}
      sx={{
        ...sx,
        boxShadow: disableShadow ? 0 : 1,
      }}
    >
      {children}
    </MuiButton>
  );
});

export default Button;
