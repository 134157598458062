import { Payment, CreatePaymentInput } from './index';
import { formatDate } from '@ts-core/utils/dateFormatters';

export function transformPaymentToFormInput(payment?: Payment): CreatePaymentInput {
  const dateFormat = 'yyyy-MM-DD';
  if (!payment) return { amount: 0, currency: 'UYU', description: '', date: formatDate(Date(), dateFormat) };
  return {
    amount: payment.amount,
    currency: payment.currency,
    description: payment.description,
    date: formatDate(payment.date, dateFormat),
  };
}
