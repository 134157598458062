import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import baseStyles from '@ts-core/styles/baseStyles';

import TSDrawer from '@ts-core/components/Drawer/TSDrawer';
import UserMenu from '@ts-core/components/NavigationBar/UserMenu';

import Box from '@mui/material/Box';

import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme) => ({
  ...baseStyles(theme),
  root: {
    width: '100%',
    height: 64,
    [theme.breakpoints.down('md')]: {
      height: 56,
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  titleLink: {
    color: 'unset',
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

const NavigationBar = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { user, topMenuItems, onSignOut, profileUrl } = props;

  const classes = useStyles();

  const handleOpenMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, [setIsMenuOpen]);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="default" elevation={1}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
            onClick={handleOpenMenu}
            size="large">
            <MenuIcon />
          </IconButton>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" component="p" color="inherit" noWrap>
              <Link to="/dashboard" className={classes.titleLink}>
                Check
              </Link>
            </Typography>
            <Typography variant="caption">Admin</Typography>
          </Box>
          <div className={classes.grow} />
          <Box display="flex" flexDirection="row">
            <UserMenu
              user={user}
              onSignOut={onSignOut}
              profileUrl={profileUrl}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <TSDrawer
        isOpen={isMenuOpen}
        course={{}}
        user={user}
        courseMenuItems={[]}
        topMenuItems={topMenuItems}
        onClose={handleCloseMenu}
      />
    </div>
  );
};

NavigationBar.propTypes = {
  user: PropTypes.object.isRequired,
  topMenuItems: PropTypes.array.isRequired,
  profileUrl: PropTypes.string.isRequired,

  onSignOut: PropTypes.func.isRequired,
};

export default NavigationBar;
