import { ApolloClient, InMemoryCache, ApolloLink, throwServerError, defaultDataIdFromObject, ServerError } from '@apollo/client';

import { RestLink } from 'apollo-link-rest';
import { onError } from '@apollo/client/link/error';

import { omitTypenameLink } from './omitVariableTypenameLink';

export const createApolloClient = ({ uri = '/v2' } = {}) => {
  const cache = new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'IsAuthenticatedPayload': {
          return `IsAuthenticatedPayload:static-id`;
        }
        case 'ActivityOverview': {
          const { year, month } = responseObject;
          let result = `ActivityOverview-${year}`;
          if (month) {
            result += `-${month}`;
          }
          return result;
        }
        case 'AcademyActivityByMonth': {
          const { year, month } = responseObject;
          const academy: any = responseObject.academy;
          return `AcademyActivityByMonth:${academy?.['_id']}-${year}-${month}`;
        }
        case 'CourseActivityByMonth': {
          const { year, month, _id } = responseObject;
          return `CourseActivityByMonth:${_id}-${year}-${month}`;
        }
        default: {
          return defaultDataIdFromObject(responseObject);
        }
      }
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
      // return Observable.of(operation);
    }
    if (networkError) {
      // Hack for: https://github.com/apollographql/apollo-link-rest/issues/172
      if (networkError.message !== 'forward is not a function') {
        if (process.env.REACT_APP_CHECK_ENV === 'dev') {
          console.error(`[Network error]`, networkError);
        }
        const serverError = networkError as ServerError;
        if (serverError && serverError.statusCode === 401) {
          // Reload page
          window.location.href = '/';
          console.log(serverError.statusCode);
        }
      }
      // return Observable.of(operation);
    }
    return forward(operation);
  });

  const restLink = new RestLink({
    uri,
    credentials: 'include',
    customFetch: async (request, init) => {
      const response = await fetch(request, init);
      if (response.status === 404) {
        const result = await response.clone().json();

        throwServerError(response, result, `Response not successful: Received status code ${response.status}`);
      } else {
        return response;
      }
    },
  });

  return new ApolloClient({
    cache,
    link: ApolloLink.from([omitTypenameLink, errorLink, restLink]),
  });
};
