import { gql } from '@apollo/client';

import { Payment, CreatePaymentInput } from '../../../types/payments';
import { paymentFragment } from '../fragments';

export interface UpdatePaymentMutationVariables {
  paymentId: String;
  input: CreatePaymentInput;
}

export interface UpdatePaymentMutationResult {
  payment: Payment;
}

export const updatePaymentMutation = gql`
  mutation UpdatePaymentMutation($paymentId: String!, $input: UpdatePaymentMutationInput!) {
    payment(paymentId: $paymentId, input: $input) @rest(type: "Payment", path: "payments/{args.paymentId}", method: "PUT") {
      ...Payment
    }
  }
  ${paymentFragment}
`;
