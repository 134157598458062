import React from 'react';

import TableRow from '@mui/material/TableRow';
import MUITableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';

interface TableFooterProps {
  state: any;
}

const TableFooter = (props: TableFooterProps) => {
  const { state } = props;
  const { hasColumnFooter } = state;

  if (!hasColumnFooter) {
    return null;
  }

  return (
    <MUITableFooter>
      <TableRow sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
        {state.allVisibleColumns.map((column: any, cIndex: number) => (
          <TableCell key={cIndex} className={column.className} padding="normal">
            {column.Footer}
          </TableCell>
        ))}
      </TableRow>
    </MUITableFooter>
  );
};

export default TableFooter;
