import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

import DrawerHeader from './DrawerHeader';
import DrawerCourseMenu from './DrawerCourseMenu';
import DrawerGeneralMenu from './DrawerGeneralMenu';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 304,
  },
  mobileOnly: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const TSDrawer = (props) => {
  const { isOpen, user, courseMenuItems, course, topMenuItems, onClose } =
    props;

  const classes = useStyles();

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.drawer }}
      variant="temporary"
    >
      <DrawerHeader user={user} course={course} />
      <Divider className={classes.mobileOnly} />
      <DrawerGeneralMenu menuItems={topMenuItems} onClick={onClose} />
      <Divider />
      <DrawerCourseMenu
        course={course}
        menuItems={courseMenuItems}
        onClick={onClose}
      />
      {course?._id && <Divider />}
    </Drawer>
  );
};

TSDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  courseMenuItems: PropTypes.array,
  topMenuItems: PropTypes.array,
  course: PropTypes.object,

  onClose: PropTypes.func.isRequired,
};

TSDrawer.defaultProps = {
  courseMenuItems: [],
  topMenuItems: [],
  course: {},
};

export default TSDrawer;
