import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const useStyles = makeStyles(() => ({
  userButtonIcon: {
    fontSize: 32,
  },
}));

const UserMenu = (props) => {
  const { user, onSignOut, profileUrl } = props;

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuOpen = Boolean(menuAnchor);
  const classes = useStyles();
  const { profilePictureUrl } = user;

  function renderAvatar() {
    return profilePictureUrl ? <Avatar src={profilePictureUrl} /> : <AccountCircle className={classes.userButtonIcon} />;
  }

  function renderMenu() {
    return (
      <Menu
        open={isMenuOpen}
        keepMounted
        onClose={() => setMenuAnchor(null)}
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem divider component={Link} to={profileUrl} onClick={() => setMenuAnchor(null)}>
          <ListItemIcon>{renderAvatar()}</ListItemIcon>
          <ListItemText primary={user.name} secondary={user.email} />
        </MenuItem>

        <MenuItem
          onClick={() => {
            setMenuAnchor(null);
            onSignOut();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Cerrar sesión</ListItemText>
        </MenuItem>
      </Menu>
    );
  }

  return (
    <div>
      <IconButton
        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
        aria-haspopup="true"
        color="inherit"
        onClick={(e) => setMenuAnchor(e.target)}
        size="large"
      >
        {renderAvatar()}
      </IconButton>
      {renderMenu()}
    </div>
  );
};

UserMenu.propTypes = {
  user: PropTypes.object.isRequired,
  profileUrl: PropTypes.string.isRequired,

  onSignOut: PropTypes.func.isRequired,
};

export default UserMenu;
