import { useCallback } from 'react';
import { useMutation } from '@ts-core/lib/apollo';
import { addItemToCache } from '@ts-core/core/utils/apolloUtils';
import { useSnackbarNotifications } from '@ts-core/core/hooks/ui';

import { CreatePaymentInput } from '../../types/payments';

import {
  getAcademyPaymentsQuery,
  GetAcademyPaymentsQueryResult,
  GetAcademyPaymentsQueryVariables,
} from '../../graphql/payments/queries/getAcademyPaymentsQuery';
import {
  createAcademyPaymentMutation,
  CreateAcademyPaymentMutationVariables,
  CreateAcademyPaymentMutationResult,
} from '../../graphql/payments/mutations/createAcademyPaymentMutation';

interface CreateAcademyPaymentHookInput {
  academyId: string;
  year: string;
  month: string;
}

interface CreateAcademyPaymentPayload {
  onCreateAcademyPayment: (input: CreatePaymentInput, onCreated?: () => void) => void;
  createAcademyPaymentLoading: boolean;
  createAcademyPaymentError?: string;
}

export function useCreateAcademyPayment(input: CreateAcademyPaymentHookInput): CreateAcademyPaymentPayload {
  const { academyId, year, month } = input;

  const { showErrorNotification } = useSnackbarNotifications();

  /**
   * Create Academy Payment
   */
  const [createAcademyPayment, { loading: createAcademyPaymentLoading, errorMessage: createAcademyPaymentError }] = useMutation<
    CreateAcademyPaymentMutationResult,
    CreateAcademyPaymentMutationVariables
  >(createAcademyPaymentMutation, {
    onError: showErrorNotification,
    update: addItemToCache<CreateAcademyPaymentMutationResult, GetAcademyPaymentsQueryResult, GetAcademyPaymentsQueryVariables>({
      query: getAcademyPaymentsQuery,
      queryName: 'payments',
      queryVariables: {
        academyId,
        year,
        month,
      },
      mutationName: 'payment',
    }),
  });
  const onCreateAcademyPayment = useCallback(
    (input: CreatePaymentInput, onCreated?: () => void) => {
      createAcademyPayment({
        variables: {
          academyId,
          year,
          month,
          input,
        },
      }).then(onCreated);
    },
    [academyId, year, month, createAcademyPayment]
  );

  return {
    onCreateAcademyPayment,
    createAcademyPaymentLoading,
    createAcademyPaymentError,
  };
}
