import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

const SwitchTableCell = (props) => {
  const { item, className, onChange, getValue } = props;

  const handleChange = useCallback(() => {
    onChange(item);
  }, [item, onChange]);

  const handleSwitchClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <Box width="100%" className={className}>
      <Switch color="secondary" onClick={handleSwitchClick} onChange={handleChange} checked={getValue(item) || false} />
    </Box>
  );
};

SwitchTableCell.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,

  getValue: PropTypes.func,
};

SwitchTableCell.defaultProps = {
  className: undefined,
  getValue: (item) => item.enabled,
};

export default SwitchTableCell;
