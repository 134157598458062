export function priceFormatter(currency: string | undefined, amount: number | undefined): string {
  if (amount === undefined || currency === undefined) {
    return '-';
  }
  let formatter = new Intl.NumberFormat('es-UY', {
    style: 'currency',
    currency: currency,
  });

  return formatter.format(amount);
}
