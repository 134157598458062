import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import checkLogo from '@ts-core/img/logoDrawer.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(1),
  },
}));

const DrawerHeader = (props) => {
  const classes = useStyles();
  const { user, course } = props;

  let { academy } = user;
  if (!academy) {
    academy = course?.academy || {};
  }
  const { logoImageUrl } = academy;

  return (
    <Box py={3} px={5} textAlign="center">
      <img
        className={classes.logo}
        src={logoImageUrl || checkLogo}
        alt="logo de academy"
      />
      {!logoImageUrl && (
        <Typography>La forma más práctica de estudiar</Typography>
      )}
    </Box>
  );
};

DrawerHeader.propTypes = {
  user: PropTypes.object,
  course: PropTypes.object,
};

DrawerHeader.defaultProps = {
  user: {},
  course: {},
};

export default DrawerHeader;
