/* eslint-disable no-console */
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import getErrorMessage from './getErrorMessage';

function useMutationCompletionHandlers({ successKey } = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const onError = useCallback(
    (error) => {
      const errorMessage = getErrorMessage({ error });
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      console.error(error);
    },
    [enqueueSnackbar]
  );
  const onCompleted = useCallback(() => {
    enqueueSnackbar(
      intl.formatMessage({
        id: successKey || 'generic.successOperation.message',
        defaultMessage: 'Operation completed',
      }),
      {
        variant: 'success',
      }
    );
  }, [enqueueSnackbar, intl, successKey]);

  const addItemToCache = useCallback(
    ({ query, variables, queryField, mutationField, insertFirst = false }) =>
      (store, { data }) => {
        try {
          const createdItem = data?.[mutationField];

          if (!createdItem) return;

          const currentData = store.readQuery({ query, variables });

          if (!currentData) return;

          const list = currentData[queryField];

          if (!list) return;
          let newData = [];
          if (insertFirst) {
            newData = [createdItem, ...list];
          } else {
            newData = [...list, createdItem];
          }
          store.writeQuery({
            query,
            variables,
            data: { [queryField]: newData },
          });
        } catch (error) {
          console.error(error);
        }
      },
    []
  );

  const removeItemFromCache =
    ({
      queryField,
      mutationField,
      query,
      variables = {},
      idAccessor = '_id',
    }) =>
    (store, { data }) => {
      try {
        const removedItem = data?.[mutationField];

        if (!removedItem) return;

        const removedItemId = removedItem?.[idAccessor];
        const currentData = store.readQuery({ query, variables });

        if (!currentData) return;

        const list = currentData[queryField];

        if (!list) return;

        const newData = list.filter((i) => i[idAccessor] !== removedItemId);
        store.writeQuery({
          query,
          variables,
          data: { [queryField]: newData },
        });
      } catch (error) {
        console.error(error);
      }
    };

  return {
    onError,
    onCompleted,
    addItemToCache,
    removeItemFromCache,
  };
}

export default useMutationCompletionHandlers;
