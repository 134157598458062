import { useCallback } from 'react';
import { useMutation } from '@ts-core/lib/apollo';

import { useMutationCompletionHandler, getErrorMessage } from '@ts-core/lib/apollo';

import { createAcademyProfessorMutation } from 'graphql/professors/mutations';
import { getAcademyProfessorsQuery } from 'graphql/professors/queries';

export default function useAcademyProfessor({ academyId }) {
  const { onCompleted, addItemToCache } = useMutationCompletionHandler();

  /**
   * Create Professor
   */
  const [createProfessor, { loading: creatingProfessor, error: createProfessorError }] = useMutation(createAcademyProfessorMutation, {
    onCompleted,
    update: addItemToCache({
      query: getAcademyProfessorsQuery,
      queryField: 'professors',
      variables: {
        academyId,
      },
      mutationField: 'professor',
    }),
  });
  const onCreateProfessor = useCallback(
    (input) => {
      createProfessor({
        variables: {
          academyId,
          input,
        },
      });
    },
    [createProfessor, academyId]
  );

  return {
    onCreateProfessor,
    creatingProfessor,
    createProfessorError: getErrorMessage({ error: createProfessorError }),
  };
}
