import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';

import BackButton from '@ts-core/components/BackButton';
import NewAcademyForm from 'components/NewAcademyForm';

import { useAcademy, useAcademyFeatures, useAcademyPricingModes, useAcademyCurrencies } from 'hooks/academies';

const NewAcademyPage = () => {
  const history = useHistory();

  const { onCreateAcademy, creatingAcademy, createAcademyError } = useAcademy();
  const { features } = useAcademyFeatures();
  const { pricingModes } = useAcademyPricingModes();
  const { currencies } = useAcademyCurrencies();

  const handleGoBack = useCallback(() => {
    history.push('/academies');
  }, [history]);

  return (
    <Box>
      <BackButton onGoBack={handleGoBack} title="Agregar Academia" subtitle="Academias" />
      <Box mt={4} maxWidth={800}>
        <NewAcademyForm
          onSubmit={onCreateAcademy}
          submitting={creatingAcademy}
          error={createAcademyError}
          features={features}
          pricingModes={pricingModes}
          currencies={currencies}
        />
      </Box>
    </Box>
  );
};

export default NewAcademyPage;
