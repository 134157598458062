import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { AcademyActivityByMonth } from '../types';
import {
  getAcademyActivityByMonthQuery,
  GetAcademyActivityByMonthQueryResult,
  GetAcademyActivityByMonthQueryVariables,
} from '../graphql/queries/getAcademyActivityByMonthQuery';

export type AcademyActivityByMonthHookResult = DataFetcherHookResult<AcademyActivityByMonth, 'academyActivity'>;

export interface AcademyActivityByMonthHookInput {
  academyId: string;
  year: string;
  month: string;
}

export function useAcademyActivityByMonth(input: AcademyActivityByMonthHookInput): AcademyActivityByMonthHookResult {
  const { academyId, year, month } = input;
  const { data, loading, errorMessage } = useQuery<GetAcademyActivityByMonthQueryResult, GetAcademyActivityByMonthQueryVariables>(
    getAcademyActivityByMonthQuery,
    { variables: { academyId, year, month } }
  );

  return { loading, error: errorMessage, academyActivity: data?.academyActivity };
}
