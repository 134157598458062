import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';

import TSTable from '@ts-core/components/Table';
import { TSTableColumn } from '@ts-core/components/Table/TSTable/TSTable';

import { filterData } from '@ts-core/utils/dataTableUtils';
import { priceFormatter } from '@ts-core/utils/priceFormatter';
import { AcademyActivityByMonth } from '../types';

interface AcademyActivityByMonthTableProps {
  academyActivity?: AcademyActivityByMonth;
  loading?: boolean;

  onGoToCourse: (courseId: string) => void;
}

export function AcademyActivityByMonthTable(props: AcademyActivityByMonthTableProps) {
  const { onGoToCourse, loading = false, academyActivity } = props;
  const [query, setQuery] = useState<string>('');

  const columns = useMemo(
    (): TSTableColumn<AcademyActivityByMonth['courses'][0]>[] => [
      {
        Header: 'Curso',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Profesor',
        accessor: 'professor.name',
        sortable: true,
      },
      {
        Header: 'Alumnos',
        accessor: 'studentsCount',
        sortable: true,
        Footer: `${academyActivity?.studentsCount}`,
      },
      {
        Header: 'Visualizaciones de Video',
        accessor: 'mediaSessionsCount',
        sortable: true,
        Footer: `${academyActivity?.mediaSessionsCount}`,
      },
      {
        Header: 'Entregas',
        accessor: 'assignmentAnswersCount',
        sortable: true,
        Footer: `${academyActivity?.assignmentAnswersCount}`,
      },
      {
        Header: 'Prácticas',
        accessor: 'practiceSessionsCount',
        sortable: true,
        Footer: `${academyActivity?.practiceSessionsCount}`,
      },
      {
        Header: 'Tareas',
        accessor: 'activityAnswersCount',
        sortable: true,
        Footer: `${academyActivity?.activityAnswersCount}`,
      },
      {
        Header: 'Costo',
        accessor: 'cost',
        sortable: true,
        valueTransformer: (value) => priceFormatter(academyActivity?.academy?.pricing?.currency, value),
        Footer: priceFormatter(academyActivity?.academy?.pricing?.currency, academyActivity?.totalCost),
      },
    ],
    [academyActivity]
  );

  const keys = ['name', 'professor.name'];
  const filteredData: AcademyActivityByMonth['courses'] = filterData(academyActivity?.courses, query, keys);

  return (
    <Box>
      <TSTable
        data={filteredData}
        loading={loading}
        columns={columns}
        onSearch={(text) => setQuery(text)}
        allowDownloadAsXLSX
        onSelectRow={(item) => onGoToCourse(item._id)}
      />
    </Box>
  );
}
