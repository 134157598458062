import { gql } from '@apollo/client';

export interface RequestResetPasswordMutationVariables {
  input: { email: string };
}

export const requestResetPasswordMutation = gql`
  mutation RequestResetPasswordMutation(
    $input: RequestResetPasswordMutationInput!
  ) {
    field(input: $input)
      @rest(
        type: "RequestResetPasswordPayload"
        path: "auth/reset-password"
        method: "POST"
      ) {
      NoResponse
    }
  }
`;
