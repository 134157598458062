import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/es';

import Box from '@mui/material/Box';

import { ImageUploaderAdapterPlugin } from '@ts-core/lib/ckeditor';
import { useApiClient } from '@ts-core/lib/apiClient';

import './styles.css';

const RichTextField = (props) => {
  const { value, onChange, onBusyStatsChange } = props;

  const { courseId } = useParams();

  const apiClient = useApiClient();

  const handlePendingActions = useCallback(
    (editor) => {
      const pendingActions = editor.plugins.get('PendingActions');
      pendingActions.on('change:hasAny', (e, propertyName, newValue) => {
        onBusyStatsChange(newValue);
      });
    },
    [onBusyStatsChange]
  );

  const handleDataChange = useCallback(
    (event, editor) => {
      const editorData = editor.getData();
      onChange(editorData);
    },
    [onChange]
  );

  return (
    <Box mt={2} width="100%">
      <CKEditor
        data={value}
        editor={ClassicEditor}
        config={{
          language: 'es',
          extraPlugins: [ImageUploaderAdapterPlugin(apiClient, { courseId })],
          mediaEmbed: {
            previewsInData: true,
          },
          toolbar: {
            items: [
              'heading',
              '|',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
              '|',
              'blockQuote',
              'insertTable',
              '|',
              'imageUpload',
              'mediaEmbed',
              'undo',
              'redo',
            ],
          },
        }}
        onReady={handlePendingActions}
        onChange={handleDataChange}
      />
    </Box>
  );
};

RichTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBusyStatsChange: PropTypes.func,
};

RichTextField.defaultProps = {
  value: '',
  onBusyStatsChange: undefined,
};

export default RichTextField;
