import { gql } from '@apollo/client';

export default gql`
  mutation SendPaymentEmailMutation(
    $academyId: String!
    $input: SendPaymentEmailMutationBody!
  ) {
    sendPaymentEmail(academyId: $academyId, input: $input)
      @rest(
        type: "AcademyPaymentEmail"
        path: "academies/{args.academyId}/send-payment-email"
        method: "POST"
      ) {
      NoResponse
    }
  }
`;
