import React from 'react';

import MUITableCell from '@mui/material/TableCell';

interface TableCellProps {
  row: any;
  column: any;
}

const TableCell = (props: TableCellProps) => {
  const { column, row } = props;
  const value = row[column.id];

  return (
    <MUITableCell className={column.className} padding="normal">
      {column.valueTransformer ? column.valueTransformer(value, column, row._original) : value}
    </MUITableCell>
  );
};

export default TableCell;
