import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';

import BackButton from '@ts-core/components/BackButton';
import SendPaymentUrlForm from 'components/SendPaymentUrlForm';

import { useAcademy } from 'hooks/academies';

const AcademyPaymentsPage = () => {
  const { academyId } = useParams();
  const history = useHistory();

  const { academy, onSendPaymentEmail, sendingPaymentEmail, sendPaymentEmailError } = useAcademy({
    academyId,
  });

  const handleGoBack = useCallback(() => {
    history.push(`/academies/${academyId}/details`);
  }, [history, academyId]);

  return (
    <Box>
      <BackButton onGoBack={handleGoBack} title={academy?.name} subtitle="Academias" />
      <Box p={2} mt={2}>
        <SendPaymentUrlForm
          academy={academy}
          onSendPaymentEmail={onSendPaymentEmail}
          sendingPaymentEmail={sendingPaymentEmail}
          sendPaymentEmailError={sendPaymentEmailError}
        />
      </Box>
    </Box>
  );
};

export default AcademyPaymentsPage;
