import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';

import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MUDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    marginBottom: theme.spacing(0),
  },
  title: {
    flex: 1,
  },
  closeButton: {
    marginLeft: -theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
  },
  dialogPaper: {
    minWidth: '50%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 800,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1000,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1200,
    },
  },
}));

const Dialog = (props) => {
  const {
    open,
    loading,
    children,
    dialogData,
    noPadding,
    toolbarColor,
    actions,
    title,
    onClose,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();

  function renderChildren() {
    if (typeof children === 'function') {
      return children(dialogData);
    }
    return children;
  }

  const handleClose = useCallback(
    (event, reason) => {
      if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <MUDialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullScreen={fullScreen}
      disableRestoreFocus
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <AppBar className={classes.appBar} color={toolbarColor}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={onClose}
            className={classes.closeButton}
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <DialogContent
        className={classes.dialogContent}
        style={{ padding: noPadding ? 0 : 16 }}
      >
        {renderChildren()}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </MUDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  dialogData: PropTypes.object,
  noPadding: PropTypes.bool,
  toolbarColor: PropTypes.string,
  actions: PropTypes.node,
  title: PropTypes.string,

  onClose: PropTypes.func,
};

Dialog.defaultProps = {
  open: false,
  loading: false,

  dialogData: {},
  noPadding: false,
  toolbarColor: 'primary',
  title: '',
  actions: undefined,

  onClose() {},
};

export default Dialog;
