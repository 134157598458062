import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { Payment } from '../../types/payments';
import {
  getAcademyPaymentsQuery,
  GetAcademyPaymentsQueryResult,
  GetAcademyPaymentsQueryVariables,
} from '../../graphql/payments/queries/getAcademyPaymentsQuery';

export type AcademyPaymentsHookResult = DataFetcherHookResult<Payment[], 'payments'>;

export interface AcademyPaymentsHookInput {
  academyId: string;
  year: string;
  month: string;
}

export function useAcademyPayments(input: AcademyPaymentsHookInput): AcademyPaymentsHookResult {
  const { data, loading, errorMessage } = useQuery<GetAcademyPaymentsQueryResult, GetAcademyPaymentsQueryVariables>(getAcademyPaymentsQuery, {
    variables: input,
  });
  return { loading, error: errorMessage, payments: data?.payments };
}
