import { useCourseCurrentPeriodDetails as useCoreCourseCurrentPeriodDetails } from '@ts-core/hooks/billingCourse';

import { getBilledCourseCurrentPeriodDetails } from 'graphql/courses/queries';

export default function useBilledCourseCurrentPeriodDetails({ academyId, courseId }) {
  return useCoreCourseCurrentPeriodDetails({
    query: getBilledCourseCurrentPeriodDetails,
    variables: {
      academyId,
      courseId,
    },
    skip: !academyId || !courseId,
  });
}
