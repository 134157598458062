/* eslint-disable no-unused-expressions */
import { useState, useCallback } from 'react';

export default function useConfirmationDialog({
  title,
  message,
  confirmActionTitle,
  cancelActionTitle,
  onConfirm,
  onCancel,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDialog = useCallback((event) => {
    event?.stopPropagation();
    setIsOpen(true);
  }, []);

  const handleConfirmAction = useCallback(
    (event) => {
      event.stopPropagation();
      setIsOpen(false);
      onConfirm?.();
    },
    [onConfirm]
  );

  const handleCancelAction = useCallback(
    (event) => {
      event.stopPropagation();
      setIsOpen(false);
      onCancel?.();
    },
    [onCancel]
  );

  return {
    onOpen: handleOpenDialog,
    props: {
      open: isOpen,
      title,
      message,
      confirmActionTitle,
      cancelActionTitle,
      onConfirm: handleConfirmAction,
      onCancel: handleCancelAction,
    },
  };
}
