import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack';

import FormContainer from '@ts-core/components/FormContainer';
import Form, { FormTextField, FormSelect } from '@ts-core/core/components/FormV2';

const useStyles = makeStyles((theme) => ({
  roleSelector: {
    marginTop: theme.spacing(2),
  },
}));

const ProfessorFormSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required(),
  role: Yup.string().required(),
});

const ProfessorForm = (props) => {
  const classes = useStyles();
  const { onSubmit, submitting, error, roles } = props;

  const { handleSubmit, formState, control } = useForm({
    resolver: yupResolver(ProfessorFormSchema),
  });

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)} isSubmitting={submitting} submitError={error} formState={formState}>
        <Stack spacing={2}>
          <FormTextField control={control} name="name" rules={{ required: true }} fullWidth label="Nombre" />
          <FormTextField control={control} name="email" rules={{ required: true }} fullWidth label="Email" />
          <FormTextField control={control} name="password" type="password" rules={{ required: true }} fullWidth label="Contraseña" />
          <FormTextField control={control} name="confirmPassword" type="password" rules={{ required: true }} fullWidth label="Confirmar Contraseña" />
          <FormSelect
            className={classes.roleSelector}
            name="role"
            label="Role"
            control={control}
            items={roles}
            itemTitleKey="name"
            itemValueKey="_id"
            rules={{ required: true }}
          />
        </Stack>
      </Form>
    </FormContainer>
  );
};

ProfessorForm.propTypes = {
  onSubmit: PropTypes.func,

  submitting: PropTypes.bool,
  error: PropTypes.string,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    })
  ),
};

ProfessorForm.defaultProps = {
  onSubmit: undefined,

  roles: [],
  submitting: false,
  error: undefined,
};

export default ProfessorForm;
