import { gql } from '@apollo/client';

export interface SetNewPasswordMutationVariables {
  input: {
    token: string;
    password: string;
    confirmPassword: string;
  };
}

export const setNewPasswordMutation = gql`
  mutation SetNewPasswordMutation($input: SetNewPasswordMutationInput!) {
    setNewPasswordPayload(input: $input) @rest(type: "SetNewPasswordPayload", path: "/auth/set-password", method: "POST") {
      NoResponse
    }
  }
`;
