import { gql } from '@apollo/client';

import { academyFragment } from '../fragments';

export default gql`
  mutation UpdateAcademy($academyId: String!, $input: CreateAcademyBody!) {
    academy(academyId: $academyId, input: $input)
      @rest(
        type: "Academy"
        path: "academies/{args.academyId}"
        method: "PUT"
      ) {
      ...Academy
    }
  }
  ${academyFragment}
`;
