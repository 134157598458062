import React, { useCallback, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import BackButton from '@ts-core/components/BackButton';

import { useAcademyActivityByMonth } from '@ts-core/modules/billing/hooks/useAcademyActivityByMonth';
import { AcademyActivityByMonthTable } from '@ts-core/modules/billing/components/AcademyActivityByMonthTable';
import { YearMonthSelector, YearMonthDate } from '@ts-core/modules/billing/components/YearMonthSelector';

import { AcademyPaymentsTable } from 'components/activities/AcademyPaymentsTable';

import { CreatePaymentInput, Payment } from 'types/payments';

import useAcademy from 'hooks/academies/useAcademy';
import { useAcademyPayments } from 'hooks/payments/useAcademyPayments';
import { useUpdatePayment } from 'hooks/payments/useUpdatePayment';
import { useDeletePayment } from 'hooks/payments/useDeletePayment';
import { useCreateAcademyPayment } from 'hooks/payments/useCreateAcademyPayment';

export default function AcademyActivityPage() {
  const history = useHistory();
  const { academyId, month: urlMonth, year: urlYear } = useParams<{ academyId: string; year: string; month: string }>();
  const [date, setDate] = useState<YearMonthDate>({ year: urlYear, month: urlMonth });
  const { year, month } = date;
  const { academyActivity, loading } = useAcademyActivityByMonth({ academyId, month, year });
  const { academy } = useAcademy({ academyId });

  const { payments, loading: loadingPayments } = useAcademyPayments({ academyId, year, month });
  const { onUpdatePayment, updatePaymentLoading } = useUpdatePayment();
  const { onDeletePayment } = useDeletePayment();
  const { onCreateAcademyPayment, createAcademyPaymentLoading } = useCreateAcademyPayment({ academyId, year, month });

  const handleGoBack = useCallback(() => {
    history.push(`/dashboard/${year}/${month}`);
  }, [history, year, month]);

  const handleGoToCourse = useCallback(
    (courseId) => {
      history.push(`/dashboard/${year}/${month}/academies/${academyId}/${courseId}`);
    },
    [history, academyId, month, year]
  );

  const handleAddPayment = useCallback(
    (input: CreatePaymentInput, onCreated: () => void) => {
      onCreateAcademyPayment(input, onCreated);
    },
    [onCreateAcademyPayment]
  );
  const handleUpdatePayment = useCallback(
    (payment: Payment, input: CreatePaymentInput, onUpdated: () => void) => {
      onUpdatePayment(payment.id, input, onUpdated);
    },
    [onUpdatePayment]
  );
  const handleDeletePayment = useCallback(
    (payment: Payment) => {
      onDeletePayment(payment);
    },
    [onDeletePayment]
  );

  useEffect(() => {
    const { year, month } = date;
    history.replace(`/dashboard/${year}/${month}/academies/${academyId}`);
  }, [date, history, academyId]);

  return (
    <Box>
      <BackButton onGoBack={handleGoBack} title={academy?.name} subtitle="Todas las academias" />
      <YearMonthSelector sx={{ mt: 3, mb: 2 }} date={date} onChangeDate={setDate} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <AcademyPaymentsTable
            payments={payments}
            loading={loadingPayments}
            onAddPayment={handleAddPayment}
            isAddingPayment={createAcademyPaymentLoading}
            onUpdate={handleUpdatePayment}
            isUpdating={updatePaymentLoading}
            onDelete={handleDeletePayment}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <AcademyActivityByMonthTable academyActivity={academyActivity} loading={loading} onGoToCourse={handleGoToCourse} />
        </Grid>
      </Grid>
    </Box>
  );
}
