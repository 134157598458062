import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { ActivityOverview } from '../../types/activity';
import {
  getActivityOverviewQuery,
  GetActivityOverviewQueryResult,
  GetActivityOverviewQueryVariables,
} from '../../graphql/activity/queries/getActivityOverviewQuery';

export type ActivityOverviewHookResult = DataFetcherHookResult<ActivityOverview, 'activityOverview'>;

export interface ActivityOverviewHookInput {
  dates?: { year: string; month: string };
}

export function useActivityOverview(input: ActivityOverviewHookInput): ActivityOverviewHookResult {
  const { dates } = input;
  const { data, loading, errorMessage } = useQuery<GetActivityOverviewQueryResult, GetActivityOverviewQueryVariables>(getActivityOverviewQuery, {
    variables: {
      year: dates?.year ? Number(dates.year) : 0,
      month: dates?.month ? Number(dates.month) : 0,
    },
    skip: !dates,
  });

  return { loading, error: errorMessage, activityOverview: data?.activityOverview };
}
