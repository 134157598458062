import React from 'react';
import { useIntl } from 'react-intl';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export interface LoginFormFooterProps {
  text: React.ReactNode;
  url: string;
}

const LoginFormFooter = (props: LoginFormFooterProps) => {
  const intl = useIntl();

  const { text, url } = props;

  return (
    <Typography variant="body2" mt={2}>
      {text}

      <Link
        color="secondary"
        sx={{ fontWeight: 700, ml: 1 }}
        href={url}
        underline="hover"
      >
        {intl.formatMessage({ id: 'login.studentLoginHere' })}
      </Link>
    </Typography>
  );
};

export default LoginFormFooter;
