import { gql } from '@apollo/client';

import { userAccountFragment } from '../fragments';

export default gql`
  query UserAccount {
    userAccount @rest(type: "User", path: "profile") {
      ...User
    }
  }
  ${userAccountFragment}
`;
