/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { getUserBrowserLanguage } from '@ts-core/utils/languageUtils';
import { SnackbarProvider } from '@ts-core/lib/snackbar';
import { createTheme } from '@ts-core/core/theme';

import { createApolloClient } from 'lib/apollo';

import App from './App';

require('moment/locale/es');

moment.locale('es');

const currentLanguage = getUserBrowserLanguage();
const messages = require(`@ts-core/strings/${currentLanguage}.json`); // eslint-disable-line

// Theme
const theme = createTheme();

const apolloClient = createApolloClient();

const Application = (
  <IntlProvider locale={currentLanguage} messages={messages}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <SnackbarProvider>
            <Router>
              <App />
            </Router>
          </SnackbarProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </IntlProvider>
);

ReactDOM.render(Application, document.getElementById('root'));
