import { gql } from '@apollo/client';

import { academyListFragment } from '../fragments';

export default gql`
  query GetAcademies {
    academies @rest(type: "Academy", path: "academies") {
      ...AcademyListItem
    }
  }
  ${academyListFragment}
`;
