export function getTimeComponents(timeInSeconds) {
  if (!timeInSeconds) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }

  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
  const seconds = Math.floor(timeInSeconds - hours * 3600 - minutes * 60);

  return {
    hours,
    minutes,
    seconds,
  };
}

export default function getTimeString(timeInSeconds) {
  const { hours, minutes, seconds } = getTimeComponents(timeInSeconds);
  const getNumberString = (number) => {
    return number.toLocaleString('en-US', { minimumIntegerDigits: 2 });
  };

  return `${getNumberString(hours)}:${getNumberString(minutes)}:${getNumberString(seconds)}`;
}
