import React, { useMemo } from 'react';

import TSTable, { DateTableCell } from '@ts-core/components/Table';
import { TSTableColumn } from '@ts-core/components/Table/TSTable/TSTable';

import { formatValue } from '@ts-core/utils/dataTableUtils';

import { useStudentMediaSessionsByMonth } from '../hooks/useStudentMediaSessionsByMonth';

import { StudentMediaSessionActivity } from '../types';

export interface StudentMediaSessionsByMonthProps {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export function StudentMediaSessionsByMonth(props: StudentMediaSessionsByMonthProps) {
  const { mediaSessions, loading } = useStudentMediaSessionsByMonth(props);

  const columns = useMemo(
    (): TSTableColumn<StudentMediaSessionActivity>[] => [
      {
        Header: 'Video',
        accessor: 'video',
        sortable: true,
      },
      {
        Header: 'Comienzo',
        accessor: 'createdAt',
        valueTransformer: (value) => <DateTableCell date={value} />,
      },
      {
        Header: 'Último Evento',
        accessor: 'lastEventDate',
        valueTransformer: (value) => <DateTableCell date={value} />,
      },
      {
        Header: 'Plataforma',
        accessor: 'platform',
      },
      {
        Header: 'Progreso',
        accessor: 'maxProgressPercentage',
        valueTransformer: (value) => formatValue(value, 1),
        sortable: true,
      },
    ],
    []
  );

  return <TSTable title="Visualizaciones de Video" data={mediaSessions} loading={loading} columns={columns} allowDownloadAsXLSX />;
}
