import DashboardPage from './pages/activity/DashboardPage';
import AcademyActivityByMonthPage from './pages/activity/AcademyActivityByMonthPage';
import CourseActivityByMonthPage from './pages/activity/CourseActivityByMonthPage';
import StudentActivityMonthPage from './pages/activity/StudentActivityByMonthPage';

import AcademiesPage from './pages/AcademiesPage';
import NewAcademyPage from './pages/NewAcademyPage';
import EditAcademyPage from './pages/EditAcademyPage';
import AcademyDetailsPage from './pages/AcademyDetailsPage';
import CourseBillingPage from './pages/CourseBillingPage';
import AcademyPaymentsPage from './pages/AcademyPaymentsPage';

import NewProfessorPage from './pages/NewProfessorPage';

export default [
  { component: StudentActivityMonthPage, path: '/dashboard/:year/:month/academies/:academyId/:courseId/:studentId/:activityType', exact: true },
  { component: CourseActivityByMonthPage, path: '/dashboard/:year/:month/academies/:academyId/:courseId', exact: true },
  { component: AcademyActivityByMonthPage, path: '/dashboard/:year/:month/academies/:academyId', exact: true },
  { component: DashboardPage, path: '/dashboard/:year?/:month?' },

  { component: NewAcademyPage, path: '/academies/new' },
  { component: EditAcademyPage, path: '/academies/:academyId/edit' },
  { component: AcademyPaymentsPage, path: '/academies/:academyId/payments' },
  { component: AcademyDetailsPage, path: '/academies/:academyId/details' },
  {
    component: CourseBillingPage,
    path: '/academies/:academyId/courses/:courseId/:invoiceId?',
  },
  {
    component: NewProfessorPage,
    path: '/academies/:academyId/professors/new',
  },

  { component: AcademiesPage, path: '/academies', exact: true },
];
