import React from 'react';
import PropTypes from 'prop-types';

import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import MUITableRow from '@mui/material/TableRow';
import MUITableCell from '@mui/material/TableCell';

import DragIndicator from '@mui/icons-material/DragIndicator';

import TableCell from './TableCell';

const DragHandle = SortableHandle(() => {
  return <DragIndicator sx={{ cursor: 'grab' }} />;
});
const SortableRow = SortableElement((props) => {
  const { children, ...otherProps } = props;
  return <MUITableRow {...otherProps}>{children}</MUITableRow>;
});

const TableRow = (props) => {
  const { row, index, canSortRows, isNavigable, onSelectRow, state } = props;
  const { allVisibleColumns } = state;

  return (
    <SortableRow
      index={index}
      disabled={!canSortRows}
      key={index}
      sx={{
        cursor: isNavigable ? 'pointer' : 'unset',
        transition: 'background-color 0.2s ease',
        bgcolor: 'white',
        '&:hover': {
          bgcolor: 'grey.100',
        },
      }}
      onClick={() => onSelectRow && onSelectRow(row._original)}
    >
      {canSortRows && (
        <MUITableCell>
          <DragHandle />
        </MUITableCell>
      )}
      {allVisibleColumns.map((column, cIndex) => (
        <TableCell key={cIndex} column={column} row={row} />
      ))}
    </SortableRow>
  );
};

TableRow.propTypes = {
  state: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  canSortRows: PropTypes.bool,
  isNavigable: PropTypes.bool,

  onSelectRow: PropTypes.func,
};

TableRow.defaultProps = {
  canSortRows: false,
  isNavigable: false,

  onSelectRow: undefined,
};

export default TableRow;
