import { useMemo } from 'react';

import { Feature } from '@ts-core/utils/features';

export default function useAcademyFeatures() {
  const features = useMemo(
    () => [
      {
        title: 'VOD',
        id: Feature.VideoOnDemand,
      },
      {
        title: 'Ranking',
        id: Feature.Ranking,
      },
      {
        title: 'Foro',
        id: Feature.Forum,
      },
      {
        title: 'A/V Ejercicios',
        id: Feature.ExerciseAudioVideoOnDemand,
      },
      {
        title: 'Videos Externos',
        id: Feature.ExternalVideos,
      },
      {
        title: 'Documentos Externos',
        id: Feature.ExternalDocuments,
      },
      {
        title: 'Tareas',
        id: Feature.Activities,
      },
    ],
    []
  );

  return {
    features,
  };
}
