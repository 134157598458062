import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';

import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import AdminPrivateSection from './pages/AdminPrivateSection';

import { AuthProvider, useAuthState } from 'lib/auth';

const Routes = () => {
  const { authLoaded } = useAuthState();

  if (!authLoaded) {
    return <LinearProgress />;
  }

  return (
    <Switch>
      <Route component={LoginPage} path="/login" exact />
      <Route component={LogoutPage} path="/logout" exact />
      <Route component={AdminPrivateSection} />
    </Switch>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <CssBaseline />
      <Routes />
    </AuthProvider>
  );
};

export default App;
