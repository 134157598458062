import { useCallback } from 'react';
import { useQuery, useMutation } from '@ts-core/lib/apollo';

import { getErrorMessage, useMutationCompletionHandler } from '@ts-core/lib/apollo';

import { getAcademyByIdQuery, getAcademiesQuery } from 'graphql/academies/queries';
import { createAcademyMutation, updateAcademyMutation, sendPaymentEmailMutation } from 'graphql/academies/mutations';

export default function useAcademy({ academyId } = {}) {
  const { onError, onCompleted, addItemToCache } = useMutationCompletionHandler();

  const { data, loading, error } = useQuery(getAcademyByIdQuery, {
    variables: { academyId },
    skip: !academyId,
  });
  const academy = data?.academy;

  /**
   * Create Academy
   */
  const [createAcademy, { loading: creatingAcademy, error: createAcademyError }] = useMutation(createAcademyMutation, {
    onError,
    onCompleted,
    update: addItemToCache({
      query: getAcademiesQuery,
      queryField: 'academies',
      mutationField: 'academy',
    }),
  });
  const onCreateAcademy = useCallback(
    (input) => {
      createAcademy({
        variables: {
          input,
        },
      });
    },
    [createAcademy]
  );

  /**
   * Update Academy
   */
  const [updateAcademy, { loading: updatingAcademy, error: updateAcademyError }] = useMutation(updateAcademyMutation, { onCompleted });
  const onUpdateAcademy = useCallback(
    (input) => {
      updateAcademy({
        variables: {
          academyId,
          input,
        },
      });
    },
    [updateAcademy, academyId]
  );

  /**
   * Send Academy Payment Email
   */
  const [sendPaymentEmail, { loading: sendingPaymentEmail, error: sendPaymentEmailError }] = useMutation(sendPaymentEmailMutation, { onCompleted });
  const onSendPaymentEmail = useCallback(
    (input) => {
      sendPaymentEmail({
        variables: {
          academyId,
          input,
        },
      });
    },
    [sendPaymentEmail, academyId]
  );

  return {
    loading,
    error: getErrorMessage({ error }),
    academy,

    onCreateAcademy,
    creatingAcademy,
    createAcademyError: getErrorMessage({ error: createAcademyError }),

    onUpdateAcademy,
    updatingAcademy,
    updateAcademyError: getErrorMessage({ error: updateAcademyError }),

    onSendPaymentEmail,
    sendingPaymentEmail,
    sendPaymentEmailError: getErrorMessage({ error: sendPaymentEmailError }),
  };
}
