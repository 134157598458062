import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles(() => ({
  listItem: {
    height: 60,
  },
}));

const DrawerCourseMenu = (props) => {
  const classes = useStyles();
  const { course, menuItems, onClick } = props;

  if (!course?._id) return null;

  return (
    <List
      component="nav"
      subheader={<ListSubheader component="div">{course.name}</ListSubheader>}
    >
      {menuItems.map((menuItem, index) => (
        <ListItem
          key={index}
          className={classes.listItem}
          onClick={onClick}
          button
          component={Link}
          to={menuItem.to}
        >
          <ListItemIcon>
            <menuItem.icon />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage id={menuItem.title} />} />
        </ListItem>
      ))}
    </List>
  );
};

DrawerCourseMenu.propTypes = {
  course: PropTypes.object,
  menuItems: PropTypes.array,

  onClick: PropTypes.func,
};

DrawerCourseMenu.defaultProps = {
  course: {},
  menuItems: [],

  onClick: undefined,
};

export default DrawerCourseMenu;
