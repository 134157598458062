import React from 'react';

import Box from '@mui/material/Box';

interface GenericListProps<T> {
  renderItem: (index: number, item?: T) => React.ReactNode;
  items?: T[];
  skeletonsCount?: number;
  disableEmptyMessageContainer?: boolean;
  loading?: boolean;
  emptyMessage?: React.ReactNode;
}

const GenericList = <T extends unknown>(props: GenericListProps<T>) => {
  const { items, renderItem, skeletonsCount = 0, loading = false, emptyMessage, disableEmptyMessageContainer = false } = props;

  if (loading && skeletonsCount > 0) {
    const skeletonItems = Array.from(new Array(skeletonsCount).keys());
    return <>{skeletonItems.map((item) => renderItem(item, undefined))}</>;
  }
  if ((!items || !items.length) && !loading && emptyMessage) {
    if (disableEmptyMessageContainer) {
      return <>{emptyMessage}</>;
    }
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        {emptyMessage}
      </Box>
    );
  }

  return <>{items?.map((item, index) => renderItem(index, item))}</>;
};

export default GenericList;
