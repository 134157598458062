import React, { useMemo, useState } from 'react';
import { Box, Link } from '@mui/material';

import TSTable from '@ts-core/components/Table';
import { TSTableColumn } from '@ts-core/components/Table/TSTable/TSTable';

import { filterData } from '@ts-core/utils/dataTableUtils';
import { CourseActivityByMonth, ActivityType } from '../types';

export interface CourseActivityByMonthTableProps {
  courseActivity?: CourseActivityByMonth;
  loading?: boolean;

  getActivityRoute: (type: ActivityType, studentId: string) => string;
}

function ActivityLinkCell(props: {
  getActivityRoute: (type: ActivityType, studentId: string) => string;
  studentId: string;
  activityType: ActivityType;
  value: number;
}) {
  const { getActivityRoute, value, studentId, activityType } = props;
  if (value === 0) {
    return <Box>{value}</Box>;
  }
  return <Link href={getActivityRoute(activityType, studentId)}>{value}</Link>;
}

export function CourseActivityByMonthTable(props: CourseActivityByMonthTableProps) {
  const { getActivityRoute, loading = false, courseActivity } = props;
  const [query, setQuery] = useState<string>('');

  const columns = useMemo(
    (): TSTableColumn<CourseActivityByMonth['students'][0]>[] => [
      {
        Header: 'Alumno',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        sortable: true,
      },
      {
        Header: 'Visualizaciones Videos',
        accessor: 'mediaSessions',
        sortable: true,
        Footer: `${courseActivity?.mediaSessions}`,
        valueTransformer: (value, _, item) => (
          <ActivityLinkCell getActivityRoute={getActivityRoute} studentId={item._id} value={value} activityType="media" />
        ),
      },
      {
        Header: 'Entregas',
        accessor: 'assignmentAnswers',
        sortable: true,
        Footer: `${courseActivity?.assignmentAnswers}`,
        valueTransformer: (value, _, item) => (
          <ActivityLinkCell getActivityRoute={getActivityRoute} studentId={item._id} value={value} activityType="assignments" />
        ),
      },
      {
        Header: 'Prácticas',
        accessor: 'practiceSessions',
        sortable: true,
        Footer: `${courseActivity?.practiceSessions}`,
        valueTransformer: (value, _, item) => (
          <ActivityLinkCell getActivityRoute={getActivityRoute} studentId={item._id} value={value} activityType="practice" />
        ),
      },
      {
        Header: 'Tareas',
        accessor: 'activityAnswers',
        sortable: true,
        Footer: `${courseActivity?.activityAnswers}`,
        valueTransformer: (value, _, item) => (
          <ActivityLinkCell getActivityRoute={getActivityRoute} studentId={item._id} value={value} activityType="activities" />
        ),
      },
    ],
    [courseActivity, getActivityRoute]
  );

  const keys = ['name', 'email'];
  const filteredData: CourseActivityByMonth['students'] = filterData(courseActivity?.students, query, keys);

  return (
    <Box>
      <TSTable data={filteredData} loading={loading} columns={columns} onSearch={(text) => setQuery(text)} allowDownloadAsXLSX />
    </Box>
  );
}
