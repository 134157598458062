import { useCallback, useMemo } from 'react';
import { useQuery } from '@ts-core/lib/apollo';
import moment from 'moment';
import XLSX from 'xlsx';

import { getErrorMessage } from '@ts-core/lib/apollo';

export default function useCourseCurrentPeriodDetails({ query, variables, skip }) {
  const { data, loading, error } = useQuery(query, {
    variables,
    skip,
  });
  const currentPeriod = useMemo(() => data?.currentPeriod || {}, [data]);

  const onDownloadPeriodData = useCallback(() => {
    const { name, startDate, totalDebt, owner, students } = currentPeriod;
    const sheets = [];
    const summaryData = [['Materia', 'Dueño', 'Inicio del Período', 'Cantidad de Alumnos', 'Costo']];

    const dateFormat = 'DD/MM/YYYY';

    summaryData.push([name, owner.name, moment(startDate).format(dateFormat), students.length, `$${totalDebt}`]);
    sheets.push({
      name: 'Resumen',
      data: summaryData,
    });

    const studentsData = [['Nombre', 'Email', 'C.I', 'Activo desde', 'Activo hasta', 'Duración (Días)']];
    for (const student of students) {
      studentsData.push([
        student.name,
        student.email,
        student.personalId,
        moment(student.startDate).format(dateFormat),
        moment(student.endDate).format(dateFormat),
        student.duration,
      ]);
    }

    sheets.push({
      name: 'Alumnos',
      data: studentsData,
    });

    const workbook = XLSX.utils.book_new();

    for (const sheet of sheets) {
      const { data: sheetData, name: sheetName } = sheet;
      const workSheet = XLSX.utils.aoa_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(workbook, workSheet, sheetName);
    }
    XLSX.writeFile(workbook, `${name}.xlsx`);
  }, [currentPeriod]);

  return {
    currentPeriod,
    loading,
    error: getErrorMessage({ error }),

    onDownloadPeriodData,
  };
}
