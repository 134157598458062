import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import BackButton from '@ts-core/components/BackButton';

import { useAcademy } from 'hooks/academies';

import ProfessorForm from 'components/ProfessorForm';

import { useAcademyProfessor } from 'hooks/professors';
import { useRoles } from '@ts-core/hooks/role';

const NewProfessorPage = () => {
  const { academyId } = useParams();
  const history = useHistory();

  const { academy } = useAcademy({ academyId });
  const { onCreateProfessor, creatingProfessor, createProfessorError } = useAcademyProfessor({ academyId });
  const { roles } = useRoles();

  const handleGoBack = useCallback(() => {
    history.push(`/academies/${academyId}/details`);
  }, [history, academyId]);

  return (
    <>
      <BackButton onGoBack={handleGoBack} title="Agregar Profesor" subtitle={academy?.name} />
      <Box mt={4} maxWidth={800}>
        <ProfessorForm onSubmit={onCreateProfessor} submitting={creatingProfessor} error={createProfessorError} roles={roles} />
      </Box>
    </>
  );
};

export default NewProfessorPage;
