import { useCallback } from 'react';
import { useMutation } from '@ts-core/lib/apollo';
import { addItemToCache } from '@ts-core/core/utils/apolloUtils';
import { useSnackbarNotifications } from '@ts-core/core/hooks/ui';

import { CreatePaymentInput } from '../../types/payments';

import {
  getCoursePaymentsQuery,
  GetCoursePaymentsQueryResult,
  GetCoursePaymentsQueryVariables,
} from '../../graphql/payments/queries/getCoursePaymentsQuery';
import {
  getAcademyPaymentsQuery,
  GetAcademyPaymentsQueryResult,
  GetAcademyPaymentsQueryVariables,
} from '../../graphql/payments/queries/getAcademyPaymentsQuery';

import {
  createCoursePaymentMutation,
  CreateCoursePaymentMutationVariables,
  CreateCoursePaymentMutationResult,
} from '../../graphql/payments/mutations/createCoursePaymentMutation';
import { CreateAcademyPaymentMutationResult } from '../../graphql/payments/mutations/createAcademyPaymentMutation';

interface CreateCoursePaymentHookInput {
  academyId: string;
  courseId: string;
  year: string;
  month: string;
}

interface CreateCoursePaymentPayload {
  onCreateCoursePayment: (input: CreatePaymentInput, onCreated?: () => void) => void;
  createCoursePaymentLoading: boolean;
  createCoursePaymentError?: string;
}

export function useCreateCoursePayment(input: CreateCoursePaymentHookInput): CreateCoursePaymentPayload {
  const { academyId, courseId, year, month } = input;

  const { showErrorNotification } = useSnackbarNotifications();

  /**
   * Create Course Payment
   */
  const [createCoursePayment, { loading: createCoursePaymentLoading, errorMessage: createCoursePaymentError }] = useMutation<
    CreateCoursePaymentMutationResult,
    CreateCoursePaymentMutationVariables
  >(createCoursePaymentMutation, {
    onError: showErrorNotification,
    update: (cache, result) => {
      const addToCourse = addItemToCache<CreateCoursePaymentMutationResult, GetCoursePaymentsQueryResult, GetCoursePaymentsQueryVariables>({
        query: getCoursePaymentsQuery,
        queryName: 'payments',
        queryVariables: {
          academyId,
          courseId,
          year,
          month,
        },
        mutationName: 'payment',
      });
      addToCourse(cache, result);

      const addToAcademy = addItemToCache<CreateAcademyPaymentMutationResult, GetAcademyPaymentsQueryResult, GetAcademyPaymentsQueryVariables>({
        query: getAcademyPaymentsQuery,
        queryName: 'payments',
        queryVariables: {
          academyId,
          year,
          month,
        },
        mutationName: 'payment',
      });
      addToAcademy(cache, result);
    },
  });
  const onCreateCoursePayment = useCallback(
    (input: CreatePaymentInput, onCreated?: () => void) => {
      createCoursePayment({
        variables: {
          academyId,
          courseId,
          year,
          month,
          input,
        },
      }).then(onCreated);
    },
    [academyId, courseId, year, month, createCoursePayment]
  );

  return {
    onCreateCoursePayment,
    createCoursePaymentLoading,
    createCoursePaymentError,
  };
}
