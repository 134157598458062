import { useQuery } from '@ts-core/lib/apollo';

import { getErrorMessage } from '@ts-core/lib/apollo';

import { getAcademyCoursesQuery } from 'graphql/academies/queries';

export default function useAcademyCourses({ academyId } = {}) {
  const { data, loading, error } = useQuery(getAcademyCoursesQuery, {
    variables: { academyId },
    skip: !academyId,
  });
  const courses = data?.courses;

  return {
    courses,
    loading,
    error: getErrorMessage({ error }),
  };
}
