import moment from 'moment';

export const formatDate = (date, format) => {
  if (!date) return '-';
  return moment(date).format(format);
};

export const formatFullDate = (date) => {
  return formatDate(date, 'DD/MM/YYYY HH:mm');
};

export const formatDateOnly = (date) => {
  return formatDate(date, 'DD/MM/YYYY');
};
