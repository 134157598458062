import { gql } from '@apollo/client';

export const paymentFragment = gql`
  fragment Payment on Payment {
    id
    academy
    course
    year
    month
    amount
    currency
    description
    date
  }
`;
