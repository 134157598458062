import { useMemo } from 'react';

import SchoolIcon from '@mui/icons-material/School';
import DashboardIcon from '@mui/icons-material/Dashboard';

export default function useDrawerMenuItems() {
  const topMenuItems = useMemo(
    () => [
      {
        title: 'drawer.dashboard',
        to: '/dashboard',
        icon: DashboardIcon,
      },
      {
        title: 'drawer.academies',
        to: '/academies',
        icon: SchoolIcon,
      },
    ],
    []
  );

  return {
    topMenuItems,
  };
}
