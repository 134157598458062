import { gql } from '@apollo/client';

export default gql`
  query GetAcademyCourseCurrentPeriodDetails($academyId: String!, $courseId: String!) {
    currentPeriod(academyId: $academyId, courseId: $courseId)
      @rest(type: "BilledCourseCurrentPeriod", path: "academies/{args.academyId}/courses/{args.courseId}/current-period-details") {
      name
      owner {
        _id
        name
        email
      }
      startDate
      totalDebt
      students {
        name
        email
        personalId
        startDate
        endDate
        duration
        assignments
        videos
      }
    }
  }
`;
