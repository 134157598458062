import React, { useMemo } from 'react';

import TSTable, { DateTableCell } from '@ts-core/components/Table';
import { TSTableColumn } from '@ts-core/components/Table/TSTable/TSTable';

import { useStudentActivitiesByMonth } from '../hooks/useStudentActivitiesByMonth';
import { StudentActivitiesActivity } from '../types';

export interface StudentActivityAnswersByMonthProps {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export function StudentActivityAnswersByMonth(props: StudentActivityAnswersByMonthProps) {
  const { activities, loading } = useStudentActivitiesByMonth(props);

  const columns = useMemo(
    (): TSTableColumn<StudentActivitiesActivity>[] => [
      {
        Header: 'Tarea',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Fecha de Respuesta',
        accessor: 'answerDate',
        valueTransformer: (value) => <DateTableCell date={value} />,
      },
      {
        Header: 'Nota',
        accessor: 'score',
      },
      {
        Header: 'Archivo Entregado',
        accessor: 'file.name',
      },
    ],
    []
  );
  return <TSTable title="Tareas" data={activities} loading={loading} columns={columns} allowDownloadAsXLSX />;
}
