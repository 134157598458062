import { useMemo } from 'react';
import { useQuery } from '@ts-core/lib/apollo';

import { getErrorMessage } from '@ts-core/lib/apollo';

export default function useAcademyCourseInvoice({ query, variables, skip }) {
  const { data, loading, error } = useQuery(query, {
    variables,
    skip,
  });
  const invoice = useMemo(() => data?.invoice || {}, [data]);

  return {
    invoice,
    loading,
    error: getErrorMessage({ error }),
  };
}
