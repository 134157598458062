import { gql } from '@apollo/client';

import { UpdateProfileInput } from '../../types/Auth';

export interface UpdateProfileMutationVariables {
  input: UpdateProfileInput;
}

export interface UpdateProfileMutationResult {
  user: {
    _id: string;
    name: string;
    email: string;
  };
}

export const updateProfileMutation = gql`
  mutation UpdateProfileMutation($input: UpdateProfileMutationInput!) {
    user(input: $input) @rest(type: "User", path: "profile", method: "PUT") {
      _id
      name
      email
    }
  }
`;
