import { useQuery } from '@ts-core/lib/apollo';

import { getErrorMessage } from '@ts-core/lib/apollo';

import { getBilledCourseDetailsQuery } from 'graphql/courses/queries';

export default function useBilledCourse({ academyId, courseId }) {
  const { data, loading, error } = useQuery(getBilledCourseDetailsQuery, {
    variables: { academyId, courseId },
    skip: !academyId || !courseId,
  });
  const course = data?.course;

  return {
    course,
    loading,
    error: getErrorMessage({ error }),
  };
}
