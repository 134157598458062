import { gql } from '@apollo/client';

export default gql`
  fragment AcademyProfessor on AcademyProfessor {
    _id
    name
    email
    createdAt
    role {
      _id
    }
  }
`;
