export const Feature = {
  VideoOnDemand: 'feat:video-on-demand',
  Ranking: 'feat:assignments-students-ranking',
  Forum: 'feat:forum',
  ExerciseAudioVideoOnDemand: 'feat:exercise-audio-video-on-demand',
  ExternalVideos: 'feat:external-videos',
  ExternalDocuments: 'feat:external-documents',
  Activities: 'feat:activities',
  NewBilling: 'feat:new-billing',
};
