import { green, red, purple, orange } from '@mui/material/colors';

const baseStyles = (theme) => ({
  rootContainer: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    maxWidth: 1400,
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.spacing()} ${theme.spacing()}`,
    },
  },
  root: {
    width: '75%',
    margin: '0 auto',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: `${theme.spacing()} ${theme.spacing()}`,
    },
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  mainContainer: {
    marginLeft: 'auto',
    width: '80%',
    maxWidth: 'unset',
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1400,
    },
    [theme.breakpoints.down('xl')]: {
      width: '85%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '96%',
    },
  },
  grow: {
    flexGrow: 1,
  },
  desktopOnly: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  mobileOnly: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
  bottonPadding: {
    paddingBottom: 64,
  },
  dropArea: {
    minHeight: 84,
    borderStyle: 'dashed',
    borderWidth: 4,
    marginTop: theme.spacing(),
    padding: theme.spacing(2),
    backgroundColor: '#fbfbfb',
    color: '#b1b1b1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  dropAreaInactive: {
    borderColor: '#c1c1c1',
  },
  dropAreaActive: {
    borderColor: theme.palette.primary.main,
  },
  optionPositive: {
    color: green[500],
  },
  optionNegative: {
    color: red[500],
  },
  optionNeutral: {
    color: orange[500],
  },
  optionNone: {
    color: purple[500],
  },
  optionPositiveBackground: {
    backgroundColor: green[50],
  },
  optionNegativeBackground: {
    backgroundColor: red[50],
  },
  optionNeutralBackground: {
    backgroundColor: orange[50],
  },
  optionNoneBackground: {
    backgroundColor: purple[50],
  },
});

export default baseStyles;
