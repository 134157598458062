import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Address from 'components/Address';

import { useAcademyPricingModes } from 'hooks/academies';

import AcademyDetailsCard from 'components/AcademyDetailsCard';

const AcademyDetails = (props) => {
  const { academy, onEditAcademy, onOpenPayments } = props;

  const { name, clientId, pricing, contact } = academy;
  const { getPricingModeById } = useAcademyPricingModes();

  const menuItems = useMemo(() => {
    return [
      {
        title: 'Editar',
        handler: onEditAcademy,
      },
      {
        title: 'Pagos',
        handler: onOpenPayments,
      },
    ];
  }, [onEditAcademy, onOpenPayments]);

  return (
    <AcademyDetailsCard title={name} menuItems={menuItems}>
      <>
        {pricing && (
          <Box mb={1}>
            <Typography variant="subtitle2" gutterBottom>
              Precio
            </Typography>
            <Typography variant="body2" gutterBottom>
              {`${pricing.currency} ${pricing.amount} / ${getPricingModeById(pricing.mode)?.title}`}
            </Typography>
          </Box>
        )}
        {clientId && (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Número de cliente
            </Typography>
            <Typography variant="body2" gutterBottom>
              {clientId}
            </Typography>
          </Box>
        )}
        {contact && (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Contacto
            </Typography>
            <Typography variant="body2" gutterBottom>
              {contact.name}
            </Typography>
            {contact.email && (
              <Typography variant="body2" gutterBottom>
                {contact.email}
              </Typography>
            )}
            {contact.phoneNumber && (
              <Typography variant="body2" gutterBottom>
                {contact.phoneNumber}
              </Typography>
            )}
          </Box>
        )}
        {academy.address?.addressLine1 && (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Dirección
            </Typography>
            <Address address={academy.address} />
          </Box>
        )}
      </>
    </AcademyDetailsCard>
  );
};

AcademyDetails.propTypes = {
  academy: PropTypes.object,

  onEditAcademy: PropTypes.func,
  onOpenPayments: PropTypes.func,
};

AcademyDetails.defaultProps = {
  academy: {},

  onEditAcademy: undefined,
  onOpenPayments: undefined,
};

export default AcademyDetails;
