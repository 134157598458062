import React from 'react';

import Box from '@mui/material/Box';

import Typography from '@ts-core/components/Typography';

interface TableTitleProps {
  title?: React.ReactNode;
  disableTypography?: boolean;

  loading?: boolean;
}

const TableTitle = (props: TableTitleProps) => {
  const { title, disableTypography = false, loading = false } = props;

  if (!title) {
    return null;
  }

  if (disableTypography) {
    return <Box ml={-1}>{title}</Box>;
  }

  return (
    <Typography variant="h5" loading={loading}>
      {title}
    </Typography>
  );
};

export default TableTitle;
