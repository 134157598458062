import moment from 'moment';
import _ from 'lodash';

export const invoiceTitle = (invoice) => {
  if (_.isEmpty(invoice)) return '';

  const startDate = moment(invoice.coursePeriodDateInit);
  const endDate = invoice.coursePeriodDateEnd
    ? moment(invoice.coursePeriodDateEnd)
    : undefined;

  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate ? endDate.format('DD/MM/YYYY') : 'Hoy';

  return `${formattedStartDate} - ${formattedEndDate}`;
};
