import React, { useState } from 'react';

import { Box } from '@mui/material';

import GenericList from '@ts-core/core/components/GenericList';
import Button from '@ts-core/components/Button';
import Typography from '@ts-core/components/Typography';

import { Payment, CreatePaymentInput } from 'types/payments';

import { PaymentRow } from './PaymentRow';
import { PaymentForm } from './PaymentForm';

interface AcademyPaymentsTableProps {
  payments?: Payment[];
  loading?: boolean;

  onAddPayment: (input: CreatePaymentInput, onCreated: () => void) => void;
  isAddingPayment: boolean;

  onUpdate: (payment: Payment, input: CreatePaymentInput, onUpdated: () => void) => void;
  isUpdating: boolean;
  onDelete: (payment: Payment) => void;
}

export function AcademyPaymentsTable(props: AcademyPaymentsTableProps) {
  const { payments = [], loading, onUpdate, isUpdating, onAddPayment, isAddingPayment, onDelete } = props;
  const [addingPayment, setAddingPayment] = useState<boolean>(false);
  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2} py={1}>
        <Typography variant="h6">Pagos</Typography>
        <Button onClick={() => setAddingPayment(true)}>Agregar Pago</Button>
      </Box>
      {addingPayment && (
        <PaymentForm
          onCancel={() => {
            setAddingPayment(false);
          }}
          onCreate={onAddPayment}
          isSubmitting={isAddingPayment}
        />
      )}
      <GenericList
        items={payments}
        loading={loading}
        skeletonsCount={3}
        emptyMessage="No hay pagos"
        renderItem={(index, payment) => (
          <PaymentRow key={index} payment={payment} loading={loading} onUpdate={onUpdate} isUpdating={isUpdating} onDelete={onDelete} />
        )}
      />
    </Box>
  );
}
