import { gql } from '@apollo/client';

import { academyCourseFragment } from '../fragments';

export default gql`
  query GetCourses($academyId: String!) {
    courses(academyId: $academyId)
      @rest(type: "AcademyCourse", path: "academies/{args.academyId}/courses") {
      ...AcademyCourse
    }
  }
  ${academyCourseFragment}
`;
