import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table, { DateTableCell } from '@ts-core/components/Table';

const AcademyProfessorsTable = (props) => {
  const { professors, loading, error, onFetchProfessors } = props;

  const columns = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        sortable: true,
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        valueTransformer: (value) => <DateTableCell date={value} />,
      },
    ],
    []
  );

  return (
    <Table
      data={professors}
      columns={columns}
      loading={loading}
      errorMessage={error}
      onRefreshData={onFetchProfessors}
      elevation={0}
    />
  );
};

AcademyProfessorsTable.propTypes = {
  professors: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.string,

  onFetchProfessors: PropTypes.func,
};

AcademyProfessorsTable.defaultProps = {
  professors: [],
  loading: false,
  error: undefined,

  onFetchProfessors: undefined,
};

export default AcademyProfessorsTable;
