import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Box } from '@mui/material';

import Typography from '@ts-core/components/Typography';
import Button from '@ts-core/components/Button';
import { formatDateOnly } from '@ts-core/utils/dateFormatters';
import { priceFormatter } from '@ts-core/utils/priceFormatter';
import ConfirmButton from '@ts-core/components/ConfirmButton';

import { Payment } from 'types/payments';

import { PaymentRowContainer } from './PaymentRowContainer';

interface PaymentDetailsRowProps {
  payment?: Payment;
  loading?: boolean;

  onEdit: (payment: Payment) => void;
  onDelete: (payment: Payment) => void;
}

export function PaymentDetailsRow(props: PaymentDetailsRowProps) {
  const { payment, loading, onDelete, onEdit } = props;
  return (
    <PaymentRowContainer>
      <Box display="flex" flexDirection="row" gap={2} justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" flexDirection="column">
          <Typography loading={loading} skeletonProps={{ width: 120 }}>
            {payment?.course?.name ?? 'Academia'}
          </Typography>
          <Typography loading={loading} variant="caption" skeletonProps={{ width: 60 }}>
            {formatDateOnly(payment?.date)}
          </Typography>
        </Box>
        <Box>
          <Typography loading={loading} fontWeight={500} skeletonProps={{ width: 50 }}>
            {priceFormatter(payment?.currency, payment?.amount)}
          </Typography>
        </Box>
      </Box>
      {!isEmpty(payment?.description) && (
        <Box display="flex" flexDirection="column">
          <Typography variant="caption" fontWeight={700}>
            Referencia
          </Typography>
          <Typography variant="caption">{payment?.description}</Typography>
        </Box>
      )}
      {payment && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <ConfirmButton
            size="small"
            color="secondary"
            onConfirm={() => {
              onDelete(payment);
            }}
            alertMessage="¿Borrar pago?"
          >
            Borrar
          </ConfirmButton>
          <Button size="small" color="secondary" onClick={() => onEdit(payment)}>
            Editar
          </Button>
        </Box>
      )}
    </PaymentRowContainer>
  );
}
