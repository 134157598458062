import { useState, useCallback } from 'react';

export default function useTextField(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);

  const onChange = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  const onReset = useCallback((newValue = '') => {
    setValue(newValue);
  }, []);

  return {
    onChange,
    value,

    onReset,
  };
}
