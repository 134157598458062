import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import FormContainer from '@ts-core/components/FormContainer';
import Form, { FormTextField, FormSelect } from '@ts-core/core/components/FormV2';

import { useCountries } from '@ts-core/hooks/utils';

const EditAcademyFormSchema = Yup.object({
  name: Yup.string().required(),
  features: Yup.array(Yup.string()).required(),
  pricing: Yup.object({
    mode: Yup.string().required(),
    currency: Yup.string().required(),
    amount: Yup.number().required(),
  }),
  address: Yup.object({
    addressLine1: Yup.string().nullable(),
    postalCode: Yup.string().nullable(),
    city: Yup.string().nullable(),
    country: Yup.string().nullable(),
  }).nullable(),
  contact: Yup.object({
    name: Yup.string().nullable(),
    email: Yup.string().email().nullable(),
    phoneNumber: Yup.string().nullable(),
  }).nullable(),
});

const EditAcademyForm = (props) => {
  const { onSubmit, academy, loading, submitting, error, features, pricingModes, currencies } = props;

  const { countries } = useCountries();

  const { handleSubmit, formState, control } = useForm({
    resolver: yupResolver(EditAcademyFormSchema),
    defaultValues: academy,
  });

  return (
    <FormContainer title="Academia">
      <Form onSubmit={handleSubmit(onSubmit)} isSubmitting={submitting} submitError={error} loading={loading} formState={formState}>
        <Stack spacing={2}>
          <FormTextField control={control} name="name" rules={{ required: true }} fullWidth label="Nombre" />
          <FormSelect
            name="features"
            label="Features"
            control={control}
            items={features}
            itemTitleKey="title"
            itemValueKey="id"
            rules={{ required: true }}
            multiple
          />
          <Box display="flex" flexDirection="row">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormTextField control={control} name="pricing.amount" type="number" rules={{ required: true }} fullWidth label="Precio" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name="pricing.currency"
                  label="Moneda"
                  control={control}
                  items={currencies}
                  itemTitleKey="title"
                  itemValueKey="id"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name="pricing.mode"
                  label="Modo Cobro"
                  itemTitleKey="title"
                  itemValueKey="id"
                  control={control}
                  items={pricingModes}
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2" sx={{ my: 2 }}>
              Contacto
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormTextField control={control} name="contact.name" fullWidth label="Nombre" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField control={control} name="contact.email" fullWidth label="Email" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField control={control} name="contact.phoneNumber" fullWidth label="Teléfono" />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2">Dirección</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormTextField control={control} name="address.addressLine1" fullWidth label="Dirección" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField control={control} name="address.postalCode" fullWidth label="Código Postal" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField control={control} name="address.city" fullWidth label="Ciudad" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelect itemTitleKey="title" itemValueKey="id" name="address.country" label="País" control={control} items={countries} />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Form>
    </FormContainer>
  );
};

EditAcademyForm.propTypes = {
  onSubmit: PropTypes.func,

  academy: PropTypes.object,
  loading: PropTypes.bool,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  pricingModes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

EditAcademyForm.defaultProps = {
  onSubmit: undefined,

  academy: {},
  loading: false,
  features: [],
  pricingModes: [],
  currencies: [],
  submitting: false,
  error: undefined,
};

export default EditAcademyForm;
