import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { CourseActivityByMonth } from '../types';
import {
  getCourseActivityByMonthQuery,
  GetCourseActivityByMonthQueryResult,
  GetCourseActivityByMonthQueryVariables,
} from '../graphql/queries/getCourseActivityByMonthQuery';

export type CourseActivityByMonthHookResult = DataFetcherHookResult<CourseActivityByMonth, 'courseActivity'>;

export interface CourseActivityByMonthHookInput {
  academyId: string;
  courseId: string;
  year: string;
  month: string;
}

export function useCourseActivityByMonth(input: CourseActivityByMonthHookInput): CourseActivityByMonthHookResult {
  const { academyId, courseId, year, month } = input;
  const { data, loading, errorMessage } = useQuery<GetCourseActivityByMonthQueryResult, GetCourseActivityByMonthQueryVariables>(
    getCourseActivityByMonthQuery,
    { variables: { academyId, courseId, year, month } }
  );

  return { loading, error: errorMessage, courseActivity: data?.courseActivity };
}
