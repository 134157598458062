import React from 'react';
import find from 'lodash/find';

import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';

interface TableHeadCellProps {
  state: any;
  instance: any;
  column: any;
}

const TableHeadCell = (props: TableHeadCellProps) => {
  const { state, instance, column } = props;
  const { sorted } = state;
  const sort = column.sortable ? find(sorted, { id: column.id }) : null;
  const isSorted = column.sortable && Boolean(sort);

  return (
    <TableCell padding="normal" variant="head">
      {column.sortable ? (
        <TableSortLabel
          active={isSorted}
          direction={sort && sort.desc ? 'desc' : 'asc'}
          onClick={(event) => instance.sortColumn(column, event.shiftKey)}
        >
          {column.Header}
        </TableSortLabel>
      ) : (
        column.Header
      )}
    </TableCell>
  );
};

export default TableHeadCell;
