import React, { useCallback, useMemo, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';

import { SxProps } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import CancelIcon from '@mui/icons-material/Cancel';

import ImageUploader from '@ts-core/core/components/ImageUploader';
import { useFileUploader } from '@ts-core/core/hooks/utils';

import { FormField } from '../types';

export type FormImageUploaderProps<TField extends FieldValues> = {
  sx?: SxProps;
  imageType: string;
  signUrlQueryParams?: Record<string, string>;
  imageUrl?: string;
} & FormField<TField>;

const FormImageUploader = <TField extends FieldValues>(props: FormImageUploaderProps<TField>) => {
  const { sx, imageType, signUrlQueryParams = {}, name, control, defaultFormValue, rules, shouldUnregister, imageUrl } = props;
  const { onUploadFile, uploadProgress, uploading, error: uploadError } = useFileUploader();
  const [uploadedImageUrl, setUploadedImageUrl] = useState(imageUrl);

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: defaultFormValue,
    shouldUnregister,
    rules: {
      required: rules?.required,
    },
  });
  const { error } = fieldState;
  const { onChange } = field;

  const signUrlEndpoint = useMemo(() => {
    return `aws/signed-url/${imageType}`;
  }, [imageType]);

  const handleFileSelected = useCallback(
    (file: File) => {
      onUploadFile(file, signUrlEndpoint, signUrlQueryParams, {
        onSuccess: (data) => {
          onChange(data.path);
          setUploadedImageUrl(data.url);
        },
      });
    },
    [onUploadFile, signUrlEndpoint, signUrlQueryParams, onChange]
  );

  const handleRemoveFile = useCallback(() => {
    onChange(undefined);
    setUploadedImageUrl(undefined);
  }, [onChange]);

  return (
    <ImageUploader
      sx={sx}
      onFileSelected={handleFileSelected}
      uploading={uploading}
      progress={uploadProgress}
      uploadError={uploadError || error?.message}
      acceptedFiles={['image/*']}
    >
      {uploadedImageUrl && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Box position="relative">
            <IconButton color="error" sx={{ position: 'absolute', right: -16, top: -16, zIndex: 999 }} onClick={handleRemoveFile}>
              <CancelIcon />
            </IconButton>
            <Box component="img" src={uploadedImageUrl} alt={uploadedImageUrl} maxHeight={300} border={1} borderColor="grey.400" />
          </Box>
        </Box>
      )}
    </ImageUploader>
  );
};

export default FormImageUploader;
