import { useState, useCallback } from 'react';

export default function useDialog({
  loading,
  noPadding,
  toolbarColor,
  actions,
} = {}) {
  const [data, setData] = useState({ open: false });

  const handleOpen = useCallback(({ dialogData = {}, title } = {}) => {
    setData({ dialogData, title, open: true });
  }, []);

  const handleClose = useCallback(() => {
    setData((d) => ({ ...d, open: false }));
  }, []);

  return {
    dialogProps: {
      onClose: handleClose,
      ...data,
      loading,
      noPadding,
      toolbarColor,
      actions,
    },
    onOpenDialog: handleOpen,
    onCloseDialog: handleClose,
  };
}
