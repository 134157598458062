import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { StudentMediaSessionActivity } from '../types';
import {
  getStudentMediaSessionsByMonthQuery,
  GetStudentMediaSessionsByMonthQueryResult,
  GetStudentMediaSessionsByMonthQueryVariables,
} from '../graphql/queries/getStudentMediaSessionsByMonthQuery';

export type StudentMediaSessionsByMonthHookResult = DataFetcherHookResult<StudentMediaSessionActivity[], 'mediaSessions'>;

export interface StudentMediaSessionsByMonthHookInput {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export function useStudentMediaSessionsByMonth(input: StudentMediaSessionsByMonthHookInput): StudentMediaSessionsByMonthHookResult {
  const { academyId, courseId, studentId, year, month } = input;
  const { data, loading, errorMessage } = useQuery<GetStudentMediaSessionsByMonthQueryResult, GetStudentMediaSessionsByMonthQueryVariables>(
    getStudentMediaSessionsByMonthQuery,
    { variables: { academyId, courseId, studentId, year, month } }
  );

  return { loading, error: errorMessage, mediaSessions: data?.mediaSessions };
}
