import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles(() => ({
  listItem: {
    height: 60,
  },
}));

const DrawerGeneralMenu = (props) => {
  const classes = useStyles();
  const { menuItems, onClick } = props;

  return (
    <List>
      {menuItems.map((menuItem, index) => (
        <ListItem
          button
          onClick={onClick}
          component={Link}
          to={menuItem.to}
          key={index}
          className={classes.listItem}
        >
          <ListItemIcon>
            <menuItem.icon />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage id={menuItem.title} />} />
        </ListItem>
      ))}
    </List>
  );
};

DrawerGeneralMenu.propTypes = {
  menuItems: PropTypes.array,

  onClick: PropTypes.func,
};

DrawerGeneralMenu.defaultProps = {
  menuItems: [],

  onClick: undefined,
};

export default DrawerGeneralMenu;
