import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import TSTable from '@ts-core/components/Table/TSTable';

function periodTitle(period) {
  const startDate = moment(period.startDate);
  const formattedStartDate = startDate.format('DD/MM/YYYY');

  return formattedStartDate;
}

const CoursePeriodDetails = (props) => {
  const { coursePeriodDetailsData, isLoading, intl, onDownloadPeriodReport } = props;

  console.log(coursePeriodDetailsData);

  const columns = useMemo(() => {
    return [
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentName',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentName',
        }),
        accessor: 'name',
        sortable: true,
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentEmail',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentEmail',
        }),
        accessor: 'email',
        sortable: true,
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentPersonalId',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentPersonalId',
        }),
        accessor: 'personalId',
        sortable: true,
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentActivationDate',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentActivationDate',
        }),
        accessor: 'startDate',
        sortable: true,
        valueTransformer: (value) => moment(value).format('DD/MM/YYYY'),
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentDeactivationDate',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentDeactivationDate',
        }),
        accessor: 'endDate',
        sortable: true,
        valueTransformer: (value) => moment(value).format('DD/MM/YYYY'),
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.duration',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.duration',
        }),
        accessor: 'duration',
        sortable: true,
      },
      {
        Header: 'Entregas',
        value: 'Entregas',
        accessor: 'assignments',
        sortable: true,
      },
      {
        Header: 'Videos',
        value: 'Videos',
        accessor: 'videos',
        sortable: true,
      },
    ];
  }, [intl]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        <FormattedMessage id="billing.invoiceDetails.periodTitle" values={{ period: periodTitle(coursePeriodDetailsData) }} />
      </Typography>
      <Box mb={3} mt={3}>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="billing.invoiceDetails.total" values={{ cost: coursePeriodDetailsData.totalDebt || 0 }} />
        </Typography>
        <Button onClick={onDownloadPeriodReport} variant="contained" color="primary">
          <FormattedMessage id="periodDetails.downloadFile" defaultMessage="Descargar XLSX" />
        </Button>
      </Box>
      <TSTable loading={isLoading} data={coursePeriodDetailsData.students || []} columns={columns} xlsxFileName={`${coursePeriodDetailsData.name}`} />
    </Box>
  );
};

CoursePeriodDetails.propTypes = {
  coursePeriodDetailsData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  onDownloadPeriodReport: PropTypes.func,
};

CoursePeriodDetails.defaultProps = {
  onDownloadPeriodReport() {},
};

export default injectIntl(CoursePeriodDetails);
