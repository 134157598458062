import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';

import styles from './paperWithLoaderStyles';

const PaperWithLoader = (props) => {
  const { loading, children, classes, elevation, ...others } = props;

  return (
    <Paper className={classes.paper} {...others} elevation={elevation}>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '400ms' : '0ms',
        }}
      >
        <LinearProgress className={classes.loader} />
      </Fade>
      {children}
    </Paper>
  );
};

PaperWithLoader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  elevation: PropTypes.number,
};

PaperWithLoader.defaultProps = {
  loading: false,
  elevation: 1,
};

export default withStyles(styles)(PaperWithLoader);
