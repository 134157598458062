import React, { useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import BackButton from '@ts-core/components/BackButton';

import { useCourseActivityByMonth } from '@ts-core/modules/billing/hooks/useCourseActivityByMonth';
import { CourseActivityByMonthTable } from '@ts-core/modules/billing/components/CourseActivityByMonthTable';
import { YearMonthSelector, YearMonthDate } from '@ts-core/modules/billing/components/YearMonthSelector';
import { ActivityType } from '@ts-core/modules/billing/types';

import { AcademyPaymentsTable } from 'components/activities/AcademyPaymentsTable';

import { CreatePaymentInput, Payment } from 'types/payments';

import { useCourse } from 'hooks/courses/useCourse';
import useAcademy from 'hooks/academies/useAcademy';
import { useCoursePayments } from 'hooks/payments/useCoursePayments';
import { useUpdatePayment } from 'hooks/payments/useUpdatePayment';
import { useDeletePayment } from 'hooks/payments/useDeletePayment';
import { useCreateCoursePayment } from 'hooks/payments/useCreateCoursePayment';

export default function CourseActivityByMonthPage() {
  const history = useHistory();
  const { academyId, courseId, month: urlMonth, year: urlYear } = useParams<{ academyId: string; courseId: string; year: string; month: string }>();
  const [date, setDate] = useState<YearMonthDate>({ year: urlYear, month: urlMonth });
  const { year, month } = date;

  const { courseActivity, loading } = useCourseActivityByMonth({ academyId, courseId, month, year });
  const { course } = useCourse({ courseId });
  const { academy } = useAcademy({ academyId });
  const { payments, loading: loadingPayments } = useCoursePayments({ academyId, courseId, year, month });
  const { onUpdatePayment, updatePaymentLoading } = useUpdatePayment();
  const { onCreateCoursePayment, createCoursePaymentLoading } = useCreateCoursePayment({ academyId, courseId, year, month });
  const { onDeletePayment } = useDeletePayment();

  const handleGoBack = useCallback(() => {
    history.push(`/dashboard/${year}/${month}/academies/${academyId}`);
  }, [history, academyId, year, month]);

  const getActivityRoute = useCallback(
    (type: ActivityType, studentId: string) => {
      return `/dashboard/${year}/${month}/academies/${academyId}/${courseId}/${studentId}/${type}`;
    },
    [academyId, courseId, month, year]
  );

  const handleAddPayment = useCallback(
    (input: CreatePaymentInput, onCreated: () => void) => {
      onCreateCoursePayment(input, onCreated);
    },
    [onCreateCoursePayment]
  );
  const handleUpdatePayment = useCallback(
    (payment: Payment, input: CreatePaymentInput, onUpdated: () => void) => {
      onUpdatePayment(payment.id, input, onUpdated);
    },
    [onUpdatePayment]
  );
  const handleDeletePayment = useCallback(
    (payment: Payment) => {
      onDeletePayment(payment);
    },
    [onDeletePayment]
  );

  return (
    <Box>
      <BackButton noMargin onGoBack={handleGoBack} title={course?.name} subtitle={academy?.name} />
      <YearMonthSelector sx={{ mt: 3, mb: 2 }} onChangeDate={setDate} date={date} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <AcademyPaymentsTable
            payments={payments}
            loading={loadingPayments}
            onAddPayment={handleAddPayment}
            isAddingPayment={createCoursePaymentLoading}
            onUpdate={handleUpdatePayment}
            isUpdating={updatePaymentLoading}
            onDelete={handleDeletePayment}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <CourseActivityByMonthTable courseActivity={courseActivity} loading={loading} getActivityRoute={getActivityRoute} />
        </Grid>
      </Grid>
    </Box>
  );
}
