import baseStyles from '@ts-core/styles/baseStyles';

export default (theme) => ({
  ...baseStyles(theme),
  paper: {
    padding: theme.spacing(2),
    overflow: 'hidden',
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
});
