/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { ApolloLink } from "@apollo/client";

const omitTypename = (key: any, value: any) => (key === "__typename" ? undefined : value);

export const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation);
});
