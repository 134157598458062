import React from 'react';
import { useIntl } from 'react-intl';
import find from 'lodash/find';
import get from 'lodash/get';
import _ from 'lodash';

import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Typography from '@ts-core/components/Typography';

interface FiltersPopoverProps {
  extraFilters?: any[];
  state: any;
  instance: any;
  isOpen?: boolean;
  anchorEl?: HTMLAnchorElement;

  onClose: () => void;
}

const FiltersPopover = (props: FiltersPopoverProps) => {
  const intl = useIntl();

  const { extraFilters = [], state, isOpen = false, anchorEl, onClose, instance } = props;
  const { data } = state;

  const filterableColumns = state.columns.filter((c: any) => c.filterable);

  return (
    <Popover
      id="filters"
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={(theme) => ({
        '& .MuiPopover-paper': {
          padding: theme.spacing(2),
          minWidth: 320,
          [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
          },
        },
      })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Typography gutterBottom variant="h6" sx={{ mb: 2 }}>
        {intl.formatMessage({ id: 'filters' })}
      </Typography>

      <Stack spacing={2}>
        {filterableColumns.map((column: any, index: number) => {
          const differentValues = _(data)
            .uniqBy(column.id)
            .map((d) => {
              const value = get(d, column.id);
              if (value !== undefined && value !== null) {
                const displayValue = column.valueTransformer ? column.valueTransformer(value, column) : value;
                return { value, displayValue };
              }
              return null;
            })
            .filter((d) => Boolean(d))
            .value()
            .sort((a: any, b: any) => a?.toString().localeCompare(b?.toString(), 'es', { numeric: true, ignorePunctuation: true }));

          const filter = find(state.filtered, { id: column.id });
          return (
            <FormControl variant="outlined" key={index} fullWidth sx={{ mb: 2 }}>
              <InputLabel id={`${column.id}-label`}>{column.Header}</InputLabel>
              <Select
                size="small"
                label={column.Header}
                id={column.id}
                labelId={`${column.id}-label`}
                value={filter && filter.value !== undefined ? filter.value : 'all'}
                fullWidth
                onChange={(event) => {
                  instance.filterColumn(column, event.target.value !== 'all' ? event.target.value : '');
                }}
              >
                <MenuItem value="all">{intl.formatMessage({ id: 'all' })}</MenuItem>
                {differentValues.map((value: any, valueIndex) => (
                  <MenuItem key={valueIndex} value={value.value}>
                    {value.displayValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        })}
        {extraFilters.map((f) => f.component)}
      </Stack>
    </Popover>
  );
};

export default FiltersPopover;
