import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import { useActivityOverview } from 'hooks/activity/useActivityOverview';
import { ActivityOverviewTable } from 'components/activities/ActivityOverviewTable';
import { ActivityOverviewTiles } from 'components/activities/ActivityOverviewTiles';
import { YearMonthSelector, YearMonthDate } from '@ts-core/modules/billing/components/YearMonthSelector';

const thisYear = new Date().getFullYear().toString();
const thisMonth = (new Date().getMonth() + 1).toString();

export default function DashboardPage() {
  const { month: urlMonth, year: urlYear } = useParams<{ year?: string; month?: string }>();
  const [date, setDate] = useState<YearMonthDate>({ year: urlYear ?? thisYear, month: urlMonth ?? thisMonth });
  const { activityOverview, loading } = useActivityOverview({ dates: date });
  const history = useHistory();

  const handleGoToAcademy = useCallback(
    (academyId) => {
      history.push(`/dashboard/${date.year}/${date.month}/academies/${academyId}`);
    },
    [history, date]
  );

  useEffect(() => {
    const { year, month } = date;
    history.replace(`/dashboard/${year}/${month}`);
  }, [date, history]);

  return (
    <Stack direction="column" spacing={1}>
      <YearMonthSelector onChangeDate={setDate} date={date} />
      <ActivityOverviewTiles activityOverview={activityOverview} loading={loading} />
      <ActivityOverviewTable activityOverview={activityOverview} loading={loading} onGoToAcademy={handleGoToAcademy} />
    </Stack>
  );
}
