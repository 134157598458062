import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { invoiceTitle } from './utils/invoiceUtils';

import TSTable from '@ts-core/components/Table/TSTable/TSTable';

const InvoiceDetails = (props) => {
  const { invoice, course, loadingInvoice, onToggleInvoiceStatus } = props;

  const intl = useIntl();

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentName',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentName',
        }),
        accessor: 'student.name',
        sortable: true,
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentEmail',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentEmail',
        }),
        accessor: 'student.email',
        sortable: true,
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentPersonalId',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentPersonalId',
        }),
        accessor: 'student.personalId',
        sortable: true,
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentActivationDate',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentActivationDate',
        }),
        accessor: 'startDate',
        sortable: true,
        valueTransformer: (value) => moment(value).format('DD/MM/YYYY'),
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentDeactivationDate',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.studentDeactivationDate',
        }),
        accessor: 'endDate',
        sortable: true,
        valueTransformer: (value) => moment(value).format('DD/MM/YYYY'),
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.duration',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.duration',
        }),
        accessor: 'duration',
        sortable: true,
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.assignmentsCount',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.assignmentsCount',
        }),
        accessor: '_id',
        valueTransformer: (id, column, item) => {
          if (!item || !item.assignmentAnswers || !item.assignments) return '-';
          return `${item.assignmentAnswers.length} / ${item.assignments.length}`;
        },
      },
      {
        Header: intl.formatMessage({
          id: 'courseInvoiceDetails.table.practiceSessionsCount',
        }),
        value: intl.formatMessage({
          id: 'courseInvoiceDetails.table.practiceSessionsCount',
        }),
        accessor: 'practiceSessions',
        valueTransformer: (value) => value.length,
      },
    ],
    [intl]
  );

  const handleToggleInvoiceStatus = useCallback(() => {
    onToggleInvoiceStatus(invoice);
  }, [onToggleInvoiceStatus, invoice]);

  function getDataToExport(sortedData) {
    const headers = [
      intl.formatMessage({
        id: 'courseInvoiceDetails.table.studentName',
      }),
      intl.formatMessage({
        id: 'courseInvoiceDetails.table.studentEmail',
      }),
      intl.formatMessage({
        id: 'courseInvoiceDetails.table.studentPersonalId',
      }),
      intl.formatMessage({
        id: 'courseInvoiceDetails.table.studentActivationDate',
      }),
      intl.formatMessage({
        id: 'courseInvoiceDetails.table.studentDeactivationDate',
      }),
      intl.formatMessage({
        id: 'courseInvoiceDetails.table.duration',
      }),
      intl.formatMessage({
        id: 'courseInvoiceDetails.table.assignmentsCount',
      }),
      intl.formatMessage({
        id: 'courseInvoiceDetails.table.practiceSessionsCount',
      }),
    ];

    const xlsData = [headers];
    for (const data of sortedData) {
      const { _original: line } = data;
      const lineData = [
        line.student.name,
        line.student.email,
        line.student.personalId,
        moment(data.startDate).format('DD/MM/YYYY'),
        moment(data.endDate).format('DD/MM/YYYY'),
        line.duration,
        `${line.assignmentAnswers.length} / ${line.assignments.length}`,
        line.practiceSessions.length,
      ];
      xlsData.push(lineData);
    }
    return xlsData;
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        <FormattedMessage id="billing.invoiceDetails.periodTitle" values={{ period: invoiceTitle(invoice) }} />
      </Typography>
      <Box mt={3} mb={3}>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="billing.invoiceDetails.total" values={{ cost: invoice.totalDebt || 0 }} />
        </Typography>
        {onToggleInvoiceStatus && !loadingInvoice && (
          <Button variant="contained" color="primary" onClick={handleToggleInvoiceStatus}>
            <FormattedMessage id={invoice.paid ? 'courseInvoiceDetails.markAsUnpaid' : 'courseInvoiceDetails.markAsPaid'} />
          </Button>
        )}
      </Box>
      <TSTable
        data={invoice.lines || []}
        columns={columns}
        loading={false}
        xlsxFileName={`${course.name} - ${invoiceTitle(invoice)}`}
        allowDownloadAsXLSX
        getDataToExport={getDataToExport}
      />
    </Box>
  );
};

InvoiceDetails.propTypes = {
  course: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  loadingInvoice: PropTypes.bool,

  onToggleInvoiceStatus: PropTypes.func,
};

InvoiceDetails.defaultProps = {
  onToggleInvoiceStatus: undefined,
  loadingInvoice: false,
};

export default InvoiceDetails;
