import { useCallback } from 'react';
import { useMutation } from '@ts-core/lib/apollo';
import { useIntl } from 'react-intl';

import { getErrorMessage } from '@ts-core/lib/apollo';
import { useSnackbarNotifications } from '@ts-core/core/hooks/ui';

import { requestResetPasswordMutation, RequestResetPasswordMutationVariables } from '../graphql/mutations/requestResetPasswordMutation';
import { setNewPasswordMutation, SetNewPasswordMutationVariables } from '../graphql/mutations/setNewPasswordMutation';
import { changePasswordMutation, ChangePasswordMutationVariables } from '../graphql/mutations/changePasswordMutation';

import { AskResetPasswordInput, ChangePasswordInput, SetNewPasswordInput } from '../types/Auth';

export interface PasswordActionsHookResult {
  onRequestResetPassword: (input: AskResetPasswordInput, onSuccess?: () => void) => void;
  requestResetPasswordLoading: boolean;
  requestResetPasswordError?: string;

  onChangePassword: (input: ChangePasswordInput, onSuccess?: () => void) => void;
  changePasswordLoading: boolean;
  changePasswordError?: string;

  onSetNewPassword: (token: string, input: SetNewPasswordInput, onSuccess?: () => void) => void;
  setNewPasswordLoading: boolean;
  setNewPasswordError?: string;
}

export default function usePasswordActions(): PasswordActionsHookResult {
  const intl = useIntl();
  const { showSuccessNotification } = useSnackbarNotifications();

  /**
   * Request reset password
   */

  const [requestResetPassword, { loading: requestResetPasswordLoading, error: requestResetPasswordError }] = useMutation<
    any,
    RequestResetPasswordMutationVariables
  >(requestResetPasswordMutation);
  const onRequestResetPassword = useCallback(
    (input: AskResetPasswordInput, onSuccess?: () => void) => {
      requestResetPassword({ variables: { input } }).then(onSuccess);
    },
    [requestResetPassword]
  );

  /**
   * Set new password
   */
  const [setNewPassword, { loading: setNewPasswordLoading, error: setNewPasswordError }] = useMutation<any, SetNewPasswordMutationVariables>(
    setNewPasswordMutation,
    { onCompleted: () => showSuccessNotification(intl.formatMessage({ id: 'setNewPassword.successMessage' })) }
  );
  const onSetNewPassword = useCallback(
    (token: string, input: SetNewPasswordInput, onSuccess?: () => void) => {
      setNewPassword({ variables: { input: { ...input, token } } }).then(onSuccess);
    },
    [setNewPassword]
  );

  /**
   * Change password
   */
  const [changePassword, { loading: changePasswordLoading, error: changePasswordError }] = useMutation<any, ChangePasswordMutationVariables>(
    changePasswordMutation,
    {
      onCompleted: () => {
        showSuccessNotification(intl.formatMessage({ id: 'profile.passwordChange.successMessage' }));
      },
    }
  );
  const onChangePassword = useCallback(
    (input: ChangePasswordInput, onSuccess?: () => void) => {
      changePassword({ variables: { input } }).then(onSuccess);
    },
    [changePassword]
  );

  return {
    onRequestResetPassword,
    requestResetPasswordLoading,
    requestResetPasswordError: getErrorMessage({
      error: requestResetPasswordError,
    }),

    onChangePassword,
    changePasswordLoading,
    changePasswordError: getErrorMessage({ error: changePasswordError }),

    onSetNewPassword,
    setNewPasswordLoading,
    setNewPasswordError: getErrorMessage({ error: setNewPasswordError }),
  };
}
