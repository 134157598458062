import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { invoiceTitle } from './utils/invoiceUtils';

import PeriodsList from './PeriodsList';

import { red, green } from '@mui/material/colors';

const InvoiceList = (props) => {
  const { invoices, selectedInvoice, loading, onSelectInvoice } = props;

  const items = invoices.map((invoice) => ({
    _id: invoice._id,
    title: invoiceTitle(invoice),
    subtitle: (
      <Typography
        style={{ color: invoice.paid ? green[500] : red[500] }}
        variant="subtitle2"
      >
        {invoice.paid ? 'Pago' : 'No Pago'}
      </Typography>
    ),
  }));

  return (
    <PeriodsList
      loading={loading}
      title="Períodos Cerrados"
      items={items}
      onSelectItem={onSelectInvoice}
      selectedItemId={selectedInvoice ? selectedInvoice._id : undefined}
    />
  );
};

InvoiceList.propTypes = {
  invoices: PropTypes.array.isRequired,
  selectedInvoice: PropTypes.object,
  loading: PropTypes.bool,

  onSelectInvoice: PropTypes.func.isRequired,
};

InvoiceList.defaultProps = {
  loading: false,
  selectedInvoice: undefined,
};

export default InvoiceList;
