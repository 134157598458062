import { gql } from '@apollo/client';

import { academyProfessorFragment } from '../fragments';

export default gql`
  mutation CreateProfessor($academyId: String!, $input: CreateProfessorInput!) {
    professor(academyId: $academyId, input: $input)
      @rest(
        type: "AcademyProfessor"
        path: "academies/{args.academyId}/professors"
        method: "POST"
      ) {
      ...AcademyProfessor
    }
  }
  ${academyProfessorFragment}
`;
