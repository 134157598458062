import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';

import TSTable from '@ts-core/components/Table';
import { TSTableColumn } from '@ts-core/components/Table/TSTable/TSTable';

import { filterData } from '@ts-core/utils/dataTableUtils';
import { ActivityOverview } from 'types/activity';
import { priceFormatter } from '@ts-core/utils/priceFormatter';

interface ActivityOverviewTableProps {
  activityOverview?: ActivityOverview;
  loading?: boolean;

  onGoToAcademy: (academyId: string) => void;
}

export function ActivityOverviewTable(props: ActivityOverviewTableProps) {
  const { onGoToAcademy, loading = false, activityOverview } = props;
  const [query, setQuery] = useState<string>('');

  const columns = useMemo(
    (): TSTableColumn<ActivityOverview['academies'][0]>[] => [
      {
        Header: 'Academia',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Cursos',
        accessor: 'coursesCount',
        sortable: true,
      },
      {
        Header: 'Alumnos Únicos',
        accessor: 'uniqueStudentsCount',
        sortable: true,
      },
      {
        Header: 'Alumnos',
        accessor: 'totalStudentsInCoursesCount',
        sortable: true,
      },
      {
        Header: 'Visualizaciones de Video',
        accessor: 'mediaSessionsCount',
        sortable: true,
      },
      {
        Header: 'Entregas',
        accessor: 'assignmentAnswersCount',
        sortable: true,
      },
      {
        Header: 'Prácticas',
        accessor: 'practiceSessionsCount',
        sortable: true,
      },
      {
        Header: 'Tareas',
        accessor: 'activityAnswersCount',
        sortable: true,
      },
      {
        Header: 'Costo',
        accessor: 'cost',
        valueTransformer: (value, _, item) => {
          return priceFormatter(item.pricing.currency, value);
        },
        sortable: true,
      },
    ],
    []
  );

  const keys = ['name'];
  const filteredData: ActivityOverview['academies'] = filterData(activityOverview?.academies, query, keys);

  return (
    <Box height="100%">
      <TSTable
        disableTypography
        data={filteredData}
        loading={loading}
        columns={columns}
        onSearch={(text) => setQuery(text)}
        allowDownloadAsXLSX
        onSelectRow={(item) => onGoToAcademy(item._id)}
      />
    </Box>
  );
}
