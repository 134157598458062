import { gql } from '@apollo/client';

export const signOutMutation = gql`
  mutation SignOutMutation {
    signOut
      @rest(type: "SignOutPayload", path: "auth/signout", method: "DELETE") {
      NoResponse
    }
  }
`;
