import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import PeriodsList from './PeriodsList';
import InvoicesList from './InvoicesList';
import InvoiceDetails from './InvoiceDetails';

import { invoiceTitle } from './utils/invoiceUtils';

import CoursePeriodDetails from '@ts-core/components/CoursePeriodDetails';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(2),
  },
}));

const PaymentCourseDetails = (props) => {
  const {
    onToggleInvoiceStatus,
    onSelectInvoice,
    course,
    selectedInvoice,
    selectedInvoiceId,
    onSelectCurrentPeriod,
    currentPeriod,
    onDownloadPeriodReport,
    loadingInvoices,
    loadingInvoice,
  } = props;
  const classes = useStyles();
  const { invoices = [] } = course;

  const isOpen = course.periodStatus === 'opened';

  function renderDetails() {
    if (selectedInvoiceId) {
      return (
        <InvoiceDetails
          course={course}
          invoice={selectedInvoice}
          loadingInvoice={loadingInvoice}
          onToggleInvoiceStatus={onToggleInvoiceStatus}
        />
      );
    }
    if (isOpen) {
      return (
        <CoursePeriodDetails
          coursePeriodDetailsData={currentPeriod}
          isLoading={false}
          onDownloadPeriodReport={onDownloadPeriodReport}
        />
      );
    }
    return null;
  }

  return (
    <Box>
      <Grid container className={classes.grid} spacing={4}>
        <Grid item xs={3}>
          <Box boxShadow={1} py={2} px={2}>
            {isOpen && (
              <Box py={2}>
                <PeriodsList
                  title="Período actual"
                  onSelectItem={onSelectCurrentPeriod}
                  selectedItemId={selectedInvoiceId || 'current'}
                  items={[
                    {
                      _id: 'current',
                      title: invoiceTitle({
                        coursePeriodDateInit: currentPeriod.startDate,
                      }),
                    },
                  ]}
                />
              </Box>
            )}
            <InvoicesList
              loading={loadingInvoices}
              invoices={invoices}
              onSelectInvoice={onSelectInvoice}
              selectedInvoice={selectedInvoice}
            />
          </Box>
        </Grid>
        <Grid item xs={9}>
          {renderDetails()}
        </Grid>
      </Grid>
    </Box>
  );
};

PaymentCourseDetails.propTypes = {
  course: PropTypes.object,
  selectedInvoiceId: PropTypes.string,
  selectedInvoice: PropTypes.object,
  loadingInvoice: PropTypes.bool,
  currentPeriod: PropTypes.object.isRequired,
  loadingInvoices: PropTypes.bool,

  onSelectInvoice: PropTypes.func.isRequired,
  onToggleInvoiceStatus: PropTypes.func,
  onSelectCurrentPeriod: PropTypes.func.isRequired,
  onDownloadPeriodReport: PropTypes.func.isRequired,
};

PaymentCourseDetails.defaultProps = {
  course: {},
  onToggleInvoiceStatus: undefined,
  selectedInvoiceId: undefined,
  loadingInvoices: false,
  loadingInvoice: false,
  selectedInvoice: {},
};

export default PaymentCourseDetails;
