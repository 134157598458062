import { gql } from '@apollo/client';

export interface IsAuthenticatedQueryResult {
  authData: { isAuthenticated: boolean };
}

export const isAuthenticatedQuery = gql`
  query IsAuthenticatedQuery {
    authData
      @rest(type: "IsAuthenticatedPayload", path: "auth/is-authenticated") {
      isAuthenticated
    }
  }
`;
