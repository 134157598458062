import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

const ConfirmationDialog = ({
  open,
  title,
  message,
  confirmActionTitle,
  cancelActionTitle,
  onCancel,
  onConfirm,
}) => (
  <Dialog
    open={open}
    keepMounted
    onClose={onCancel}
    aria-labelledby="confirm-alert-title"
    aria-describedby="confirm-alert-description"
  >
    {title && <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>}
    {message && (
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {message}
        </DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        {cancelActionTitle}
      </Button>
      <Button onClick={onConfirm} color="primary">
        {confirmActionTitle}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node,
  message: PropTypes.node,
  confirmActionTitle: PropTypes.node,
  cancelActionTitle: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmationDialog.defaultProps = {
  title: undefined,
  message: undefined,
  confirmActionTitle: '',
  cancelActionTitle: '',
};

export default ConfirmationDialog;
