import React, { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import find from 'lodash/find';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';

import TableTitle from './TableTitle';
import FiltersPopover from './FiltersPopover';

interface TableToolbarProps {
  state: any;
  instance: any;

  loading?: boolean;
  title: React.ReactNode;
  extraFilters?: any[];
  disableTypography?: boolean;

  onSearch?: (value: string) => void;

  onDownloadAsXLSX?: (instance: any) => void;
}

const TableToolbar = (props: TableToolbarProps) => {
  const { onSearch, onDownloadAsXLSX, state, instance, title, loading, extraFilters = [], disableTypography = false } = props;
  const intl = useIntl();

  function hasFilters() {
    const filterableColumns = state.columns.filter((c: any) => c.filterable);
    return filterableColumns.length + extraFilters.length > 0;
  }

  const [filersMenuAnchorEl, setFiltersMenuAnchorEl] = useState<HTMLAnchorElement>();
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState<HTMLAnchorElement>();

  const handleOpenFiltersMenu = useCallback((e: React.MouseEvent) => {
    setFiltersMenuAnchorEl(e.target as HTMLAnchorElement);
  }, []);
  const handleCloseFiltersMenu = useCallback(() => {
    setFiltersMenuAnchorEl(undefined);
  }, []);

  const handleOpenDownloadMenu = useCallback((e: React.MouseEvent) => {
    setDownloadMenuAnchorEl(e.target as HTMLAnchorElement);
  }, []);

  const handleCloseDownloadMenu = useCallback(() => {
    setDownloadMenuAnchorEl(undefined);
  }, []);

  const handleDownloadAlsXLSX = useCallback(() => {
    handleCloseDownloadMenu();
    if (onDownloadAsXLSX) {
      onDownloadAsXLSX(instance);
    }
  }, [handleCloseDownloadMenu, onDownloadAsXLSX, instance]);

  const filtersChips = useMemo(() => {
    const columns = state.columns.filter((c: any) => c.filterable);
    return columns
      .map((column: any) => {
        const filter = find(state.filtered, { id: column.id });
        if (filter) {
          const displayValue = column.valueTransformer ? column.valueTransformer(filter.value, column) : filter.value;
          return {
            chipLabel: `${column.value}: ${displayValue}`,
            column,
          };
        }
        return null;
      })
      .filter((c: any) => Boolean(c));
  }, [state]);

  const isToolbarEnabled = Boolean(onSearch) || hasFilters() || !!onDownloadAsXLSX;

  if (!isToolbarEnabled) {
    return <TableTitle title={title} disableTypography={disableTypography} loading={loading} />;
  }

  return (
    <Toolbar>
      <Box display="flex" flexDirection="column">
        <TableTitle title={title} disableTypography={disableTypography} loading={loading} />
        {onSearch && (
          <Box mt={2}>
            <TextField
              variant="outlined"
              size="small"
              id="input-with-icon-textfield"
              sx={{ ml: -0.5, minWidth: 320 }}
              onChange={(event) => onSearch(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
      </Box>
      <Stack direction="row" spacing={2} height="100%" ml={2} mt={2}>
        {filtersChips.map((chipData: { chipLabel: string; column: any }, index: number) => (
          <Chip key={index} color="secondary" label={chipData.chipLabel} onDelete={() => instance.filterColumn(chipData.column, '')} />
        ))}
      </Stack>
      <Box flexGrow={1} />
      {hasFilters() && (
        <IconButton onClick={handleOpenFiltersMenu} size="large">
          <FilterListIcon />
        </IconButton>
      )}
      {onDownloadAsXLSX && (
        <Box>
          <IconButton onClick={handleOpenDownloadMenu} size="large">
            <CloudDownloadIcon />
          </IconButton>
          <Menu id="long-menu" anchorEl={downloadMenuAnchorEl} open={Boolean(downloadMenuAnchorEl)} onClose={handleCloseDownloadMenu}>
            <MenuItem onClick={handleDownloadAlsXLSX}>{intl.formatMessage({ id: 'table.downloadAsXLSX' })}</MenuItem>
          </Menu>
        </Box>
      )}
      <FiltersPopover
        state={state}
        extraFilters={extraFilters}
        instance={instance}
        anchorEl={filersMenuAnchorEl}
        isOpen={!!filersMenuAnchorEl}
        onClose={handleCloseFiltersMenu}
      />
    </Toolbar>
  );
};

export default TableToolbar;
