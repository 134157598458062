import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { StudentPracticeSessionsActivity } from '../types';
import {
  getStudentPracticeSessionsByMonthQuery,
  GetStudentPracticeSessionsByMonthQueryResult,
  GetStudentPracticeSessionsByMonthQueryVariables,
} from '../graphql/queries/getStudentPracticeSessionsByMonthQuery';

export type StudentPracticeSessionsByMonthHookResult = DataFetcherHookResult<StudentPracticeSessionsActivity[], 'practiceSessions'>;

export interface StudentPracticeSessionsByMonthHookInput {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export function useStudentPracticeSessionsByMonth(input: StudentPracticeSessionsByMonthHookInput): StudentPracticeSessionsByMonthHookResult {
  const { academyId, courseId, studentId, year, month } = input;
  const { data, loading, errorMessage } = useQuery<GetStudentPracticeSessionsByMonthQueryResult, GetStudentPracticeSessionsByMonthQueryVariables>(
    getStudentPracticeSessionsByMonthQuery,
    { variables: { academyId, courseId, studentId, year, month } }
  );

  return { loading, error: errorMessage, practiceSessions: data?.practiceSessions };
}
