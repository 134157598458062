import React, { useMemo } from 'react';

import TSTable, { DateTableCell } from '@ts-core/components/Table';
import { TSTableColumn } from '@ts-core/components/Table/TSTable/TSTable';

import { formatValue } from '@ts-core/utils/dataTableUtils';

import { useStudentAssignmentsByMonth } from '../hooks/useStudentAssignmentsByMonth';
import { StudentAssignmentsActivity } from '../types';

export interface StudentAssignmentsByMonthProps {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export function StudentAssignmentsByMonth(props: StudentAssignmentsByMonthProps) {
  const { assignments, loading } = useStudentAssignmentsByMonth(props);

  const columns = useMemo(
    (): TSTableColumn<StudentAssignmentsActivity>[] => [
      {
        Header: 'Entrega',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Comienzo',
        accessor: 'answerStartDate',
        valueTransformer: (value) => <DateTableCell date={value} />,
      },
      {
        Header: 'Finalizada',
        accessor: 'answerFinishDate',
        valueTransformer: (value) => <DateTableCell date={value} />,
      },
      {
        Header: 'Nota',
        accessor: 'score',
        valueTransformer: (value) => formatValue(value, 1),
        sortable: true,
      },
    ],
    []
  );
  return <TSTable title="Entregas" data={assignments} loading={loading} columns={columns} allowDownloadAsXLSX />;
}
