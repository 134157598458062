import { createTheme as createMuiTheme } from '@mui/material/styles';
import { green, red, purple, orange } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    check: {
      palette: {
        exercise: {
          color: (type?: 'positive' | 'neutral' | 'negative' | 'none') => string | undefined;
          bgcolor: (type?: 'positive' | 'neutral' | 'negative' | 'none') => string | undefined;
        };
      };
    };
  }
  interface ThemeOptions {
    check: {
      palette: {
        exercise: {
          color: (type?: 'positive' | 'neutral' | 'negative' | 'none') => string | undefined;
          bgcolor: (type?: 'positive' | 'neutral' | 'negative' | 'none') => string | undefined;
        };
      };
    };
  }
}

export interface CheckTheme {
  palette: {
    exercise: {
      color: (type?: 'positive' | 'neutral' | 'negative' | 'none') => string | undefined;
      bgcolor: (type?: 'positive' | 'neutral' | 'negative' | 'none') => string | undefined;
    };
  };
}

export const createTheme = () =>
  createMuiTheme({
    check: {
      palette: {
        exercise: {
          color: (type?: 'positive' | 'neutral' | 'negative' | 'none') => {
            switch (type) {
              case 'positive':
                return green[500];
              case 'neutral':
                return orange[500];
              case 'negative':
                return red[500];
              case 'none':
                return purple[500];
              default:
                return undefined;
            }
          },
          bgcolor: (type?: 'positive' | 'neutral' | 'negative' | 'none') => {
            switch (type) {
              case 'positive':
                return green[50];
              case 'neutral':
                return orange[50];
              case 'negative':
                return red[50];
              case 'none':
                return purple[50];
              default:
                return undefined;
            }
          },
        },
      },
    },
    typography: {
      fontFamily: ['Kelson'].join(','),
      fontSize: 13,
      fontWeightLight: 300,
      fontWeightRegular: 500,
      fontWeightMedium: 700,
    },
    palette: {
      primary: {
        main: '#d91d52',
      },
      secondary: {
        main: '#617984',
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              fontWeight: 700,
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
  });
