import { gql } from '@apollo/client';

import { academyFragment } from '../fragments';

export default gql`
  mutation ToggleAcademyMutation($academyId: String!, $method: String!) {
    academy(academyId: $academyId, method: $method, input: {})
      @rest(type: "Academy", path: "academies/{args.academyId}/{args.method}", method: "PUT") {
      ...Academy
    }
  }
  ${academyFragment}
`;
