import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { Student } from '../../types/students';
import { getStudentQuery, GetStudentQueryResult, GetStudentQueryVariables } from '../../graphql/students/queries/getStudentQuery';

export type StudentHookResult = DataFetcherHookResult<Student, 'student'>;

export interface StudentHookInput {
  studentId: string;
}

export function useStudent(input: StudentHookInput): StudentHookResult {
  const { studentId } = input;
  const { data, loading, errorMessage } = useQuery<GetStudentQueryResult, GetStudentQueryVariables>(getStudentQuery, { variables: { studentId } });

  return { loading, error: errorMessage, student: data?.student };
}
