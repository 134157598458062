import { gql } from '@apollo/client';

import { billedCourseFragment } from '../fragments';

export default gql`
  query GetBilledCourse($academyId: String!, $courseId: String!) {
    course(academyId: $academyId, courseId: $courseId)
      @rest(
        type: "BilledCourse"
        path: "academies/{args.academyId}/courses/{args.courseId}/billing"
      ) {
      ...BilledCourse
    }
  }
  ${billedCourseFragment}
`;
