import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@ts-core/components/Button';

const BaseListPage = (props) => {
  const { addButtonTitle, onAdd, children } = props;
  return (
    <>
      {onAdd && (
        <Box mb={2} display="flex" justifyContent="flex-end">
          <Button onClick={onAdd} color="primary" variant="contained">
            {addButtonTitle || 'Add'}
          </Button>
        </Box>
      )}
      {children}
    </>
  );
};

BaseListPage.propTypes = {
  children: PropTypes.node.isRequired,

  onAdd: PropTypes.func,
  addButtonTitle: PropTypes.node,
};

BaseListPage.defaultProps = {
  onAdd: undefined,
  addButtonTitle: undefined,
};

export default BaseListPage;
