import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import logoWhiteSmall from '@ts-core/img/logoWhiteSmall.svg';

export interface LoginFormHeaderProps {
  title: React.ReactNode;
}

const LoginFormHeader = (props: LoginFormHeaderProps) => {
  const { title } = props;
  return (
    <Box
      py={3}
      mb={3}
      mt={-3}
      minHeight={60}
      minWidth="95%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor="primary.main"
      color="background.paper"
      boxShadow={1}
      borderRadius="8px"
    >
      <img src={logoWhiteSmall} alt="logo" />
      <Box fontSize="h6.fontSize" fontWeight={500} mt={2}>
        {title}
      </Box>
    </Box>
  );
};

export default LoginFormHeader;
