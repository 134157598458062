import { useMemo } from 'react';

export default function useCountries() {
  const countries = useMemo(() => {
    return [
      {
        id: 'UY',
        title: 'Uruguay',
      },
      {
        id: 'AR',
        title: 'Argentina',
      },
    ];
  }, []);

  return { countries };
}
