import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useAcademies } from 'hooks/academies';
import BaseListPage from 'pages/BaseListPage';

import AcademiesTable, { useAcademiesTableData } from 'components/AcademiesTable';

const AcademiesPage = () => {
  const { academies: allAcademies, loading, error, onFetchAcademies, onToggleAcademy } = useAcademies();

  const { academies, onFilterAcademies } = useAcademiesTableData({
    allAcademies,
  });

  const history = useHistory();

  const handleAddAcademy = useCallback(() => {
    history.push(`/academies/new`);
  }, [history]);

  const handleEditAcademy = useCallback(
    (academy) => {
      history.push(`/academies/${academy._id}/edit`);
    },
    [history]
  );

  const handleSelectAcademy = useCallback(
    (academy) => {
      history.push(`/academies/${academy._id}/details`);
    },
    [history]
  );

  return (
    <BaseListPage onAdd={handleAddAcademy} addButtonTitle="Agregar Academia">
      <AcademiesTable
        academies={academies}
        loading={loading}
        error={error}
        onFetchAcademies={onFetchAcademies}
        onDeleteAcademy={onToggleAcademy}
        onActivateAcademy={onToggleAcademy}
        onEditAcademy={handleEditAcademy}
        onSelectAcademy={handleSelectAcademy}
        onFilterAcademies={onFilterAcademies}
      />
    </BaseListPage>
  );
};

export default AcademiesPage;
