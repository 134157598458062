import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';

import BackButton from '@ts-core/components/BackButton';

import AcademyCoursesTable, { useAcademyCoursesTable } from 'components/AcademyCoursesTable';

import { useAcademy, useAcademyCourses } from 'hooks/academies';
import { useAcademyProfessors } from 'hooks/professors';

import AcademyDetails from 'components/AcademyDetails';
import AcademyProfessors from 'components/AcademyProfessors';

const AcademyDetailsPage = () => {
  const { academyId } = useParams();
  const history = useHistory();

  const { professors, loading, error } = useAcademyProfessors({ academyId });
  const { academy } = useAcademy({
    academyId,
  });
  const { courses: allCourses, loading: loadingCourses, error: coursesError } = useAcademyCourses({ academyId });

  const { courses, onFilterCourses } = useAcademyCoursesTable({ allCourses });

  const handleSelectCourse = useCallback(
    (course) => {
      history.push(`/academies/${academyId}/courses/${course._id}`);
    },
    [history, academyId]
  );

  const handleAddNewProfessor = useCallback(() => {
    history.push(`/academies/${academyId}/professors/new`);
  }, [history, academyId]);

  const handleGoBack = useCallback(() => {
    history.push(`/academies`);
  }, [history]);

  const handleEditAcademy = useCallback(() => {
    history.push(`/academies/${academyId}/edit`);
  }, [history, academyId]);

  const handleOpenPayments = useCallback(() => {
    history.push(`/academies/${academyId}/payments`);
  }, [history, academyId]);

  return (
    <Box>
      <BackButton onGoBack={handleGoBack} title={academy?.name} subtitle="Academias" />
      <Box width="100%" display="flex" flexDirection={{ xs: 'column', md: 'row' }} mt={2}>
        <Box width={{ xs: '100%', md: '50%' }} mb={2}>
          <AcademyDetails academy={academy} onEditAcademy={handleEditAcademy} onOpenPayments={handleOpenPayments} />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} ml={1} mb={2}>
          <AcademyProfessors professors={professors} loading={loading} error={error} onAddProfessor={handleAddNewProfessor} />
        </Box>
      </Box>
      <Box>
        <AcademyCoursesTable
          courses={courses}
          loading={loadingCourses}
          error={coursesError}
          onFilterCourses={onFilterCourses}
          onSelectCourse={handleSelectCourse}
        />
      </Box>
    </Box>
  );
};

export default AcademyDetailsPage;
