import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import BackButton from '@ts-core/components/BackButton';
import EditAcademyForm from 'components/EditAcademyForm';

import { useAcademy, useAcademyFeatures, useAcademyPricingModes, useAcademyCurrencies } from 'hooks/academies';

const EditAcademyPage = () => {
  const history = useHistory();
  const { academyId } = useParams();

  const { academy, loading, onUpdateAcademy, updatingAcademy, updateAcademyError } = useAcademy({
    academyId,
  });
  const { features } = useAcademyFeatures();
  const { pricingModes } = useAcademyPricingModes();
  const { currencies } = useAcademyCurrencies();

  const handleGoBack = useCallback(() => {
    history.push(`/academies/${academyId}/details`);
  }, [history, academyId]);

  return (
    <Box>
      <BackButton onGoBack={handleGoBack} title={academy?.name} subtitle="Academias" />
      <Box mt={4} maxWidth={800}>
        {!loading && (
          <EditAcademyForm
            academy={academy}
            loading={loading}
            onSubmit={onUpdateAcademy}
            submitting={updatingAcademy}
            error={updateAcademyError}
            features={features}
            pricingModes={pricingModes}
            currencies={currencies}
          />
        )}
      </Box>
    </Box>
  );
};

export default EditAcademyPage;
