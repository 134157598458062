import { gql } from '@apollo/client';

import { courseInvoiceFragment } from '../fragments';

export default gql`
  query GetCourseInvoice(
    $academyId: String!
    $courseId: String!
    $invoiceId: String!
  ) {
    invoice(academyId: $academyId, courseId: $courseId, invoiceId: $invoiceId)
      @rest(
        type: "CourseInvoice"
        path: "academies/{args.academyId}/courses/{args.courseId}/invoices/{args.invoiceId}"
      ) {
      ...CourseInvoice
    }
  }
  ${courseInvoiceFragment}
`;
