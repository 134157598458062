import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { Box } from '@mui/material';

import Button from '@ts-core/components/Button';
import Form, { FormTextField, FormSelect } from '@ts-core/core/components/FormV2';

import { PaymentRowContainer } from './PaymentRowContainer';
import { useAcademyCurrencies } from 'hooks/academies';

import { Payment, CreatePaymentInput } from 'types/payments';
import { transformPaymentToFormInput } from 'types/payments/utils';

interface PaymentFormProps {
  payment?: Payment;

  onCancel: () => void;
  onUpdate?: (payment: Payment, input: CreatePaymentInput, onUpdated: () => void) => void;
  onCreate?: (input: CreatePaymentInput, onCreated: () => void) => void;

  isSubmitting?: boolean;
}

export function PaymentForm(props: PaymentFormProps) {
  const { onCancel, onUpdate, onCreate, payment, isSubmitting = false } = props;
  const { currencies } = useAcademyCurrencies();

  const { handleSubmit, formState, control } = useForm<CreatePaymentInput>({ defaultValues: transformPaymentToFormInput(payment) });

  const handleSaveAcademy = useCallback(
    (input: CreatePaymentInput) => {
      if (payment) {
        onUpdate?.(payment, input, onCancel);
      } else {
        onCreate?.(input, onCancel);
      }
    },
    [payment, onUpdate, onCreate, onCancel]
  );

  return (
    <PaymentRowContainer>
      <Form
        isSubmitting={isSubmitting}
        sx={{ width: '100%' }}
        onSubmit={handleSubmit(handleSaveAcademy)}
        formState={formState}
        renderButtons={(submitting, disabled) => (
          <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%" gap={2} mt={2}>
            <Button size="small" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button size="small" color="secondary" variant="contained" disabled={disabled} loading={submitting} type="submit">
              Save
            </Button>
          </Box>
        )}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between">
            <Box display="flex" flexDirection="row" gap={1}>
              <FormTextField control={control} type="number" label="Monto" name="amount" rules={{ required: true }} />
              <Box>
                <FormSelect
                  name="currency"
                  label="Moneda"
                  control={control}
                  items={currencies}
                  itemTitleKey="title"
                  itemValueKey="id"
                  rules={{ required: true }}
                />
              </Box>
            </Box>
            <Box>
              <FormTextField control={control} name="date" label="Fecha" type="date" />
            </Box>
          </Box>
          <FormTextField control={control} name="description" label="Referencia" multiline rows={2} />
        </Box>
      </Form>
    </PaymentRowContainer>
  );
}
