import React from 'react';

import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 32,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

interface FileProgressBarProps {
  sx?: SxProps;
  progress: number;
  fileName?: string;
}

const FileProgressBar = (props: FileProgressBarProps) => {
  const { progress, fileName, sx } = props;
  return (
    <Box position="relative" height={32} sx={sx}>
      <BorderLinearProgress
        sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        variant="determinate"
        value={progress}
        color="secondary"
      />
      <Stack
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="white"
        fontWeight={500}
        spacing={2}
        direction="row"
      >
        {fileName && <Typography>{fileName}</Typography>}
        <Typography width={32} ml={1}>
          {progress.toFixed(0)}%
        </Typography>
      </Stack>
    </Box>
  );
};

export default FileProgressBar;
