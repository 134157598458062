import { useFilteredData } from '@ts-core/hooks/ui';

export default function useAcademyCoursesTable({ allCourses }) {
  const {
    filteredData: courses,
    onFilterData: onFilterCourses,
  } = useFilteredData({
    list: allCourses,
    fields: ['name', 'owner.name', 'owner.email'],
  });

  return {
    courses,
    onFilterCourses,
  };
}
