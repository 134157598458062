import React, { useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Box } from '@mui/material';

import BackButton from '@ts-core/components/BackButton';
import { YearMonthSelector, YearMonthDate } from '@ts-core/modules/billing/components/YearMonthSelector';
import { ActivityType, ActivityTypesValues } from '@ts-core/modules/billing/types';
import { StudentActivityAnswersByMonth } from '@ts-core/modules/billing/components/StudentActivityAnswersByMonth';
import { StudentAssignmentsByMonth } from '@ts-core/modules/billing/components/StudentAssignmentsByMonth';
import { StudentMediaSessionsByMonth } from '@ts-core/modules/billing/components/StudentMediaSessionsByMonth';
import { StudentPracticeSessionsByMonth } from '@ts-core/modules/billing/components/StudentPracticeSessionsByMonth';

import { useStudent } from 'hooks/students/useStudent';
import { useCourse } from 'hooks/courses/useCourse';

export default function StudentActivityByMonthPage() {
  const {
    academyId,
    courseId,
    studentId,
    activityType,
    year: urlYear,
    month: urlMonth,
  } = useParams<{
    academyId: string;
    courseId: string;
    studentId: string;
    activityType: ActivityType;
    year: string;
    month: string;
  }>();
  const history = useHistory();

  const { student } = useStudent({ studentId });
  const { course } = useCourse({ courseId });

  const [date, setDate] = useState<YearMonthDate>({ year: urlYear, month: urlMonth });
  const { year, month } = date;

  const handleGoBack = useCallback(() => {
    history.push(`/dashboard/${year}/${month}/academies/${academyId}/${courseId}`);
  }, [history, academyId, courseId, year, month]);

  if (!ActivityTypesValues.includes(activityType)) {
    history.replace(`/dashboard/${year}/${month}/academies/${academyId}/${courseId}`);
    return null;
  }

  function renderContent() {
    switch (activityType) {
      case 'activities':
        return <StudentActivityAnswersByMonth academyId={academyId} courseId={courseId} studentId={studentId} year={year} month={month} />;
      case 'assignments':
        return <StudentAssignmentsByMonth academyId={academyId} courseId={courseId} studentId={studentId} year={year} month={month} />;
      case 'media':
        return <StudentMediaSessionsByMonth academyId={academyId} courseId={courseId} studentId={studentId} year={year} month={month} />;
      case 'practice':
        return <StudentPracticeSessionsByMonth academyId={academyId} courseId={courseId} studentId={studentId} year={year} month={month} />;
    }
  }

  return (
    <Box>
      <BackButton noMargin onGoBack={handleGoBack} title={student?.name} subtitle={course?.name} />
      <YearMonthSelector sx={{ mt: 3, mb: 2 }} onChangeDate={setDate} date={date} />
      {renderContent()}
    </Box>
  );
}
