import { gql } from '@apollo/client';

export default gql`
  fragment Academy on Academy {
    _id
    name
    pricing {
      amount
      currency
      mode
    }
    features
    active
    clientId
    address {
      addressLine1
      postalCode
      city
      country
    }
    contact {
      name
      email
      phoneNumber
    }
  }
`;
