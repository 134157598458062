import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/auth';

import { AuthContext } from './AuthContext';

export const useAuthState = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const { authLoaded, authenticated, loadingUser, user } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        authLoaded,
        authenticated,
        loadingUser,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
