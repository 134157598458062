/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Form, { FormTextField } from '@ts-core/core/components/FormV2';
import Dialog, { useDialog } from '@ts-core/components/Dialog';
import AskResetPasswordForm from '@ts-core/modules/auth/components/AskResetPasswordForm';

import { LoginInput } from '../../types/Auth';

import LoginFormHeader from './LoginFormHeader';
import LoginFormFooter from './LoginFormFooter';

const LoginFormSchema = Yup.object({
  email: Yup.string().email('El email no es válido').required('El email es requerido'),
  password: Yup.string().required('Ingresa la contraseña'),
});

interface LoginFormProps {
  title?: React.ReactNode;
  footerText?: React.ReactNode;
  footerUrl?: string;

  onSubmit: (data: LoginInput) => void;

  isSubmitting?: boolean;
  error?: string;

  showResetPassword?: boolean;
}

const LoginForm = (props: LoginFormProps) => {
  const { title, isSubmitting = false, error, onSubmit, showResetPassword = false, footerText, footerUrl } = props;
  const intl = useIntl();

  const { onOpenDialog, onCloseDialog, dialogProps } = useDialog();

  const { handleSubmit, formState, control } = useForm<LoginInput>({
    resolver: yupResolver(LoginFormSchema),
  });

  const submitProxy = useCallback(
    (input: LoginInput) => {
      onSubmit(input);
    },
    [onSubmit]
  );

  return (
    <Box width="100%">
      <Box boxShadow={1} bgcolor="background.paper" borderRadius="8px" px={3} display="flex" flexDirection="column" alignItems="center">
        <LoginFormHeader title={title} />
        <Form
          sx={{ width: '100%' }}
          onSubmit={handleSubmit(submitProxy)}
          isSubmitting={isSubmitting}
          submitError={error}
          submitButtonAlignment="center"
          submitActionTitle={intl.formatMessage({ id: 'login.loginButton' })}
          submitButtonProps={{ variant: 'text' }}
          formState={formState}
        >
          <Stack spacing={2}>
            <FormTextField control={control} name="email" rules={{ required: true }} fullWidth label="Email" defaultValue="" />
            <FormTextField
              sx={{ pb: 2 }}
              control={control}
              name="password"
              rules={{ required: true }}
              type="password"
              fullWidth
              label={intl.formatMessage({ id: 'field.password.label' })}
            />
          </Stack>
          {showResetPassword && (
            <Typography variant="body2" sx={{ textAlign: 'left', mt: 1 }}>
              {intl.formatMessage({ id: 'login.forgotPassword' })}

              <Link
                component="button"
                type="button"
                color="secondary.main"
                underline="hover"
                onClick={(event) => {
                  event.preventDefault();
                  onOpenDialog();
                }}
                sx={{ ml: 1 }}
              >
                {intl.formatMessage({ id: 'login.resetPassword' })}
              </Link>
            </Typography>
          )}
        </Form>
      </Box>
      {footerText && footerUrl && <LoginFormFooter text={footerText} url={footerUrl} />}
      <Dialog {...dialogProps}>
        <AskResetPasswordForm onClose={onCloseDialog} />
      </Dialog>
    </Box>
  );
};

export default LoginForm;
