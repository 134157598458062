import { gql } from '@apollo/client';

import { ActivityOverview } from '../../../types/activity';

export interface GetActivityOverviewQueryVariables {
  year: number;
  month?: number;
}

export interface GetActivityOverviewQueryResult {
  activityOverview: ActivityOverview;
}

export const getActivityOverviewQuery = gql`
  query GetActivityOverviewQuery($year: String, $month: String!) {
    activityOverview(year: $year, month: $month) @rest(type: "ActivityOverview", path: "/students-activity/overview?{args}") {
      year
      month
      academiesCount
      coursesCount
      totalStudentsInCoursesCount
      uniqueStudentsCount
      academies
      mediaSessionsCount
      assignmentAnswersCount
      practiceSessionsCount
      activityAnswersCount
      documentsCount
    }
  }
`;
