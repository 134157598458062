import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import RichTextField from '@ts-core/components/RichTextField';

function FormRichTextEditor(props) {
  const { name, control, onBusyStatsChange } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => <RichTextField onChange={onChange} value={value} onBusyStatsChange={onBusyStatsChange} />}
    />
  );
}

FormRichTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,

  onBusyStatsChange: PropTypes.func,
};

export default FormRichTextEditor;
