import { gql } from '@apollo/client';

import { userAccountFragment } from '../fragments';

export default gql`
  mutation SignIn($input: SignInBody!) {
    user(input: $input)
      @rest(type: "User", path: "auth/signin", method: "POST") {
      ...User
    }
  }
  ${userAccountFragment}
`;
