import React, { useMemo } from 'react';

import TSTable, { DateTableCell } from '@ts-core/components/Table';
import { TSTableColumn } from '@ts-core/components/Table/TSTable/TSTable';

import { formatValue } from '@ts-core/utils/dataTableUtils';

import { useStudentPracticeSessionsByMonth } from '../hooks/useStudentPracticeSessionsByMonth';
import { StudentPracticeSessionsActivity } from '../types';

export interface StudentPracticeSessionsByMonthProps {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export function StudentPracticeSessionsByMonth(props: StudentPracticeSessionsByMonthProps) {
  const { practiceSessions, loading } = useStudentPracticeSessionsByMonth(props);

  const columns = useMemo(
    (): TSTableColumn<StudentPracticeSessionsActivity>[] => [
      {
        Header: 'Nombre',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Comienzo',
        accessor: 'createdAt',
        valueTransformer: (value) => <DateTableCell date={value} />,
      },
      {
        Header: 'Nota',
        accessor: 'score',
        valueTransformer: (value) => formatValue(value, 1),
        sortable: true,
      },
    ],
    []
  );
  return <TSTable title="Prácticas" data={practiceSessions} loading={loading} columns={columns} allowDownloadAsXLSX />;
}
