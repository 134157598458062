export interface AcademyActivityByMonth {
  academy: {
    _id: string;
    name: string;
    pricing: {
      currency: string;
      amount: number;
      mode: string;
    };
  };
  year: string;
  month: string;
  coursesCount: number;
  studentsCount: number;
  mediaSessionsCount: number;
  assignmentAnswersCount: number;
  practiceSessionsCount: number;
  activityAnswersCount: number;
  documentsCount: number;
  totalCost: number;
  courses: {
    _id: string;
    name: string;
    professor: {
      _id: string;
      name: string;
      email: string;
    };
    studentsCount: number;
    mediaSessionsCount: number;
    assignmentAnswersCount: number;
    practiceSessionsCount: number;
    activityAnswersCount: number;
    documentsCount: number;
  }[];
}

export interface CourseActivityByMonth {
  _id: string;
  name: string;
  year: string;
  month: string;
  academy: {
    _id: string;
    name: string;
  };
  professor: {
    _id: string;
    name: string;
    email: string;
  };
  students: {
    _id: string;
    name: string;
    email: string;
    activityAnswers: number;
    documents: number;
    mediaSessions: number;
    practiceSessions: number;
    assignmentAnswers: number;
  }[];
  activityAnswers: number;
  documents: number;
  mediaSessions: number;
  practiceSessions: number;
  assignmentAnswers: number;
}

export const ActivityTypesValues = ['media', 'practice', 'activities', 'assignments'] as const;

export type ActivityType = typeof ActivityTypesValues[number];

export interface StudentMediaSessionActivity {
  _id: string;
  platform: string;
  maxProgressPercentage: number;
  createdAt: Date;
  lastEventDate: Date;
  video: string;
}

export interface StudentAssignmentsActivity {
  _id: string;
  name: string;
  answerId: string;
  answerStartDate: Date;
  answerFinishDate: Date;
  score: number;
}

export interface StudentPracticeSessionsActivity {
  _id: string;
  name: string;
  createdAt: Date;
  score: number;
}

export interface StudentActivitiesActivity {
  _id: string;
  name: string;
  answerDate: Date;
  file: {
    _id: string;
    name: string;
  };
  score?: string;
}
