import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { Course } from '../../types/courses';
import { getCourseQuery, GetCourseQueryResult, GetCourseQueryVariables } from '../../graphql/courses/queries/getCourseQuery';

export type CourseHookResult = DataFetcherHookResult<Course, 'course'>;

export interface CourseHookInput {
  courseId: string;
}

export function useCourse(input: CourseHookInput): CourseHookResult {
  const { courseId } = input;
  const { data, loading, errorMessage } = useQuery<GetCourseQueryResult, GetCourseQueryVariables>(getCourseQuery, { variables: { courseId } });

  return { loading, error: errorMessage, course: data?.course };
}
