import { useMemo, useCallback, useState } from 'react';

import { filterListWithQuery } from '@ts-core/utils/filters';

export default function useFilteredData({ list = [], fields = [] }) {
  const [query, setQuery] = useState();

  const filteredData = useMemo(() => {
    return filterListWithQuery({
      list,
      query,
      fields,
    });
  }, [list, query, fields]);

  const onFilterData = useCallback((newQuery) => {
    setQuery(newQuery);
  }, []);

  return {
    query,
    onFilterData,
    filteredData,
  };
}
