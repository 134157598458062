import { useQuery } from '@ts-core/lib/apollo';

import { getErrorMessage } from '@ts-core/lib/apollo';
import { getRolesQuery } from '@ts-core/graphql/role/queries';

export default function useRoles() {
  const { data, loading, error } = useQuery(getRolesQuery);
  const roles = data?.roles;

  return {
    roles,
    loading,
    error: getErrorMessage({ error }),
  };
}
