export const AttendanceQueryType = {
  active: 'active',
  history: 'history',
};

export const AttendanceType = {
  general: 'general',
  byLessons: 'byLessons',
};

export const RankingType = {
  general: 'general',
  bySubjects: 'bySubjects',
  byAssignments: 'byAssignments',
};

export const AssignmentDetailsTab = {
  answers: 'answers',
  results: 'results',
  exercises: 'exercises',
};

export const CourseStatus = {
  Active: 'opened',
  Closed: 'closed',
};

export const CourseStatusColor = {
  Active: '#2FA865', //its a green
  // Closed, it's the theme's default
};

export const OptionType = {
  positive: 'positive',
  negative: 'negative',
  neutral: 'neutral',
};

export const MediaTypes = {
  Audio: 'audio',
  Video: 'video',
  Image: 'image',
};

export const MediaTypesInPlural = {
  Audio: 'audios',
  Video: 'videos',
  Image: 'images',
};

export const PricingMode = {
  StudentPeriod: 'student:period',
  StudentMonth: 'student:month',
  StudentCourseMonth: 'student:course:month',
  FixedMonth: 'fixed:month',
  Free: 'free',
};

export const DEFAULT_FILL_IN_THE_BLANKS_OPTION_TYPE = OptionType.positive;
export const EMPTY_SPACE = 'EMPTY_SPACE_123654';
export const BLANK = 'BLANK_SPACE_123654';
export const DEFAULT_EXERCISE_TYPE = 'Default';

export const NOTIFICATIONS_URL = '/notifications';
export const SINGLE_NOTIFICATION_URL = '/single_notification';
