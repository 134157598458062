import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import AcademyProfessorsTable from 'components/AcademyProfessorsTable';
import AcademyDetailsCard from 'components/AcademyDetailsCard';

const AcademyProfessors = (props) => {
  const { professors, loading, error, onAddProfessor } = props;

  const menuItems = useMemo(() => {
    return [
      {
        title: 'Agregar Profesor',
        handler: onAddProfessor,
      },
    ];
  }, [onAddProfessor]);

  return (
    <AcademyDetailsCard title="Professors" menuItems={menuItems}>
      <AcademyProfessorsTable professors={professors} loading={loading} error={error} />
    </AcademyDetailsCard>
  );
};

AcademyProfessors.propTypes = {
  professors: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.string,

  onAddProfessor: PropTypes.func,
};

AcademyProfessors.defaultProps = {
  professors: [],
  loading: false,
  error: undefined,

  onAddProfessor: undefined,
};

export default AcademyProfessors;
