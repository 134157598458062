import React from 'react';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TableHeadCell from './TableHeadCell';

interface TableHeaderProps {
  state: any;
  instance: any;

  canSortRows: boolean;
}

const TableHeader = (props: TableHeaderProps) => {
  const { canSortRows, state, instance } = props;
  const { headerGroups, allVisibleColumns, hasHeaderGroups } = state;

  return (
    <TableHead>
      {hasHeaderGroups && (
        <TableRow sx={{ height: 44, verticalAlign: 'bottom' }}>
          {headerGroups.map((headerGroup: any, cIndex: number) => (
            <TableCell
              sx={{ top: 0, position: 'sticky', backgroundColor: 'white', borderBottom: 'unset', zIndex: 1 }}
              padding="normal"
              key={cIndex}
              colSpan={headerGroup.columns.length}
            >
              {headerGroup.Header || ''}
            </TableCell>
          ))}
        </TableRow>
      )}
      {allVisibleColumns.length > 0 && (
        <TableRow>
          {canSortRows && <TableCell />}
          {allVisibleColumns.map((column: any, cIndex: number) => (
            <TableHeadCell key={cIndex} state={state} instance={instance} column={column} />
          ))}
        </TableRow>
      )}
    </TableHead>
  );
};

export default TableHeader;
