import { gql } from '@apollo/client';

import { academyProfessorFragment } from '../fragments';

export default gql`
  query GetAcademyProfessors($academyId: String!) {
    professors(academyId: $academyId)
      @rest(
        type: "AcademyProfessor"
        path: "academies/{args.academyId}/professors"
      ) {
      ...AcademyProfessor
    }
  }
  ${academyProfessorFragment}
`;
