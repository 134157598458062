import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface BackButtonProps {
  onGoBack?: () => void;
  href?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  noMargin?: boolean;
}

const BackButton = (props: BackButtonProps) => {
  const { onGoBack, href, title, subtitle, noMargin = false } = props;
  const location = useLocation();
  const query = queryString.parse(location.search);

  const history = useHistory();
  const handleGoBack = useCallback(() => {
    if (query.from) {
      history.push(query.from as string);
    } else if (href) {
      history.push(href);
    }
  }, [history, href, query]);

  return (
    <Box display="flex" alignItems="center" mb={noMargin ? 0 : 1}>
      <IconButton onClick={href ? handleGoBack : onGoBack} size="large">
        <ArrowBackIcon />
      </IconButton>
      <Box display="flex" flexDirection="column">
        {title && <Typography variant="h6">{title}</Typography>}
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </Box>
    </Box>
  );
};

export default BackButton;
