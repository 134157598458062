import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Menu from '@ts-core/components/Menu';

const AcademyDetailsCard = (props) => {
  const { children, menuItems, title } = props;

  return (
    <Box boxShadow={2} borderRadius="4px" p={2} bgcolor="background.paper">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Menu menuItems={menuItems} />
      </Box>
      {children}
    </Box>
  );
};

AcademyDetailsCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  menuItems: PropTypes.array,
};

AcademyDetailsCard.defaultProps = {
  title: '',
  menuItems: [],
};

export default AcademyDetailsCard;
