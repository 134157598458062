import React, { useEffect, useCallback } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import BasePage from '@ts-core/pages/BasePage';
import NavigationBar from 'components/NavigationBar';

import { useDrawerMenuItems } from 'hooks/ui';

import { useAuthState } from 'lib/auth';

import privateRoutes from '../adminPrivateRoutes';

const AdminPrivateSection = () => {
  const history = useHistory();
  const { authenticated, user } = useAuthState();

  const { topMenuItems } = useDrawerMenuItems();

  useEffect(() => {
    if (!authenticated) {
      history.push('/logout');
    }
  }, [authenticated, history]);

  const handleSignOut = useCallback(() => {
    history.replace('/logout');
  }, [history]);

  return (
    <>
      <NavigationBar user={user} topMenuItems={topMenuItems} onSignOut={handleSignOut} profileUrl="/profile" />
      <BasePage>
        <Switch>
          {privateRoutes.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} exact={route.exact} />
          ))}
          <Redirect to="/dashboard" />
        </Switch>
      </BasePage>
    </>
  );
};

export default AdminPrivateSection;
