import React, { useMemo } from 'react';

import { Box, FormControl, InputLabel, MenuItem, SxProps } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { allMonths } from './data';

export interface YearMonthDate {
  year: string;
  month: string;
}

export interface YearMonthSelectorProps {
  minYear?: number;
  minMonth?: number;
  sx?: SxProps;
  date: YearMonthDate;

  onChangeDate: (date: YearMonthDate) => void;
}

const thisYear = new Date().getFullYear();
const thisMonth = new Date().getMonth() + 1;

const monthsForYear = (year: Number, { minMonth, minYear }: { minMonth?: number; minYear?: number }): { id: string; label: string }[] => {
  let months = allMonths;

  if (year === minYear && minMonth) {
    months = allMonths.filter((m) => Number(m.id) >= minMonth);
  }

  if (year === thisYear) {
    months = months.filter((m) => Number(m.id) <= thisMonth);
  }

  return months;
};

export function YearMonthSelector(props: YearMonthSelectorProps) {
  const { sx, minYear = 2023, minMonth = 11, date, onChangeDate } = props;
  const { year, month } = date;

  const years = useMemo((): { id: string; label: string }[] => {
    return Array.from(new Array(1 + thisYear - minYear).keys()).map((i) => {
      const year = `${minYear + i}`;
      return {
        id: year,
        label: year,
      };
    });
  }, [minYear]);

  const months = useMemo(() => {
    return monthsForYear(Number(year), { minYear, minMonth });
  }, [year, minYear, minMonth]);

  const handleChangeYear = (event: SelectChangeEvent) => {
    const newYear = event.target.value;
    const newMonth = monthsForYear(Number(newYear), { minYear, minMonth })[0].id;
    onChangeDate({ year: newYear, month: newMonth });
  };

  const handleChangeMonth = (event: SelectChangeEvent) => {
    const newMonth = event.target.value;
    onChangeDate({ year, month: newMonth });
  };

  return (
    <Box sx={sx}>
      <FormControl variant="outlined" sx={{ minWidth: 110 }} size="small">
        <InputLabel id="year-label-id">Año</InputLabel>
        <Select labelId="year-label-id" id="year-selector" value={year} label="Año" variant="outlined" onChange={handleChangeYear}>
          {years.map((year) => (
            <MenuItem key={year.id} value={year.id}>
              {year.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ minWidth: 110, ml: 0.5 }} size="small">
        <InputLabel id="month-label-id">Mes</InputLabel>
        <Select labelId="month-label-id" id="month-selector" value={month} label="Mes" variant="outlined" onChange={handleChangeMonth}>
          {months.map((month) => (
            <MenuItem key={month.id} value={month.id}>
              {month.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
