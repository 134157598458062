import React from 'react';
import { useController, FieldValues } from 'react-hook-form';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import { FormField } from '../types';

type StringKeyOf<T> = { [K in keyof T]: T[K] extends string ? K : never }[keyof T];

export type FormSelectProps<TField extends FieldValues, TItem> = {
  items: TItem[];
  itemTitleKey: StringKeyOf<TItem>;
  itemValueKey: StringKeyOf<TItem>;
} & FormField<TField> &
  Omit<SelectProps, 'required'>;

const FormSelect = <TField extends FieldValues, TItem>(props: FormSelectProps<TField, TItem>) => {
  const {
    label,
    name,
    control,
    rules,
    defaultFormValue = '',
    variant,
    shouldUnregister = false,
    items,
    itemTitleKey,
    itemValueKey,
    sx,
    ...selectProps
  } = props;
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: defaultFormValue,
    shouldUnregister,
    rules: {
      required: rules?.required,
    },
  });
  const { error } = fieldState;
  const hasError = !!error;
  const { ref, ...fieldProps } = field;

  return (
    <FormControl variant={variant} fullWidth size="small" required={!!rules?.required} sx={sx}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select labelId={name} label={label} inputRef={ref} error={hasError} variant={variant} {...selectProps} {...fieldProps}>
        {items.map((item, index) => {
          const label = item[itemTitleKey] as unknown as React.ReactNode;
          const value = item[itemValueKey] as unknown as string;
          return (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      {hasError && <FormHelperText error={hasError}>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

export default FormSelect;
