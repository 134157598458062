import { useQuery } from '@ts-core/lib/apollo';
import { DataFetcherHookResult } from '@ts-core/core/types';

import { StudentActivitiesActivity } from '../types';
import {
  getStudentActivitiesByMonthQuery,
  GetStudentActivitiesByMonthQueryResult,
  GetStudentActivitiesByMonthQueryVariables,
} from '../graphql/queries/getStudentActivitiesByMonthQuery';

export type StudentActivitiesByMonthHookResult = DataFetcherHookResult<StudentActivitiesActivity[], 'activities'>;

export interface StudentActivitiesByMonthHookInput {
  academyId: string;
  courseId: string;
  studentId: string;
  year: string;
  month: string;
}

export function useStudentActivitiesByMonth(input: StudentActivitiesByMonthHookInput): StudentActivitiesByMonthHookResult {
  const { academyId, courseId, studentId, year, month } = input;
  const { data, loading, errorMessage } = useQuery<GetStudentActivitiesByMonthQueryResult, GetStudentActivitiesByMonthQueryVariables>(
    getStudentActivitiesByMonthQuery,
    { variables: { academyId, courseId, studentId, year, month } }
  );

  return { loading, error: errorMessage, activities: data?.activities };
}
