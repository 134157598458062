import { gql } from '@apollo/client';

import { CourseActivityByMonth } from '../../types';

export interface GetCourseActivityByMonthQueryVariables {
  academyId: string;
  courseId: string;
  year: string;
  month: string;
}

export interface GetCourseActivityByMonthQueryResult {
  courseActivity: CourseActivityByMonth;
}

export const getCourseActivityByMonthQuery = gql`
  query GetCourseActivityByMonth($academyId: String!, $courseId: String!, $year: String!, $month: String!) {
    courseActivity(academyId: $academyId, courseId: $courseId, year: $year, month: $month)
      @rest(
        type: "CourseActivityByMonth"
        path: "/students-activity/academies/{args.academyId}/years/{args.year}/months/{args.month}/courses/{args.courseId}"
      ) {
      _id
      name
      year
      month
      students
      academy
      professor
      activityAnswers
      mediaSessions
      documents
      practiceSessions
      assignmentAnswers
    }
  }
`;
