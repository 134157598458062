import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FormContainer = ({ children, title }) => {
  return (
    <Box boxShadow={1} borderRadius="4px" p={3} border={1} borderColor="grey.200">
      {title && (
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      )}
      <Box mt={2}>{children}</Box>
    </Box>
  );
};

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,

  title: PropTypes.node,
};

FormContainer.defaultProps = {
  title: undefined,
};

export default FormContainer;
