import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import getErrorMessage from '@ts-core/lib/apollo/getErrorMessage';

interface SnackbarNotificationHookResult {
  showErrorNotification: (error: ApolloError | string) => void;
  showSuccessNotification: (message?: string) => void;
}

export default function useSnackbarNotification(): SnackbarNotificationHookResult {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const showErrorNotification = useCallback(
    (error: ApolloError | string) => {
      let message = '';
      if (error instanceof ApolloError) {
        message = getErrorMessage({ error }) || 'Error';
      } else {
        message = error;
      }
      console.error(error);
      enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  const showSuccessNotification = useCallback(
    (message?: string) => {
      const finalMessage = message || intl.formatMessage({ id: 'generic.successOperation.message' });
      enqueueSnackbar(finalMessage, { variant: 'success' });
    },
    [enqueueSnackbar, intl]
  );

  return {
    showErrorNotification,
    showSuccessNotification,
  };
}
