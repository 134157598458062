import { gql } from '@apollo/client';

export default gql`
  fragment CourseInvoice on CourseInvoice {
    _id
    course
    coursePeriodDateEnd
    coursePeriodDateInit
    paid
    totalDebt
    lines {
      _id
      duration
      assignments
      assignmentAnswers
      group
      invoice
      practiceSessions
      createdAt
      updatedAt
      startDate
      endDate
      student {
        _id
        email
        name
      }
    }
  }
`;
