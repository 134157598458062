import React from 'react';
import { useIntl } from 'react-intl';
import { FormState, FieldValues } from 'react-hook-form';

import { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import Button, { ButtonProps } from '@ts-core/components/Button';

export interface FormProps<TFieldValues> {
  children: React.ReactNode;
  sx?: SxProps;
  formState: FormState<TFieldValues>;
  loading?: boolean;
  disabled?: boolean;

  onSubmit: React.FormEventHandler<HTMLFormElement>;

  submitActionTitle?: React.ReactNode;
  submitButtonProps?: ButtonProps;
  submitButtonAlignment?: 'center' | 'flex-end' | 'flex-start';

  isSubmitting?: boolean;
  submitError?: string;

  renderButtons?: (loading: boolean, disabled: boolean) => React.ReactNode;
}

const Form = <TFieldValues extends FieldValues>(props: FormProps<TFieldValues>) => {
  const {
    sx = [],
    children,
    onSubmit,
    submitActionTitle,
    submitButtonProps,
    loading = false,
    disabled = false,
    isSubmitting = false,
    submitButtonAlignment = 'flex-end',
    submitError,
    formState,
    renderButtons,
  } = props;
  const intl = useIntl();

  const { variant = 'contained', color = 'primary', sx: buttonSx = [] } = submitButtonProps || {};

  if (loading) {
    return (
      <Box display="flex" width="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={sx}>
      <form onSubmit={onSubmit} noValidate>
        {children}
        {submitError && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {submitError}
          </Alert>
        )}

        {!!renderButtons ? (
          renderButtons(isSubmitting, !formState.isDirty || disabled)
        ) : (
          <Box display="flex" flexDirection="row" justifyContent={submitButtonAlignment} alignItems="center" mt={3} mb={2}>
            <Box>
              <Button
                fullWidth
                variant={variant}
                color={color}
                sx={[{ boxShadow: 0 }, ...(Array.isArray(buttonSx) ? buttonSx : [buttonSx])]}
                type="submit"
                loading={isSubmitting}
                disabled={!formState.isDirty || disabled}
              >
                {submitActionTitle || intl.formatMessage({ id: 'button.save.title' })}
              </Button>
            </Box>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default Form;
