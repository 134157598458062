import React, { useMemo } from 'react';
import { blueGrey, green } from '@mui/material/colors';
import { Box, Grid } from '@mui/material';
import AcademiesIcon from '@mui/icons-material/School';
import ActivityIcon from '@mui/icons-material/Assignment';
import MediaIcon from '@mui/icons-material/OndemandVideo';
import StudentsIcon from '@mui/icons-material/SupervisedUserCircle';
import CoursesIcon from '@mui/icons-material/Class';
import AssignmentsIcon from '@mui/icons-material/Work';
import PracticeIcon from '@mui/icons-material/FitnessCenter';
import ReceiptIcon from '@mui/icons-material/Paid';

import { priceFormatter } from '@ts-core/utils/priceFormatter';

import { ActivityOverview } from 'types/activity';

import Typography from '@ts-core/components/Typography';

export interface ActivityOverviewTilesProps {
  loading?: boolean;
  activityOverview?: ActivityOverview;
}

type TileId =
  | 'academiesCount'
  | 'coursesCount'
  | 'totalStudentsInCoursesCount'
  | 'uniqueStudentsCount'
  | 'mediaSessionsCount'
  | 'assignmentAnswersCount'
  | 'practiceSessionsCount'
  | 'activityAnswersCount';

const tiles: { id: TileId; label: string; icon: React.ReactElement }[] = [
  {
    id: 'academiesCount',
    label: 'Academias',
    icon: <AcademiesIcon />,
  },
  {
    id: 'coursesCount',
    label: 'Cursos',
    icon: <CoursesIcon />,
  },
  {
    id: 'totalStudentsInCoursesCount',
    label: 'Total alumnos en cursos',
    icon: <StudentsIcon />,
  },
  {
    id: 'uniqueStudentsCount',
    label: 'Alumnos Únicos',
    icon: <StudentsIcon />,
  },
  {
    id: 'mediaSessionsCount',
    label: 'Videos',
    icon: <MediaIcon />,
  },
  {
    id: 'assignmentAnswersCount',
    label: 'Entregas',
    icon: <AssignmentsIcon />,
  },
  {
    id: 'practiceSessionsCount',
    label: 'Practicas',
    icon: <PracticeIcon />,
  },
  {
    id: 'activityAnswersCount',
    label: 'Tareas',
    icon: <ActivityIcon />,
  },
];

export function ActivityOverviewTiles(props: ActivityOverviewTilesProps) {
  const { loading, activityOverview } = props;

  const groupedTotals = useMemo((): Record<string, number> => {
    return (
      activityOverview?.academies?.reduce((totals: Record<string, number>, academy) => {
        const total = totals[academy.pricing.currency] ?? 0;
        totals[academy.pricing.currency] = total + academy.cost;
        return totals;
      }, {}) ?? {}
    );
  }, [activityOverview]);

  return (
    <Box width="100%">
      <Grid container spacing={1}>
        {tiles.map((tile) => (
          <Grid key={tile.id} item xs={6} sm={4} md={3}>
            <Box bgcolor={blueGrey[100]} borderRadius={2} p={2}>
              <Typography loading={loading} fontSize={30} fontWeight={700} skeletonProps={{ width: 60 }}>
                {activityOverview?.[tile.id] ?? '-'}
              </Typography>
              <Box display="flex" mt={2} alignItems="center">
                {tile.icon}
                <Typography fontSize={16} fontWeight={700} ml={1}>
                  {tile.label}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1} mt={3} mb={2}>
        {Object.keys(groupedTotals).map((key) => (
          <Grid key={key} item xs={6} sm={4} md={3}>
            <Box bgcolor={green[100]} borderRadius={2} p={2}>
              <Typography loading={loading} fontSize={30} fontWeight={700} skeletonProps={{ width: 60 }}>
                {priceFormatter(key, groupedTotals[key])}
              </Typography>
              <Box display="flex" mt={2} alignItems="center">
                <ReceiptIcon />
                <Typography fontSize={16} fontWeight={700} ml={1}>
                  A cobrar {`${key}`}
                </Typography>
              </Box>
              <Typography fontSize={12} fontWeight={100} ml={1}>
                * Los precios incluyen IVA
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
