import { gql } from '@apollo/client';

import { courseInvoiceFragment } from '../fragments';

export default gql`
  mutation ToggleInvoicePaidStatus(
    $academyId: String!
    $courseId: String!
    $invoiceId: String!
    $method: String!
  ) {
    invoice(
      academyId: $academyId
      courseId: $courseId
      invoiceId: $invoiceId
      method: $method
      input: {}
    )
      @rest(
        type: "CourseInvoice"
        path: "academies/{args.academyId}/courses/{args.courseId}/invoices/{args.invoiceId}/{args.method}"
        method: "PUT"
      ) {
      ...CourseInvoice
    }
  }
  ${courseInvoiceFragment}
`;
