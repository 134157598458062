import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const PeriodsList = (props) => {
  const { title, items, loading, onSelectItem, selectedItemId } = props;

  const handleSelectItem = useCallback(
    (item) => () => {
      onSelectItem(item);
    },
    [onSelectItem]
  );

  function renderSelectedMark(item) {
    if (selectedItemId === item._id) {
      return (
        <div
          style={{
            width: 3,
            position: 'absolute',
            height: '100%',
            backgroundColor: 'black',
            left: 0,
          }}
        />
      );
    }
    return null;
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      <List>
        {items.map((item) => {
          return (
            <ListItem
              key={item._id}
              divider
              onClick={handleSelectItem(item)}
              button
              style={{ borderLeft: '2' }}
            >
              {renderSelectedMark(item)}
              <ListItemText
                primary={item.title}
                secondary={item.subtitle && item.subtitle}
              />
            </ListItem>
          );
        })}
      </List>
      {items.length === 0 && !loading && (
        <Box mt={3}>
          <Typography>
            <FormattedMessage id="courseInvoiceDetails.noPeriods" />
          </Typography>
        </Box>
      )}
    </>
  );
};

PeriodsList.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    })
  ).isRequired,
  selectedItemId: PropTypes.string,

  onSelectItem: PropTypes.func.isRequired,
};

PeriodsList.defaultProps = {
  loading: false,
  title: undefined,
  selectedItemId: undefined,
};

export default PeriodsList;
